import React, {useCallback} from 'react';
import {Backdrop, Card, Divider, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import validator from "validator";
import ReportParamsConfig from "./ReportParamsConfig";
import {getFinalDayOfMonthInUTC, getInitOfDayMonthInUTC} from "../../../../../utils/dateUtil";
import {dateToUTCServerFormatString} from "../../../../../utils/dateToUtcStringConverter";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export const isCurrentPeriodValid = (date,period)=>{
    const initialDayOfCurrentMont = getInitOfDayMonthInUTC(new Date())
    const endOfPeriod = getFinalDayOfMonthInUTC(date,period-1)
    return initialDayOfCurrentMont.getTime() > endOfPeriod.getTime()
}

const ReportConfigPopUp = ({open, finishReportSetup, cancelReportSetup}) => {

    const {t} = useTranslation()
    const currentDate = new Date()
    const { units } = useSelector( state => state.auth );
    const [{name,initDate,pollutants,period,temperature,index,windSpeed}, updateState] = React.useState(
        {
            name:"",
            initDate:new Date(currentDate.getFullYear(),currentDate.getMonth()-1,currentDate.getDate()),
            period:1,
            validData:false,
            pollutants:units.pollutants,
            temperature:units.temperature,
            index:units.index,
            windSpeed:units.windSpeed
        });

    const indexCallback = useCallback((event) => {
        updateState(state=>({...state,index:event.target.value}))
    },[])

    const windSpeedCallback =  useCallback((event) => {
        updateState(state=>({...state,windSpeed:event.target.value}))
    },[])

    const nameCallback = useCallback((event)=>{
        let value = event.target.value
        let step1Valid = !validator.isEmpty(value)
        updateState(state=>({...state,name:value,step1Valid}))
    },[])

    const tempUnitCallback = useCallback((event) => {
        updateState(state=>({...state,temperature:event.target.value}))
    },[])

    const periodCallback = useCallback((event)=>{
        updateState(state=>({...state,period:event.target.value}))
    },[])

    const unitsCallback = useCallback((event)=>{
        updateState(state=>({...state,pollutants:event.target.value}))
    },[])

    const dateCallback = useCallback((value)=>{
        updateState(state=>({...state,initDate:value}))
    },[])

    const useStyles = makeStyles((
        {
            card:{
                paddingTop:16,
                paddingBottom:10,
            },
            tittle:{
                marginLeft:30,
                color:"#434343",
                marginBottom:16
            },
            buttons:{
                marginRight:30,
                display:"flex",
                marginTop:10,
                justifyContent:"flex-end"
            },
            divider:{
                marginBottom:32
            },
            divider2:{
                marginTop:8
            },
            popUpContainer:{
                maxWidth:980
            },
            paramsSetup:{
                paddingTop:20,
                paddingBottom:20,
                paddingLeft:30,
                paddingRight:30,
                maxWidth:1000,
                justifyContent:"center"
            }

        }
    ));
    const classes = useStyles();

    const handleFinishSetup = ()=>{
        finishReportSetup({
            "name": name,
            "time": dateToUTCServerFormatString(getInitOfDayMonthInUTC(initDate)),
            "pollutants": pollutants,
            "temperature": temperature,
            "windSpeed":windSpeed,
            "index":index,
            "months": period,
        })
    }
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <Card className={classes.card} data-testid={"create-report-popup"}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.tittle}>{t("reportScreen.reportConfig.title")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                     <Grid container item xs={12} className={classes.paramsSetup} >
                         <ReportParamsConfig name={name} nameCallback={nameCallback}
                                             period={period} periodCallback={periodCallback}
                                             pollutantUnit={pollutants}
                                             tempUnits={temperature}
                                             tempoCallback={tempUnitCallback}
                                             pollutantUnitsCallback={unitsCallback}
                                             initialDate={initDate}
                                             updateDate={dateCallback}
                                             index={index}
                                             indexCallback={indexCallback}
                                             windSpeedCallback={windSpeedCallback}
                                             windSpeedUnit={windSpeed}
                         />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={cancelReportSetup}>{ t("cancel")}</Button>
                            <Button
                                data-testid={"create-report-finish"}
                                disabled={name === "" || !isCurrentPeriodValid(initDate,period)}
                                onClick={handleFinishSetup}>{t("finish")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default ReportConfigPopUp;
