import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import validator from 'validator';
import {useDispatch} from "react-redux";
import {
    setRequestError,
    setSuccessRequest,
    startLoadingAction,
    stopLoadingAction
} from "../../../../../reducers/requestUIReducer";
import {expiredSession} from "../../../../../reducers/authReducer";
import {inviteMembersRequest} from "../../../../../requests/organization/organizationRequests";
import {ORGANIZATION_ROUTE} from "../../../../../constants";
import {useTranslation} from "react-i18next";
import {Backdrop, Grid, Typography} from "@material-ui/core";

export default function InviteDialog({setInviteDialogState,sucessfullySent,serverErrorCallback}) {

    const initialState = {
        email:'',
        validEmail:false
    }

    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [state, setState] = useState(initialState);
    const {email,validEmail} = state;

    const handleSendInvitation = () => {
        dispatch(startLoadingAction())
        inviteMembersRequest({mail:email},(data,error)=>{
            dispatch(stopLoadingAction())
            if(!error){
                dispatch(setSuccessRequest(t("organizationMembersDialog.sucessfully_sent")));
                sucessfullySent();
            }
            else {
                switch (data.status){
                    case 401:
                        expiredSession(ORGANIZATION_ROUTE)(dispatch)
                        break;
                    case 400:
                        setInviteDialogState(false);
                        dispatch(setRequestError(t("organizationMembersDialog.invalid_email")));
                        break;
                    case 500:
                        setInviteDialogState(false);
                        serverErrorCallback();
                        break;
                    default:
                        setInviteDialogState(false);
                        dispatch(setRequestError(t("organizationMembersDialog.unknown_error")));
                        break;
                }
            }
        })
    };

    const handleEmail =( event)=>{
        const value = event.target.value;
        setState({email: value,validEmail:validator.isEmail(value)});
    };

    const handleClose = ()=>{
        setState(initialState);
        setInviteDialogState( false);
    }

    return (
        <Backdrop open={true} style={{zIndex:1999}} >
            <Grid container style={{backgroundColor:"#fff", maxWidth:680, padding:30}}>
                <Grid item xs={12}>
                    <Typography variant={"h5"}>{t("organizationMembersDialog.invite_member")}</Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop:20}}>
                    <Typography> {t("organizationMembersDialog.please_enter_email")}</Typography>
                </Grid>
                <Grid item xs={12} style={{marginBottom:20,textAlign:"center"}}>
                    <TextField
                        inputProps={{ "data-testid": "invite-dialog-textfield" }}
                        label={t("organizationMembersDialog.email")} variant="outlined"
                                required autoComplete="email" autoFocus fullWidth  margin="normal"
                                onChange={handleEmail} value={email}/>
                </Grid>
                <Grid item container xs={12} style={{justifyContent:"flex-end"}}>
                    <Button
                        onClick={handleClose} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button
                        data-testid={"invite-dialog-send"}
                        disabled={!validEmail}
                        onClick={handleSendInvitation} color="primary">
                        {t("organizationMembersDialog.send_invitation")}
                    </Button>
                </Grid>
            </Grid>
        </Backdrop>
    );
}