import React, {useCallback, useState} from 'react';
import {Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import validator from "validator";
import ChangePassword from "./ChangePassword";
import {useDispatch, useSelector} from "react-redux";
import {updateProfileRequest} from "../../../../../requests/profile/profileRequest";
import {expiredSession, updateProfileAction} from "../../../../../reducers/authReducer";
import RequestInfoComponent from "../../../../common/RequestInfoComponent";
import {PROFILE_ROUTE} from "../../../../../constants";
import ServerErrorScreen from "../../../../common/ServerErrorScreen";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles({
    saveButton:{
        color:'#ffffff',
    },
    changePasswordButton:{
        marginTop:24
    },

    form:{
        width:"100%",
    },
    email:{
      color:"#8a8a8a"
    },
    emailTittle:{
        fontWeight:"bold"
    },

    grip:{
        marginTop:12,
    },
    rowMargin:{
    }
});


const UserDataCardView = ({className}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const classes = useStyles();
    const { name,lastName,email,units } = useSelector( state => state.auth );

    const initialFormValues = {profileName:name,profileLastName:lastName,
        profileUnits:units,validForm:validateForm(name,lastName),
        openChangePassword:false,serverError:false,loading:false};
    const [{profileName,
        profileLastName,
        validForm,
        openChangePassword,
        serverError,
        profileUnits,
        loading
    }, updateState] = useState(initialFormValues);

    const closeDialog = useCallback(()=>{
        updateState(state =>{
            return {...state,openChangePassword:false}
        })
    },[updateState])

    const updateServerError = ()=>{
        updateState(state =>({...state,serverError:true}))
    }

    const updateServerErrorCallback = useCallback(()=>{
            updateServerError();
        }
        ,[]);

    const handleName = (event)=>{
        const value = event.target.value;
        const isFormValid = validateForm(value,null)
        updateState(state =>{
            return {...state,profileName: value,validForm:isFormValid}
        })
    }

    const handleLastName = (event)=>{
        const value = event.target.value
        const isFormValid = validateForm(null,value)
        updateState(state =>{
            return {...state,profileLastName: value,validForm:isFormValid}
        })
    }

    const handleSave = ()=>{
        updateState(state => ({...state,loading: true, error:""}))
        updateProfileRequest({name:profileName,lastName:profileLastName,units:profileUnits}, (data,err) =>{
            if(!err){
                updateState(state => ({...state,loading: false, error:""}))
                enqueueSnackbar(t("profile.success_updated"),{ variant:"success" });
                dispatch(updateProfileAction(profileName,profileLastName,profileUnits));
            }
            else {
                switch (data.status){
                    case 401:
                        expiredSession(PROFILE_ROUTE)(dispatch)
                        break;
                    case 400:
                        enqueueSnackbar(t("profile.error_updating"),{ variant:"error" });
                        break;
                    default:
                        updateState(state => {
                            return {...state,loading:false,error: t("profile.could_not_updated")}
                        })
                        enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("profile.could_not_updated")}`,{ variant:"error" });
                        break;
                }
            }
        })
    }

    const handleChangePassword = ()=>{
        updateState(state =>{
            return {...state,openChangePassword:true}
        })
    }

    function validateForm(forName, formLastName ){
        let nameField = (forName !== undefined && forName !== null ) ? forName : profileName;
        let lastNameField = (formLastName !== undefined && formLastName !== null ) ? formLastName : profileLastName;
        return !(validator.isEmpty(nameField) || validator.isEmpty(lastNameField));
    }

    const handleIndexChange = (event) =>{
        updateState(state =>(
            {...state,profileUnits:{...state.profileUnits,index:event.target.value}}))
    }

    const handleTempChange = (event) =>{
        updateState(state =>(
            {...state,profileUnits:{...state.profileUnits,temperature:event.target.value}}))
    }

    const handleWindSpeedChange = (event) =>{
        updateState(state =>(
            {...state,profileUnits:{...state.profileUnits,windSpeed:event.target.value}}))
    }

    const handlePollutantsChange = (event) =>{
        updateState(state =>(
            {...state,profileUnits:{...state.profileUnits,pollutants:event.target.value}}))
    }

    return (

        <>
        <Card className={className} >
                <form  noValidate className={classes.form} >
                    <Typography className={classes.emailTittle}  variant={"h4"}>{t("profile.user_profile")}</Typography>
                    <Grid className={classes.grip} container direction={"row"} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                inputProps={{ "data-testid": "profile-name" }}
                                disabled={loading}
                                autoFocus label={t("profile.name")} variant="outlined"
                                        required fullWidth  margin="normal"
                                        onChange={handleName} value={profileName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField  label={t("profile.last_name")} variant="outlined"
                                        inputProps={{ "data-testid": "profile-lastname" }}
                                        required
                                        disabled={loading}
                                        fullWidth  margin="normal"
                                        onChange={handleLastName}
                                        value={profileLastName}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField  label={t("profile.email")} variant="outlined"
                                        inputProps={{ "data-testid": "profile-email" }}
                                        required
                                        disabled
                                        fullWidth  margin="normal"
                                        value={email}/>
                        </Grid>


                        <Grid item xs={12} md={6} className={classes.rowMargin}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                                <InputLabel >{t("units.pollutant_index")}</InputLabel>
                                <Select
                                    disabled={loading}
                                    onChange={handleIndexChange}
                                    label={t("units.pollutant_index")}
                                    value={profileUnits.index}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    data-testid = "profile-pollutant-index"
                                >
                                    <MenuItem value={"aqi"}>AQI</MenuItem>
                                    <MenuItem value={"caqi"}>CAQI</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.rowMargin}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                                <InputLabel>{t("units.concentration_units")}</InputLabel>
                                <Select
                                    disabled={loading}
                                    label={t("units.concentration_units")}
                                    onChange={handlePollutantsChange}
                                    value={profileUnits.pollutants}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    data-testid = "profile-concentration"
                                >
                                    <MenuItem value={"eu"}>µg/m³ - mg/m³</MenuItem>
                                    <MenuItem value={"usa"}>{t("units.ppm_ppb")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.rowMargin}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                                <InputLabel>{t("units.temperature_units")}</InputLabel>
                                <Select
                                    disabled={loading}
                                    label={t("units.temperature_units")}
                                    onChange={handleTempChange}
                                    value={profileUnits.temperature}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    data-testid = "profile-temperature"
                                >
                                    <MenuItem value={"celsius"}>Celsius (ºC)</MenuItem>
                                    <MenuItem value={"fahrenheit"}>Fahrenheit (ºF)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} >
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                                <InputLabel>{t("units.wind_speed_unit")}</InputLabel>
                                <Select
                                    disabled={loading}
                                    label={t("units.wind_speed_unit")}
                                    onChange={handleWindSpeedChange}
                                    value={profileUnits.windSpeed}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    data-testid = "profile-wind"
                                >
                                    <MenuItem value={"mps"}>{t("units.meter_per_second")}</MenuItem>
                                    <MenuItem value={"mph"}>{t("units.mile_per_hour")}</MenuItem>
                                    <MenuItem value={"knots"}>{t("units.knots")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}/>

                        <Grid item md={8} lg={9}/>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button
                                data-testid = "profile-save-button"
                                disabled={!validForm || loading} className={classes.saveButton} color={"primary"} fullWidth
                                    onClick={handleSave} size={"large"} variant="contained">{t("save")}</Button>

                        </Grid>
                        <Grid item xs={12}>
                            <Button

                                disabled={loading} className={classes.changePasswordButton} fullWidth color={"primary"}
                                     onClick={handleChangePassword} size={"large"} variant="text">{t("profile.change_password")}</Button>
                        </Grid>
                    </Grid>
                </form>
            <ChangePassword updateServerErrorCallback={updateServerErrorCallback} open={openChangePassword} closeDialog = {closeDialog}/>
        </Card>
            <RequestInfoComponent/>
            {serverError && <ServerErrorScreen/>}
        </>
    );
};

export default UserDataCardView;
