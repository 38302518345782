import React, {useCallback, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {useDispatch, useSelector} from "react-redux";
import {
    Button, Checkbox, FormControlLabel,
    Grid, Link,
    TextField,
    Typography
} from "@material-ui/core";
import validator from 'validator';
import logo from '../../../../assets/bettair_logo.png'
import CookiesDialog from "./CookiesDialog";
import { loginAction} from "../../../../reducers/authReducer";
import ForgotDialog from "./ForgotDialog";
import RequestInfoComponent from "../../../common/RequestInfoComponent";
import {MINIMUN_PASSWORD_LENGHT} from "../../../../constants";
import {hashGenerator} from "../../../../utils/hashGenerator";
import ServerErrorScreen from "../../../common/ServerErrorScreen";
import {
    removeRequestErrorAction,
    setRequestError,
    startLoadingAction,
    stopLoadingAction
} from "../../../../reducers/requestUIReducer";
import {loginRequest} from "../../../../requests/auth/authRequests";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    button:{
        color:'#ffffff',
        marginBottom:12,
        marginTop:12
    },
    img:{
        width : 220,
        paddingBottom : 30
    },
    forgot:{
        display: 'flex',
        justifyContent: "center"
    },
    loginContainer: {
        minWidth:400,
        maxWidth:600,
        background: 'white',
        padding: 40,
        borderRadius: 6
    },
    container:{
        background : "#eaeaea",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
    },
    form: {
        width: '100%'
    }
});


const LoginScreen = () => {

    const classes = useStyles();
    const dispatch = useDispatch()
    const { loading } = useSelector( state => state.requestUI );
    const { t } = useTranslation();

    const persistedEmail = localStorage.getItem('user_email');
    const initialEmail = persistedEmail ? JSON.parse(persistedEmail):'';

    const [state, setState] = useState({
        email:initialEmail,
        password:'',
        remember:false,
        validForm:false,
        showForgotDialog:false,
        serverError:false
    });

    const {email,password,remember,validForm,showForgotDialog,serverError} = state;

    const serverErrorCallback = useCallback(()=>{
        setState(oldState => ({...oldState,serverError: true}))
    },[setState])

    const handleEmail = (event)=>{
        const value = event.target.value
        const validForm = validateForm(value,undefined)
        setState({...state, email: value, validForm });
    }

    const handlePassword = (event)=>{
        const value = event.target.value
        const validForm = validateForm(undefined,value)
        setState({...state, password: value, validForm });
    }

    const handleRemember= ()=>{
        setState({...state, remember:!remember });
    }

    const handleSigIn = async ()=>{
        let digestHex = await hashGenerator(password);
        login(email,digestHex,remember);
    }

    const handleEnter = async (e) => {
        if(!validForm){return}
        if (e.key === "Enter")
        {
            await handleSigIn()
        }
    };

    const login = (email, password,remember) => {
        dispatch(startLoadingAction())
        dispatch(removeRequestErrorAction())
        loginRequest({mail:email,pass:password,remember}, (resp, err) => {
            dispatch(stopLoadingAction())
            if (err) {
                switch (resp.status) {
                    case 401:
                        dispatch(setRequestError("Error, invalid credentials"));
                        break;
                    case 400:
                        dispatch(setRequestError("Error, invalid request"));
                        break;
                    case 500:
                        setState({...state,serverError: true });
                        break;
                    default:
                        dispatch(setRequestError("An error occurred, please try again later"));
                        break;
                }
            } else {
                if(remember){
                    localStorage.setItem('user_email',JSON.stringify(email));
                }else {
                    localStorage.removeItem('user_email');
                }
                dispatch(loginAction(resp));
            }
        });

    };

    const handlerForgot = ()=>{
        setState({...state,showForgotDialog: true})
    }

    function validateForm(formEmail = email, formPassword = password){
        if(!validator.isEmail(formEmail)){
            return false;
        }
        return formPassword.trim().length >= MINIMUN_PASSWORD_LENGHT;
    }

    return <div>
    <Grid className={ classes.container} container justifyContent={"center"} alignContent={"center"}>
                <Grid container item xs = {6}  md={4}  justifyContent={"center"} alignItems={"center"} direction={"column"}
                      className={classes.loginContainer} alignContent={"center"}
                     >
                    <Grid item >
                        <img className={classes.img} src={logo} alt="logo"/>
                    </Grid>
                    <Grid container item>
                        <Typography className={classes.form}  align={"left"} variant={"h5"}>{t('loginScreen.sign_in')}</Typography>
                    </Grid >
                    <Grid container item>
                        <form className={classes.form} noValidate>
                            <TextField  label={t('loginScreen.email')} variant="outlined"
                            required autoComplete="email" autoFocus fullWidth  margin="normal"
                                        onChange={handleEmail} value={email}
                                        onKeyPress={handleEnter}
                                        inputProps={{ "data-testid": "login-email" }}
                            />
                            <TextField  label={t('loginScreen.password')} variant="outlined"
                                        required autoComplete="current-password"
                                        type="password" fullWidth  margin="none"
                                        onChange={handlePassword}
                                        value={password}
                                        onKeyPress={handleEnter}
                                        inputProps={{ "data-testid": "login-password" }}
                                        helperText={`${t('loginScreen.minimun_password')} ${MINIMUN_PASSWORD_LENGHT} ${t('loginScreen.characters')}`}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleRemember}
                                        checked={remember}
                                        value="remember"
                                        color="primary"
                                    />
                                }
                                label={t('loginScreen.remember')}
                            />
                            <Button
                                data-testid="login-sign-in"
                                disabled={!validForm || loading} className={classes.button} color={"primary"} fullWidth
                                    onClick={handleSigIn} size={"large"} variant="contained">{t('loginScreen.sign_in')}</Button>
                            <Link className={classes.forgot} onClick={handlerForgot} variant="body2">
                                {t('loginScreen.forgot_password')}
                            </Link>
                        </form>
                    </Grid >
                </Grid>
            </Grid>
        <CookiesDialog/>
        <ForgotDialog showForgotDialog = {showForgotDialog} setForgotState = {setState}
                      serverErrorCallback={serverErrorCallback}/>
        <RequestInfoComponent/>
        {serverError && <ServerErrorScreen />}
    </div>

};

export default LoginScreen;
