import React from 'react';
import { Grid } from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import RequestInfoComponent from "../../../common/RequestInfoComponent";
import OrganizationUnits from "./units/OrganizationUnits";
import OrganizationMembers from "./members/OrganizationMembers";
import Preferences from "./preference/Preferences";

const useStyles = makeStyles({
    root: {
        maxWidth:1800,
        paddingBottom:20,
        paddingTop:20,
        paddingLeft:40,
        paddingRight:40,
    },
    prefCard: {
        display:"flex",
        padding:20,
        borderRadius: 5,
        minHeight:200
    }
    ,
    unitsCard: {
        display:"flex",
        padding:20,
        borderRadius: 5,
        minHeight:348
    },
    membersCard: {
        display:"flex",
        padding:20,
        borderRadius: 5,
        minHeight:600
    }
});

const OrganizationScreen = () => {

    const classes = useStyles();
    return (
        <>
            <Grid container direction={"row"} className={classes.root}
                  alignItems={"center"} justifyContent="space-around" spacing={2}>

                <Grid item xs={12}>
                    <Preferences className={classes.prefCard}/>
                </Grid>
                <Grid item xs={12}>
                    <OrganizationUnits className={classes.unitsCard}/>
                </Grid>
                <Grid item xs={12}>
                    <OrganizationMembers className={classes.membersCard}/>
                </Grid>
            </Grid>
            <RequestInfoComponent/>
        </>
    );
}

export default OrganizationScreen;

