import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";

const CAQILegend = () => {
    const { t } = useTranslation();
    const useStyles = makeStyles({
        valueDark:{
            textAlign:"center",
            minWidth:60,
            color:"#484848",
            fontWeight:"bold",
            marginRight:42,
            marginLeft:8
        },
        descriptionDark:{
            textAlign:"center",
            color:"#484848",
            fontWeight:"bold",
        },
        value:{
            textAlign:"center",
            minWidth:60,
            color:"#ffffff",
            fontWeight:"bold",
            marginRight:42,
            marginLeft:8
        },
        description:{
            textAlign:"center",
            color:"#ffffff",
            fontWeight:"bold",
        },

        greenCAQI:{
            background:"#79BC6A"
        },
        greenYellowCAQI:{
            background:"#BCCF4D",
            marginBottom:4
        },
        yellowCAQI:{
            background:"#EEC208",
            marginBottom:4
        },
        orangeCAQI:{
            background:"#F29307",
            marginBottom:4
        },
        redCAQI:{
            background:"#E8416F",
            marginBottom:4
        }

    });

    const classes = useStyles();


    return (
        <>
            <Grid item xs={12} container className={classes.redCAQI}>
                <Typography variant={"subtitle2"} className={classes.value}>>100 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("caqiLegend.very_high")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.orangeCAQI}>
                <Typography variant={"subtitle2"} className={classes.value}>75–100 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("caqiLegend.high")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.yellowCAQI}>
                <Typography variant={"subtitle2"} className={classes.valueDark}>50–75 </Typography>
                <Typography variant={"subtitle2"} className={classes.descriptionDark}>{t("caqiLegend.medium")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.greenYellowCAQI}>
                <Typography variant={"subtitle2"} className={classes.valueDark}>25–50 </Typography>
                <Typography variant={"subtitle2"} className={classes.descriptionDark}>{t("caqiLegend.low")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.greenCAQI}>
                <Typography variant={"subtitle2"} className={classes.valueDark}>0–25 </Typography>
                <Typography variant={"subtitle2"} className={classes.descriptionDark}>{t("caqiLegend.very_low")}</Typography>
            </Grid>
        </>
    );
};

export default CAQILegend;
