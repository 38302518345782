import React from 'react';
import {
    Backdrop,
    Card,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableRow,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import {dateToReadableUTCString} from "../../../../utils/dateToReadableDateTimeString";
import {useSelector} from "react-redux";
import {getVariableName} from "../pollutantNames";
import {useTranslation} from "react-i18next";



const DownloadDetailPopUp = ({open,handleCloseDetailCallback,downloadDetail}) => {

    const {t} = useTranslation()
    const { stations } = useSelector( state => state.dashboardUI );

    const useStyles = makeStyles((
        {
            card:{
                paddingTop:16,
                paddingBottom:10,
            },
            tittle:{
                marginLeft:30,
                color:"#434343",
                marginBottom:16
            },
            subTittle:{
                fontSize:20,
                color:"#434343",
                marginBottom:4
            },
            value:{
                fontSize:18,
                color:"#a0a0a0",
                marginBottom:32
            },
            buttons:{
                marginRight:30,
                display:"flex",
                marginTop:10,
                justifyContent:"flex-end"
            },
            divider:{
                marginBottom:32
            },
            divider2:{
                marginTop:8
            },
            popUpContainer:{
                width:620
            },
            detailContainer:{
                paddingLeft:20,
               maxWidth:500,
                paddingBottom:20
            },
            tableContainer:{
                marginTop:20,
                height:240,
                width:220,
                borderRadius:6,
                background:"#eaeaea",
            }

        }
    ));
    const classes = useStyles();

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.tittle}>{t("downloadScreen.detailPopUp.download_detail")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid container item xs={12} className={classes.detailContainer}>
                        <Grid item xs={6}>
                            <Typography variant={"h5"} className={classes.subTittle}>{t("downloadScreen.name")}</Typography>
                            <Typography variant={"subtitle1"} className={classes.value}>{downloadDetail.name}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"} className={classes.subTittle}>{t("downloadScreen.detailPopUp.date_range")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{`${dateToReadableUTCString(new Date(downloadDetail.time))} 
                                        - ${dateToReadableUTCString(new Date(downloadDetail.endtime))}`}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"} className={classes.subTittle}>{t("units.concentration_units")}</Typography>

                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{downloadDetail.pollutants === "eu" ?
                                "µg/m³ - mg/m³" : "ppb - ppm"}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"} className={classes.subTittle}>{t("units.temperature_units")}</Typography>
                            <Typography variant={"subtitle1"}
                                        className={classes.value}>{downloadDetail.temperature === "celsius" ?
                                "Celsius (ºC)" :"Fahrenheit (ºF)"}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"} className={classes.subTittle}>{t("downloadScreen.detailPopUp.stations")}</Typography>
                            <TableContainer  className={classes.tableContainer}>
                                <Table stickyHeader aria-label="event table" >
                                    <TableBody className={classes.table}>
                                        {downloadDetail.stations.map((id) => (
                                            <TableRow key={id}>
                                                <TableCell
                                                    align="left">{stations.find(station => station.id === id).alias}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant={"h5"} className={classes.subTittle}>{t("downloadScreen.detailPopUp.variables")}</Typography>
                            <TableContainer  className={classes.tableContainer}>
                                <Table stickyHeader aria-label="event table" >
                                    <TableBody >
                                        {downloadDetail.variables.map((item) => (
                                            <TableRow key={item}>
                                                <TableCell align="left">{
                                                    getVariableName(item)
                                                }</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={handleCloseDetailCallback}>{t("ok")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default DownloadDetailPopUp;
