import React from 'react';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import ScheduleAlarmCardView from "./ScheduleAlarmCardView";
import clsx from "clsx";
import NotificationsCardView from "./NotificationsCardView";

const AlarmsScreen = () => {

    const useStyles = makeStyles( {
            root: {
                maxWidth:1800,
                paddingBottom:20,
                paddingTop:20,
                paddingLeft:40,
                paddingRight:40,
            },
            card: {
                justifyContent:"center",
                flexDirection:"column",
                display:"flex",
                background:"white",
                padding:20,
                borderRadius: 5,
                minHeight:245
            },
            firstRowCard: {
                minHeight:500
            },
            secondRowCard: {
                minHeight:600
            }
        }
    );

    const classes = useStyles();


    return (
        <Grid className={classes.root} container direction={"row"}
              alignItems={"center"} justifyContent="space-around" spacing={2} >
                <Grid item  xs={12} >
                   <ScheduleAlarmCardView className={clsx(classes.card,classes.firstRowCard)}/>
                </Grid>
                <Grid item xs={12}>
                    <NotificationsCardView className={clsx(classes.card,classes.secondRowCard)}/>
                </Grid>
        </Grid>
    );
};

export default AlarmsScreen;
