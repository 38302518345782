import React, {useCallback, useEffect, useRef, useState} from "react";
import theme from "../../theme/bettairTheme";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {PublicRoute} from "./public/PublicRoute";
import LoginScreen from "./public/login/LoginScreen";
import { ThemeProvider} from "@material-ui/styles";
import {profileRequest} from "../../requests/profile/profileRequest";
import LoadingFullScreen from "../common/LoadingFullScreen";
import {useDispatch, useSelector} from "react-redux";
import {expiredSession, loginAction} from "../../reducers/authReducer";
import Dashboard from "./private/dashboard/Dashboard";
import InvitationAndResetScreen from "./public/invitations/InvitationAndResetScreen";
import {HOME_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE, RESET_ROUTE} from "../../constants";
import ServerErrorScreen from "../common/ServerErrorScreen";
import {stationRequest} from "../../requests/stationsRequests";
import { updateStationsAction} from "../../reducers/dashboardUIReducer";
import {useSnackbar} from "notistack";
import {downloadConfigRequest} from "../../requests/downloads/downloadConfigRequest";
import {setDownloadListAction} from "../../reducers/downloadReducer";
import {webSocket} from "../../websocket/webSocket";
import * as Sentry from "@sentry/react";
import {downloadReportListRequest} from "../../requests/report/downloadReportListRequest";
import {useMountComponent} from "../../hooks/useMountComponent";
import {setReportListAction} from "../../reducers/reportReducer";
import TopLevelSwitch from "./TopLevelSwitch";


export const TopLevelRoutesTree = ()=>{
    const isMounted = useMountComponent();
    const dispatch = useDispatch()
    const [{checkState,serverError},updateState] = useState({checkState:false,serverError:false})
    const { signedIn } = useSelector( state => state.auth );
    const { enqueueSnackbar } = useSnackbar();
    const { units } = useSelector( state => state.auth );
    let  closeWebSockedRef = useRef(null)


    const sockedFailToConnectCallback = useCallback(()=>{
        enqueueSnackbar(`Fail to connect websocket`,{ variant:"error" });
    },[enqueueSnackbar])

    const downloadReportList = useCallback(()=>{
        downloadReportListRequest((data, err)=>{
            if(!isMounted.current){return}
            if(!err && data){
                if(data.length>0){
                    dispatch(setReportListAction(data));
                }
            }
            if(err){
                if(data.status === 401){
                    expiredSession(HOME_ROUTE)(dispatch)
                }else {
                    enqueueSnackbar(`Error ${data.status},
                         Fetching report list`,{ variant:"error" });
                }
            }
        })
    },[enqueueSnackbar,dispatch,isMounted])

    const downloadDownloadsConfig = useCallback(()=>{
        downloadConfigRequest((data, err)=>{
            if(!isMounted.current){return}
            if(!err && data){
                if(data.length>0){
                    dispatch(setDownloadListAction(data));
                }
            }
            if(err){
                if(data.status === 401){
                    expiredSession(HOME_ROUTE)(dispatch)
                }else {
                    enqueueSnackbar(`Error ${data.status},
                         Fetching download list`,{ variant:"error" });
                }
            }
        })
    },[isMounted,dispatch,enqueueSnackbar])

    const updateDataCallback = useCallback((type)=>{
        switch (type){
            case "download":
                downloadDownloadsConfig()
                break;
            case "report":
                downloadReportList()
                break;
            case "alarms":
                break;
            default:
                Sentry.captureMessage(`Error, updateDataCallback called with invalid type ${type}`);
        }
    },[downloadReportList,downloadDownloadsConfig])

    useEffect(()=>{
        if(signedIn){
           webSocket(dispatch,closeWebSockedRef,sockedFailToConnectCallback,updateDataCallback)
        }else {
            if(closeWebSockedRef.current !== null){
                closeWebSockedRef.current.close(1000)
            }else{
                Sentry.captureMessage("Error, web socked close function ref is null");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[signedIn])


     useEffect(()=>{
         document.getElementById('splash-screen').style.display = 'none';
         downloadCurrentUseProfile();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

     useEffect(()=>{
             if(signedIn){
                 downloadStations(units.index);
                 downloadDownloadsConfig();
                 downloadReportList();
             }
     }
         // eslint-disable-next-line react-hooks/exhaustive-deps
     ,[units.index]);

    const downloadStations = useCallback((index)=>{
        stationRequest(index,(data, err)=>{
            if(!err && data){
                if(data.length>0){
                    dispatch(updateStationsAction(data));
                }
            }
            if(err){
                if(data.status === 401){
                    expiredSession(HOME_ROUTE)(dispatch)
                }else {
                    enqueueSnackbar(`Error ${data.status},
                         Downloading sensors.`,{ variant:"error" });
                }
            }
        })
    },[dispatch,enqueueSnackbar]);

     const downloadCurrentUseProfile = useCallback(()=>{
         profileRequest((data, err)=>{
             if(err && data.status === 500){
                 return updateState({checkState:true,serverError: true})
             }
             if(!err){
                 dispatch(loginAction(data));
             }
             updateState({checkState:true,serverError: false});
         })
     },[dispatch]);


    return (<ThemeProvider theme={theme}>
        { checkState &&
            <Router>
               <TopLevelSwitch signedIn={signedIn}/>
            </Router>
        }
        <LoadingFullScreen loading={!checkState}/>
        {serverError && <ServerErrorScreen />}
    </ThemeProvider>);
}

