import React from 'react';
import {Step, StepLabel, Stepper} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const AlarmConfigSteps = ({activeStep}) => {

    const {t} = useTranslation()
    const steps = [t("alarmScreen.trigger"),t("alarmScreen.stations")];

    return (
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default AlarmConfigSteps;
