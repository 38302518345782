import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";


export let organizationRequest = (callback) => {
    webClient.get('/organization')
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};

export const deleteMemberRequest = (list) => {
    let deletePromises = []
    list.forEach((userId)=>{
        deletePromises.push(webClient.delete(`/organization/user/${userId}`))
    })
    return deletePromises;
};

export const inviteMembersRequest = (body,callback) => {
    webClient.post('/organization/user', body, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const resendInvitationRequest = (body,callback) => {
    webClient.put('organization/invitation', body, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};

export const updateUnitsRequest = (body,callback) => {
    webClient.put('/organization/units', body, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const updateLocale = (body,callback) =>{
    webClient.put('/organization/locale', body, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        });
}