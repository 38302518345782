import React from 'react';
import {  Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";

const ServerErrorScreen = () => {

    const {t} = useTranslation()
    const useStyles = makeStyles({
        box: {
            zIndex:9999,
            position:"absolute",
            top:0,
            left:0,
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            width:"100vw",
            height:"100vh",
            background:"white",
        }
    });

    const classes = useStyles();

    return (
         <div className={classes.box} >
            <Typography
                data-testid =  {"server-error-text"}
                variant={"h1"}>{t("error_500")}</Typography>
        </div>
    );
};

export default ServerErrorScreen;
