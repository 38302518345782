import React from 'react';
import {Backdrop, CircularProgress} from "@material-ui/core";

const LoadingFullScreen = ({loading}) => {
    return (
        <Backdrop style={{zIndex: 1999, color: '#fff'}} open={loading} data-testid={"loading-screen"}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default LoadingFullScreen;
