import {webClient} from "../../config/axios";

export const getFileExport = (id,progress, cancelToken, callback) => {
    let value = 0;
    progress(id,value);
    webClient.get(`/download/file/${id}`, {
        responseType: 'blob',
        timeout: 0,
        cancelToken,
        onDownloadProgress: progressEvent => {
            const total = progressEvent.total ?? progressEvent.totalSize;
            const loaded = progressEvent.loaded ?? progressEvent.position;
            if (loaded * 100 / total > value + 5 || value === 0) {
                value = loaded * 100 / total;
                if (value < 10) value = 5;
                progress(id,value);
            }
        }
    })
        .then((response) => {
            callback(id,response, false);
        })
        .catch(error => {
            if (error.response) {
                callback(id,{status: error.response.status, error: error.response.data}, true);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an mapGenerator of XMLHttpRequest in the browser and an mapGenerator of
                // http.ClientRequest in node.js
                callback(id,{status: 500, error: 'Service could not be reached'}, true);
            } else {
                // Something happened in setting up the request that triggered an Error
                callback(id,{status: 500, error: 'Service could not be reached'}, true);
            }
        });
};

