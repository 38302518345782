import type from "./action_types/type";


const initialState = {
    drawerOpen:false,
    stations:[],
    selectedStation:null,
    selectCompareStations:[]
};

export const dashboardUIReducer = (state = initialState, action) =>{
    switch (action.type){
        case type.updateCompareStation:
            return {...state,selectCompareStations:action.payload}
        case type.updateDashboardDrawerVisibility:
            return {...state,drawerOpen:action.payload}
        case type.updateStation:
            return {...state,stations: action.payload}
        case type.updateSelectedStation:
            return {...state,selectedStation: action.payload}
        case type.updateAQI:
            let updatedAqiStations = state.stations.map(obj => {
                let payLoadStation = action.payload.find(it => it.id === obj.id)
                if(payLoadStation !== undefined){
                    obj.index = payLoadStation.index
                }
                return obj;
            });
            return {...state,stations: updatedAqiStations}
        case type.updateAlias:
            let newStations = state.stations.map(obj => {
                if(obj.id === action.payload[0]){
                    obj.alias = action.payload[1]
                }
                return obj;
            });
            return {...state,stations: newStations}
        default:
            return state;
    }
};


export const updateAQIAction = (indexArray)=>({
    type:type.updateAQI,
    payload:indexArray
})

export const updateCompareStationsAction = (stationsList)=>({
    type:type.updateCompareStation,
    payload:stationsList
});

export const updateStationsAction = (stations) =>({
    type:type.updateStation,
    payload:stations
});

export const updateAliasAction = (deviceId,alias) =>({
    type:type.updateAlias,
    payload:[deviceId,alias]
});

export const updateSelectedStationAction = (stationId) =>({
    type:type.updateSelectedStation,
    payload:stationId
});

export const updateDashboardDrawerAction = (open) =>({
    type:type.updateDashboardDrawerVisibility,
    payload:open
});