import {webClient} from '../../config/axios'
import {handleError} from "../handleRequestError";

export const loginRequest = (body, callback) => {
    webClient.post('/auth/login', body, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};

export const forgotRequest = (body,callback) => {
    webClient.post('/auth/reset',body,{
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};

export const forgotChangePasswordRequest = (body,callback) =>{
    webClient.post('/auth/reset/pass',body,{
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
}

export let logoutRequest = (callback) => {
    webClient.get('/user/logout')
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};