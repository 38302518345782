import React, {useCallback, useEffect, useState} from 'react';
import {Card, Grid, IconButton} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import Plot from "react-plotly.js";
import HelpPopup from "../common/HelpPopup";
import {makeStyles} from "@material-ui/styles";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE, DEFAULT_PERIOD} from "../../../../../constants";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import CardTittle from "../common/CardTittle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import PeriodSelector, {CUSTOM_RANGE} from "../common/PeriodSelector";
import {noiseDataRequest} from "../../../../../requests/analytics/noiseDataRequest";
import useDateRange from "../../../../../hooks/useDateRange";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import {roundAccurately} from "../../../../../utils/roundNumbers";
import './chart_style.css';
import DataNotFound from "../../../../common/DataNotFound";
import ChartLoading from "../common/ChartLoading";
import ErrorFetchingDataMessage from "../common/ErrorFetchingDataMessage";
import {useTranslation} from "react-i18next";

const baseSensorData ={
    type: 'scatter',
}

const NoiseCardView = ({className}) => {

    const {t} = useTranslation()
    const dispatch = useDispatch();
    const initialState = {data:[],period:DEFAULT_PERIOD,loading:true,error:""}
    const[{data,period,loading,error},updateState] = useState(initialState)
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const { enqueueSnackbar } = useSnackbar();
    const isMounted = useMountComponent();
    const { selectedStation } = useSelector( state => state.dashboardUI );

    const [{openDateRangePicker,dateRange},updateOpenDatePickerCallback,
        updateDatePickedCallback,clearDataRange] = useDateRange();

    useEffect(
        ()=>{
            if(dateRange != null){
                updateData(CUSTOM_RANGE);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[dateRange]);

    useEffect(()=>{
        updateData();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[selectedStation])

    const handleRefresh = ()=>{
        updateData(null);
    }

    const updateData = useCallback((selectedPeriod)=>{
        let currentPeriod = selectedPeriod || period

        updateState(state =>{
            return {...state,loading: true,data: [],error: "", period: currentPeriod}
        });

        let selectedRange = getDateRange(currentPeriod,dateRange);

        noiseDataRequest(selectedStation,
            selectedRange[0],
            selectedRange[1], (data,err)=>{
                if (!isMounted.current) {return}
                if(!err){
                    let fetchData = [{name:"Noise level",
                        x:(data.x),
                        y:(data.y).map(item =>{
                            return roundAccurately(item,2);
                        }),
                        ...baseSensorData,
                        hovertemplate: `<b>Noise level</b>: %{y} dB`,
                        hoverlabel:{namelength:0},
                        marker: {color:'#1A23D0' }
                    }]

                    updateState(state =>{
                        return {...state,data:fetchData,loading: false}
                    });
                }
                else {
                    if(data.status === 404){
                        updateState(state =>{
                            return {...state,loading: false}
                        });
                        enqueueSnackbar(t("analyticScreen.ambientNoise.not_found_for_period"),{ variant:"info" });
                    }
                    else {
                        updateState(state =>{
                            return {...state,loading: false,error:data.status}
                        });
                        if(data.status === 401){
                            expiredSession(ANALYTICS_ROUTE)(dispatch)
                        }
                        else {
                            enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.ambientNoise.could_not_update")}`,{ variant:"error" });
                        }
                    }
                }
            } );
    },[t,dispatch,enqueueSnackbar,isMounted,period,selectedStation,dateRange]);


    const handleSelectorChange = useCallback((event)=>{
        if( Number(event.target.value) !== CUSTOM_RANGE){
            clearDataRange();
            updateData(event.target.value)
        }
    },[clearDataRange,updateData]);

    const onCustomPressedCallback = useCallback(()=>{
            updateOpenDatePickerCallback(true);
        }
        ,[updateOpenDatePickerCallback]);

    const plotLayout = {
        autosize: true,
        legend:{x: -0.22, y: 0.9},
        yaxis: {
            title: 'dB'
        },
        xaxis:{showgrid:false,domain: [0.01, 1]},
    };

    const useStyles = makeStyles((
         {
            loading:{
                left: "50%",
                position: "relative",
                top: "50%",
                zIndex:999
            },
            rightControls:{
                display:"flex",
                flexDirection:"column",
                alignItems:"flex-start"
            },
            refreshButton:{
                marginLeft:"auto",
                color:"gray"
            }
    }));

    const plotConfig = {
        modeBarButtonsToRemove:[ "select2d", "lasso2d",
            "toggleHover", "sendDataToCloud", "toggleSpikelines","hoverCompareCartesian",
            "hoverClosestCartesian"
        ],
        displaylogo : false
    };

    const classes = useStyles();

    return (
        <Card className={className}>
            <DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                onDateRangePicked={updateDatePickedCallback} />
            <Grid container>
                <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                    <CardTittle tittle={t("analyticScreen.ambientNoise.ambient_noise")} setAnchorEl={setAnchorEl}/>
                    <IconButton className={classes.refreshButton} aria-label="refresh"  disabled={loading}
                                onClick={handleRefresh}>
                        <RefreshIcon fontSize={"large"}/>
                    </IconButton>
                </Grid>
                {data.length>0 &&  <Grid  container item xs={10} className={"noiseChart"} >
                    <Plot
                        useResizeHandler = {true}
                        layout={plotLayout}
                        data={data}
                        onInitialized={(figure) => this.setState(figure)}
                        onUpdate={
                            (figure) =>
                                this.setState(figure)

                        }
                        config = {plotConfig}>

                    </Plot>
                </Grid>}
                {(data.length === 0 && !loading) &&  <Grid container item xs={10} className={"notFoundNoiseData"} >
                    <DataNotFound/>
                </Grid>}
                {error !== "" &&  <Grid container item xs={10} className={"notFoundNoiseData"} >
                    <ErrorFetchingDataMessage/>
                </Grid>}
                {loading &&  <Grid container item xs={10} className={"notFoundNoiseData"} >
                    <ChartLoading/>
                </Grid>}
                <Grid item className={classes.rightControls} xs={2}>
                    <PeriodSelector loading={loading}
                                    period={period}
                                    handleSelectorChange={handleSelectorChange}
                                    dateRange={dateRange}
                                    onCustomPressedCallback={onCustomPressedCallback}
                    />
                </Grid>
            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("analyticScreen.ambientNoise.en_analytics_noise")}/>
        </Card>
    );
};

export default NoiseCardView;
