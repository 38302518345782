import React from 'react';
import Button from "@material-ui/core/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useSnackbar} from "notistack";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import {useTranslation} from "react-i18next";

const CreatedPasswordPopUp = ({password,handleClose}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <div data-testid={ "created-app-password-pop-up"}>
            <Dialog
                fullWidth
                open={true}
                onClose={handleClose}
                aria-labelledby="password-alert-dialog-title"
                aria-describedby="password-alert-dialog-description"
            >
                <DialogTitle id="password-alert-dialog-title">
                    {t("appPassword.app_password")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="password-dialog-content">
                        {t("appPassword.success_created")}
                    </DialogContentText>
                    <div style={{marginTop:20,display:"flex",flexDirection:"row", alignItems:"center",justifyContent:"center"}}>
                        <DialogContentText
                            style={{fontSize:24,color:"black",marginTop:10,backgroundColor:"#e3e3e3",paddingLeft:8,paddingRight:8}}
                            id="password-value-dialog-content">
                            {password}
                        </DialogContentText>
                        <CopyToClipboard text={password}
                                         onCopy={() =>  enqueueSnackbar(`${t("appPassword.clipboard")}`,{ variant:"info" })}>
                            <IconButton aria-label="copy" style={{color:"#1A23D0"}}>
                                <ContentCopyIcon />
                            </IconButton>
                        </CopyToClipboard>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        {t("ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CreatedPasswordPopUp;
