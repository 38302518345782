import React, {useCallback, useEffect, useState} from 'react';
import CardViewLoading from "../../analytics/common/ChartLoading";
import {Button, Card, Grid, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {DataGrid} from "@material-ui/data-grid";
import DataNotFound from "../../../../common/DataNotFound";
import {makeStyles} from "@material-ui/styles";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {
    dateToReadableUTCString,
} from "../../../../../utils/dateToReadableDateTimeString";
import AppPasswordConfigPopUp from "./AppPasswordConfigPopUp";
import ConfirmDialog from "../../../../common/ConfirmDialog";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {getPasswordListRequest} from "../../../../../requests/password/getPasswordListRequest";
import {createPasswordRequest} from "../../../../../requests/password/createPasswordRequest";
import {useDispatch} from "react-redux";
import {startLoadingAction, stopLoadingAction} from "../../../../../reducers/requestUIReducer";
import CreatedPasswordPopUp from "./CreatedPasswordPopUp";
import {expiredSession} from "../../../../../reducers/authReducer";
import { PROFILE_ROUTE} from "../../../../../constants";
import {deletePasswordRequest} from "../../../../../requests/password/deletePasswordRequest";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const AppPasswordCardView = ({className}) => {
    const { t } = useTranslation();
    const [{loading,deleting,enableDelete,error,selectionModel,passwords, openConfigPopUp,
        openConfirmDialog,createdPassword},updateState] = useState(
        {loading:true,openConfigPopUp:false,
            deleting:false,enableDelete:false,error:"",selectionModel:[], createdPassword:"",
            openConfirmDialog:false, passwords:[]})

    const useStyles = makeStyles({
        deleteButton:{
            color:"#ffffff",
            background:"#f6343d",
            '&:hover': {
                background:"#81191c"
            },
        },
        subtitle:{
            color:"#8a8a8a"
        },
        organizationName:{
            fontWeight:"bold"
        },
        rowTable:{
            display:"flex",
            width:"100%vw",
            justifyContent:"center",
            margin:0,
        },
        table:{
            maxWidth:1600,
            height: 480,
            '& .super-app.current_user': {
                fontWeight: '500',
                color: '#bcbcbc',
                backgroundColor: 'rgba(105,105,105,0.05)',
            },
            '& .super-app.other_user': {
            },
        }
    });
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const isMounted = useMountComponent();
    const dispatch = useDispatch()

    const updateTable = useCallback(()=>{
        updateState(state => ({...state,loading: true, error: "",passwords: [],createdPassword: "",
            deleting:false,selectionModel: [],enableDelete:false}))
        getPasswordListRequest((data,error)=>{
            if(isMounted.current){
                if(!error){
                    updateState(state => ({...state,loading: false,passwords: data.map(item => {return {...item,
                            created: new Date(item.created)}})}))
                }else {
                    switch (data.status){
                        case 401:
                            expiredSession(PROFILE_ROUTE)(dispatch)
                            break;
                        default:
                            updateState(state => ({...state,loading: false,error: t("appPassword.error_fetching")}))
                            enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("appPassword.fetching_password")}`,{ variant:"error" });
                            break;
                    }
                }
            }
        })
    },[t,dispatch,enqueueSnackbar,isMounted])


    useEffect(()=>{
        updateTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleNewAppPassword = ()=>{
        updateState(state => ({...state,openConfigPopUp: true}))
    }

    const handleDelete = ()=>{
        updateState(state =>({...state,openConfirmDialog: true}))
    }

    const onSelectionModelChange = (params)=>{
        updateState(state =>{
            return {...state,enableDelete:(params.selectionModel.length !== 0),
                selectionModel:params.selectionModel
            }})
    }

    const handlePasswordDisplayClosed = useCallback(()=>{
        updateTable()
    },[updateTable])

    const finishAppPasswordSetup = useCallback((json)=>{
        dispatch(startLoadingAction())
        createPasswordRequest(json,(data,error)=>{
            dispatch(stopLoadingAction())
            if(isMounted.current){
                if(!error){
                    updateState(state => ({...state,createdPassword:data.password,openConfigPopUp: false}))
                }else {
                    switch (data.status){
                        case 401:
                            expiredSession(PROFILE_ROUTE)(dispatch)
                            break;
                        default:
                            enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("appPassword.creating_password")}`,{ variant:"error" });
                            break;
                    }
                }
            }
        })
    },[t,enqueueSnackbar,isMounted,dispatch])

    const cancelAppPasswordSetup = useCallback(()=>{
        updateState(state => ({...state,openConfigPopUp: false}))
    },[])

    const hideDialog = useCallback(()=>{
        updateState(state => ({...state,openConfirmDialog: false}))
    },[])

    const handleApiDocumentation = ()=>{

        const newWindow = window.open(`https://docs.bettair.city/api`, '_blank')
        if (newWindow) newWindow.opener = null
    }

    const confirmDelete = useCallback(()=>{
        updateState(state => ({...state,openConfirmDialog: false,deleting: true}))
        Promise.all(deletePasswordRequest(selectionModel)).then(() => {
            if (!isMounted.current) {return}
            updateTable();
        }).catch(err => {
            if (!isMounted.current) {return}
            if(err.response){
                switch (err.response.status){
                    case 401:
                        expiredSession(PROFILE_ROUTE)(dispatch)
                        break;
                    default:
                        enqueueSnackbar(`${t("error")} ${err.response.status},
                         ${t("appPassword.deleting_password")}`,{ variant:"error" });
                        break;
                }
            }else {
                enqueueSnackbar(`${t("appPassword.error_deleting_password")}`,{ variant:"error" });
            }
        });
    },[t,dispatch,enqueueSnackbar,isMounted,selectionModel,updateTable])

    const columns = [
        { field: 'id', headerName: 'ID',  hide:true },
        { field: 'name', headerName: t("appPassword.name") , width: 340},
        { field: 'date', headerName: t("appPassword.created"),width: 240,
            renderCell: (params) => (
                <Typography>{dateToReadableUTCString(params.row.created)}</Typography>
            )}
    ];

    return (
        <>
        <Card className={className}  >
            {loading && <CardViewLoading/>}
            {!loading && error === "" && <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={4} lg={6} >
                    <Typography className={classes.organizationName} variant={"h4"}>{t("appPassword.app_password")}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} >
                    <Button
                        fullWidth
                        data-testid={ "create-password-button"}
                        disabled={deleting}
                        onClick={handleNewAppPassword}
                        color={"primary"}
                        variant="contained"
                        startIcon={<VpnKeyIcon />}
                    >
                        {t("appPassword.create_app_password")}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Button
                        fullWidth
                        data-testid={ "delete-password-button"}
                        disabled={  !enableDelete || deleting}
                        onClick={handleDelete}
                        variant="contained"
                        className={classes.deleteButton}
                        startIcon={<DeleteIcon />}
                    >
                        {t("delete")}
                    </Button>
                </Grid>
                <Grid item container  xs={12} style={{paddingTop:4}} >
                    <Button
                        startIcon={<DescriptionOutlinedIcon/>}
                        onClick={handleApiDocumentation}
                    >
                        {t("dashboardMenu.api_manual")}
                    </Button>
                </Grid>

                <Grid item xs={12} className={classes.rowTable} >
                    <DataGrid
                        loading={deleting}
                        className={classes.table}
                        rows={passwords}
                        columns={columns.map((column) => {
                                return {
                                    ...column,disableClickEventBubbling:column.field === "registered"
                                }
                            }
                        )}
                        pageSize={7}
                        Name="dataGrid1"
                        selectionModel={selectionModel}
                        onSelectionModelChange={onSelectionModelChange}
                        checkboxSelection  density={"standard"}
                    />
                </Grid>
            </Grid>}
            {!loading && error !== "" && <DataNotFound message={error}/>}
        </Card>
            {createdPassword !== "" && <CreatedPasswordPopUp password={createdPassword} handleClose={handlePasswordDisplayClosed}/>}

            {openConfigPopUp && <AppPasswordConfigPopUp open={openConfigPopUp}
                                         finishAppPasswordSetup={finishAppPasswordSetup}
                                         cancelAppPasswordSetup={cancelAppPasswordSetup}/>}
            {openConfirmDialog && <ConfirmDialog hideDialog={hideDialog} tittle={t("appPassword.delete_app_password")}
                                                 question={t("appPassword.are_you_sure_to_delete")}
                                                 confirm={confirmDelete}/>
            }
        </>
    );
};

export default AppPasswordCardView;
