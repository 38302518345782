import React, {useCallback, useEffect} from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import PeriodSelector, {CUSTOM_RANGE} from "../../analytics/common/PeriodSelector";
import useDateRange from "../../../../../hooks/useDateRange";
import {makeStyles} from "@material-ui/styles";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import {useTranslation} from "react-i18next";

const Step1 = ({name,nameCallback,pollutantUnit,pollutantCallback,
                   tempUnit,tempCallback,period,initialDate,updateDate,windSpeedCallback,windSpeedUnit}) => {


    const {t} = useTranslation()
    const useStyles = makeStyles({
        saveButton:{
            color:'#ffffff',
        },
        changePasswordButton:{
            marginTop:24
        },
        card: {
            maxWidth:1600,
            width:"100%",
            display:"flex",
            background:"white",
            padding:40,
            marginTop: 20,
            marginLeft:20,
            marginRight:20,
            borderRadius: 6,
        },
        form:{
            width:"100%",
        },
        email:{
            color:"#8a8a8a"
        },
        emailTittle:{
            fontWeight:"bold"
        },
        unitsTittle:{
           marginTop:32,
           color:"#c4c4c4",
            marginBottom:0
        },
        grip:{
            marginTop:12,

        },
        rowMargin:{
        },
    });

    const classes = useStyles();
    const [{openDateRangePicker,dateRange},updateOpenDatePickerCallback,
        updateDatePickedCallback,clearDataRange] = useDateRange();

    useEffect(()=>{
        if(initialDate !== null){
            updateDatePickedCallback(initialDate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleSelectorChange = useCallback((event)=>{
        if( Number(event.target.value) !== CUSTOM_RANGE){
            clearDataRange();
            updateDate(event.target.value,null)
        }
    },[clearDataRange,updateDate]);

    const onCustomPressedCallback = useCallback(()=>{
            updateOpenDatePickerCallback(true);
        }
        ,[updateOpenDatePickerCallback]);

    useEffect(
        ()=>{
            if(dateRange != null){
                updateDate(CUSTOM_RANGE,dateRange);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[dateRange]);


    return (
        <><DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                 onDateRangePicked={updateDatePickedCallback} />
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={1}/>
            <Grid item xs={4}>
                <TextField
                    inputProps={{ "data-testid": "download-step1-name" }}
                    onChange={nameCallback}
                    value={name}
                    label={t("downloadScreen.popUp.download_name")} fullWidth/>
            </Grid>
            <Grid item xs={4} >
                <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"none"}>
                    <InputLabel>{t("units.concentration_units")}</InputLabel>
                    <Select
                        label={t("units.concentration_units")}
                        onChange={pollutantCallback}
                        value={pollutantUnit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"eu"}>µg/m³ - mg/m³</MenuItem>
                        <MenuItem value={"usa"}>{t("units.ppm_ppb")}</MenuItem>

                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <PeriodSelector loading={false}
                                period={period}
                                handleSelectorChange={handleSelectorChange}
                                dateRange={dateRange}
                                onCustomPressedCallback={onCustomPressedCallback}
                />
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={1}/>
            <Grid item xs={4} className={classes.rowMargin}>
                <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                    <InputLabel>{t("units.temperature_units")}</InputLabel>
                    <Select
                        label={t("units.temperature_units")}
                        onChange={tempCallback}
                        value={tempUnit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"celsius"}>Celsius (ºC)</MenuItem>
                        <MenuItem value={"fahrenheit"}>Fahrenheit (ºF)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4} >
                <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                    <InputLabel>{t("units.wind_speed_unit")}</InputLabel>
                    <Select
                        label={t("units.wind_speed_unit")}
                        onChange={windSpeedCallback}
                        value={windSpeedUnit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"mps"}>{t("units.meter_per_second")}</MenuItem>
                        <MenuItem value={"mph"}>{t("units.mile_per_hour")}</MenuItem>
                        <MenuItem value={"knots"}>{t("units.knots")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        <Grid item xs={3}/>
        </Grid>
        </>
    );
};

export default Step1;
