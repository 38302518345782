import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Grid, Link, Typography} from "@material-ui/core";
import {dateToReadableDateTimeString} from "../../../../utils/dateToReadableDateTimeString";
import {useTranslation} from "react-i18next";

const NodeDetail = ({index,device,deviceSelectedCallback}) => {

    const { t } = useTranslation();

    const useStyles = makeStyles({
        box: {
            width:300,
            padding:6
        },
        mainTittle:{
            color:"#696969",
            fontWeight:"bold"
        },
        tittle:{
            color:"#696969",
        },
        value:{
            marginLeft:4,
            color:"#9f9f9f"
        },
        active:{
            marginLeft:4,
            color:"#26D13D"
        },
        inactive:{
            marginLeft:4,
            color:"#c61218"
        },
        link:{
            marginTop:16,
            textAlign:"right",
            marginRight:16
        }
    });

    const handleOnClick  = ()=>{
        deviceSelectedCallback(device.id)
    }

    const classes = useStyles();


    return (
        <Grid container className={classes.box}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={classes.mainTittle}>{device.alias}</Typography>
            </Grid>
            <Grid item container xs={12} className={classes.card}>
                <Typography variant={"subtitle1"} className={classes.tittle}>{t("home.battery")}</Typography>
                <Typography variant={"subtitle1"} className={classes.value}>{`${device.battery}%`}</Typography>
            </Grid>
            <Grid item container xs={12} className={classes.card}>
                <Typography variant={"subtitle1"} className={classes.tittle}>{index === "aqi" ? "AQI" : index === "caqi" ? "CAQI" : "error"}</Typography>
                <Typography variant={"subtitle1"} className={classes.value}>{device.index === undefined ? "N/A":
                    (index === "caqi" && device.index === "101") ? ">100" : device.index}</Typography>
            </Grid>
            <Grid item container xs={12} className={classes.card}>
                <Typography variant={"body2"} className={classes.tittle}>{t("home.last_connection")} </Typography>
                <Typography variant={"body2"}
                            className={classes.value}>{dateToReadableDateTimeString(new Date(device.lastConnection))}</Typography>
            </Grid>
            <Grid item container xs={12} className={classes.card}>
                <Typography variant={"body2"} className={classes.tittle}>{t("home.state")} </Typography>
                <Typography variant={"body2"}
                            className={device.state === "online" || device.state === "active" ? classes.active : classes.inactive}>{device.state}</Typography>
            </Grid>
            <Grid item className={classes.link} xs={12}>
                <Link  onClick={handleOnClick} variant="body2">
                    {t("home.more_detail")}
                </Link>
            </Grid>
        </Grid>
    );
};

export default NodeDetail;
