import React from 'react';
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const ErrorFetchingDataMessage = () => {
    const useStyles = makeStyles((theme) => {
        return {
            notFound:{
                display:"flex",
                height:"100%",
                width:"100%",
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            },
            text:{
                color:"#a9a9a9"
            }
        }
    });
    const classes = useStyles();
    return (
        <div className={classes.notFound}>
            <Typography variant={"h4"} align={"center"} className={classes.text} >
                Error fetching data for selected period
            </Typography>
        </div>
    );
};

export default ErrorFetchingDataMessage;
