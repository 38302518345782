import {Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import CompareSensorDataCardView from "./compare_data_card/CompareSensorDataCardView";
import CompareStatisitcCardView from "./statistic_data_card/CompareStatisitcCardView";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateCompareStationsAction} from "../../../../reducers/dashboardUIReducer";
import NoStationSelected from "./NoStationSelected";
import CompareAmbientNoiseCardView from "./noise_data_card/CompareAmbientNoiseCardView";
import VerifyingDevice from "../analytics/VerifyingDevice";
const queryString = require('query-string');

export const compareStationsColors = [ '#1A23D0',
    '#D01A55',
    '#40D01A',
    '#efca11',
    '#1ad0a6',
    '#496186',
    '#e76735',
    '#991ad0',
    '#000',
    '#7B7B7B'

];

export const CompareScreen = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const { stations,selectCompareStations } = useSelector( state => state.dashboardUI );
    const [{validatedUrl},updateState] = useState({validatedUrl:false})

    useEffect(()=>{
        const parsedValues = queryString.parse(location.search);
        if(parsedValues.id === undefined){
            dispatch(updateCompareStationsAction([]))
        }else {
            if(Array.isArray(parsedValues.id)){
                let selectedStations = stations.filter( value => {
                    return parsedValues.id.includes(value.id)
                })
                dispatch(updateCompareStationsAction(selectedStations))
            }else {
                let selectedStations = stations.filter( value => {
                    return parsedValues.id === value.id
                })
                dispatch(updateCompareStationsAction(selectedStations))
            }
        }
        updateState({validatedUrl: true})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location,stations])

    const useStyles = makeStyles( {
            root: {
                maxWidth:1800,
                paddingBottom:20,
                paddingTop:20,
                paddingLeft:40,
                paddingRight:40,
            },
            card: {
                background:"white",
                padding:20,
                borderRadius: 5,
                minHeight:245
            },
            firstCard: {
                height:800
            },
            secondCard:{
                height:600
            },
            thirdCard: {
                height:540
            },
            loading:{
                height:600
            }
        }
    );

    const classes = useStyles();

    return (
        <Grid className={classes.root} container direction={"row"}
              alignItems={"center"} justifyContent="space-around" spacing={2} >

            {selectCompareStations.length > 0 && validatedUrl &&  <Grid item xs={12}>
                <CompareSensorDataCardView className={clsx(classes.card,classes.firstCard)} boxShadow={2} />
            </Grid>}
            {selectCompareStations.length > 0 && validatedUrl &&   <Grid item xs={12}>
                <CompareAmbientNoiseCardView className={clsx(classes.card,classes.secondCard)} boxShadow={2} />
            </Grid>}
            {selectCompareStations.length > 0 && validatedUrl && <Grid item xs={12}>
                <CompareStatisitcCardView className={clsx(classes.card,classes.thirdCard)} boxShadow={2} />
            </Grid>}
            {selectCompareStations.length === 0 && validatedUrl &&  <Grid item xs={12}>
                <NoStationSelected/>
            </Grid> }
            {!validatedUrl && <VerifyingDevice className={classes.loading}/>}
        </Grid>
    );
};

export default CompareScreen;
