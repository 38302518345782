import React, {useEffect, useState} from 'react';
import { Button, Card, Grid, Snackbar, TextField, Typography, Alert} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import validator from "validator";
import {useHistory, useLocation} from "react-router-dom";
import {joinOrganizationRequest, validateInvitationRequest} from "../../../../requests/invitationRequests";
import {useDispatch, useSelector} from "react-redux";
import {
    removeRequestErrorAction,
    setRequestError,
    setSuccessRequest,
    startLoadingAction,
    stopLoadingAction
} from "../../../../reducers/requestUIReducer";
import LoadingFullScreen from "../../../common/LoadingFullScreen";
import InvalidInvitationScreen from "./InvalidInvitationScreen";
import {LOGIN_ROUTE, MINIMUN_PASSWORD_LENGHT} from "../../../../constants";
import { logoutAction} from "../../../../reducers/authReducer";
import {hashGenerator} from "../../../../utils/hashGenerator";
import {forgotChangePasswordRequest} from "../../../../requests/auth/authRequests";
import ServerErrorScreen from "../../../common/ServerErrorScreen";
import {useTranslation} from "react-i18next";
const queryString = require('query-string');


const useStyles = makeStyles({
    saveButton:{
        color:'#ffffff',
    },
    card: {
        maxWidth:1600,
        width:"100%",
        minHeight:350,
        background:"white",
        padding:80,
        marginTop: 20,
        marginLeft:20,
        marginRight:20,
        borderRadius: 6
    },
    email:{
        color:"#8a8a8a"
    },
    emailTittle:{
        fontWeight:"bold"
    },
    grip:{
        marginTop:22
    },
    form:{
        width:"100%"
    },
    background:{
        display:"flex",
        background:"#eaeaea",
        height:"100%",
        width:"100vw",
        justifyContent:"center"
    }
});

const InvitationAndResetScreen = () => {

    const {t} = useTranslation()
    const classes = useStyles();
    let location = useLocation();
    let history = useHistory();
    let dispatch = useDispatch();
    const { loading,error } = useSelector( state => state.requestUI );

    useEffect(
        ()=>{
            const parsedValues = queryString.parse(location.search);
            let token = parsedValues.token || null

          if(token === null){
              updateErrorMessage(t("invitationAndResetScreen.invalid_link"));
          }else {
              dispatch(startLoadingAction())
              validateInvitationRequest(token,(data,error)=>{
                  if(!error){
                      updateRegisterScreen(data.create,data.organization)
                  }else {
                      switch (data.status){
                          case 404:
                              updateErrorMessage(t("invitationAndResetScreen.invalid_link"));
                              break;
                          case 401:
                              updateErrorMessage(t("invitationAndResetScreen.expired_link"));
                              break;
                          case 400:
                              updateErrorMessage(t("invitationAndResetScreen.no_token_provided"));
                              break;
                          case 500:
                              updateServerError();
                              break;
                          default:
                              //dispatch(setRequestError(t("invitationAndResetScreen.unknown_error")));
                              break;
                      }
                  }
                  dispatch(stopLoadingAction())
              });
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[dispatch,location.search]);


    const initialFormValues ={name:'',lastName:'', password:'', confirmPassword:'',
        organization:'',emptyFields:true,showPasswordError:false,showConfirmPasswordError:false,
         validInvitation:false, errorMessage:'',isJoinOrganizationTokenType:false,serverError:false}

    const [{name,lastName,password,confirmPassword,emptyFields,
    showPasswordError,showConfirmPasswordError, organization,
    validInvitation,errorMessage, isJoinOrganizationTokenType,
        serverError}, updateFormState] = useState(initialFormValues);


    const updateRegisterScreen = (isJoinOrganizationType,organizationName)=>{
        updateFormState(state =>{
            return {...state,organization:organizationName,validInvitation:true,
                isJoinOrganizationTokenType:isJoinOrganizationType}
        });
    }

    const updateErrorMessage = (message)=>{
        updateFormState(state =>{
            return {...state,errorMessage:message,validInvitation:false}
        });
    }

    const handleName = (event)=>{
        const value = event.target.value
        const emptyField = validateEmptyFields(value,undefined,
            undefined,undefined);
        updateFormState(state =>{
           return {...state, name:value,emptyFields:emptyField }
        });
    };

    const handleLastName = (event)=>{
        const value = event.target.value
        const emptyField = validateEmptyFields(undefined,value,
            undefined,undefined);
        updateFormState(state =>{
            return {...state, lastName:value,emptyFields:emptyField}
        });
    };

    const handlePassword = (event)=>{
        updatePasswordError(false);
        updateConfirmPasswordError(false);
        const value = event.target.value
        const emptyField = validateEmptyFields(undefined,
            undefined,value,undefined);
        updateFormState(state =>{
            return {...state, password:value,emptyFields:emptyField}
        });
    };

    const handleConfirmPassword = (event)=>{
        updateConfirmPasswordError(false);
        const value = event.target.value
        const emptyField = validateEmptyFields(undefined,
            undefined,undefined,value);
        updateFormState(state =>{
            return {...state, confirmPassword:value,emptyFields:emptyField}
        });
    };

    const handleSave = async ()=>{
       if(validatePassword()){
           const parsedValues = queryString.parse(location.search);
           let token = parsedValues.token || null
           let digestHex = await hashGenerator(password);
           if(isJoinOrganizationTokenType){
               joinOrganization(token,digestHex);
           }
           else {
               updatePassword(token,digestHex);
           }
       }
    }

    const joinOrganization = (token,digestHex)=>{
        dispatch(startLoadingAction())
        joinOrganizationRequest({name,lastName,token,pass:digestHex},(data,err)=>{
            dispatch(stopLoadingAction())
            if(!err){
                localStorage.removeItem('user_email');
                dispatch(logoutAction())
                dispatch(setSuccessRequest(t("invitationAndResetScreen.successfully_joined")));
                history.replace(LOGIN_ROUTE);
            }else {
                switch (data.status){
                    case 400:
                        updateErrorMessage(t("invitationAndResetScreen.an_error_occurred_check_info"));
                        break;
                    case 401:
                        updateErrorMessage(t("invitationAndResetScreen.invitation_link_expired"));
                        break;
                    case 500:
                        updateServerError();
                        break;
                    default:
                        dispatch(setRequestError(t("invitationAndResetScreen.unknown_error")));
                        break;
                }
            }
        });
    }

    const updateServerError = ()=>{
        updateFormState(state =>({...state,serverError: true}));
    }

    const updatePassword = (token,digestHex)=>{
        dispatch(startLoadingAction())
        forgotChangePasswordRequest({token,pass:digestHex},(data,err)=>{
            dispatch(stopLoadingAction())
            if(!err){
                localStorage.removeItem('user_email');
                dispatch(setSuccessRequest(t("invitationAndResetScreen.password_sucessfully_updated")));
                history.replace(LOGIN_ROUTE);
            }else {
                switch (data.status){
                    case 400:
                        updateErrorMessage(t("invitationAndResetScreen.an_error_occurred_check_info"));
                        break;
                    case 401:
                        updateErrorMessage(t("invitationAndResetScreen.recover_link_expired"));
                        break;
                    case 500:
                        updateServerError();
                        break;
                    default:
                        dispatch(setRequestError(t("invitationAndResetScreen.unknown_error")));
                        break;
                }
            }
        });
    }

    const validatePassword = ()=>{
        if(password.trim().length < MINIMUN_PASSWORD_LENGHT){
            updatePasswordError(true);
            return false;
        }
        if(password !== confirmPassword){
            updateConfirmPasswordError(true);
            return false;
        }
        return true;
    }

    const updatePasswordError= (showError)=>{
        updateFormState(state =>{
            return {...state, showPasswordError:showError}
        });
    }
    const updateConfirmPasswordError= (showError)=>{
        updateFormState(state =>{
            return {...state, showConfirmPasswordError:showError}
        });
    }

    const validateEmptyFields = (nameForm = name,lastNameForm = lastName,
                                 passwordForm = password, confirmPasswordForm = confirmPassword)=> {
       if(isJoinOrganizationTokenType){
           return (validator.isEmpty(nameForm) ||
               validator.isEmpty(lastNameForm) ||
               validator.isEmpty(passwordForm) ||
               validator.isEmpty(confirmPasswordForm))
       }else {
           return (
               validator.isEmpty(passwordForm) ||
               validator.isEmpty(confirmPasswordForm))
       }
    }

    const handleOnErrorSnackClose = ()=>{
        dispatch(removeRequestErrorAction());
    }

    return (
        <div className={classes.background}>
            {validInvitation && <Card className={classes.card} >
                <form  noValidate className={classes.form}>
                    <Typography className={classes.emailTittle}  variant={"h3"}>{isJoinOrganizationTokenType
                        ? t("invitationAndResetScreen.invitation_to_join") : t("invitationAndResetScreen.reset_password")}</Typography>
                    <Typography className={classes.email}  variant={"h4"}>{organization}</Typography>
                    <Grid className={classes.grip} container direction={"row"} spacing={1}>
                        { isJoinOrganizationTokenType &&  <Grid item xs={12} md={6}>
                            <TextField  autoFocus = {isJoinOrganizationTokenType} label={t("invitationAndResetScreen.name")} variant="outlined"
                                        required fullWidth  margin="normal"
                                        inputProps={{ "data-testid": "invitation-name" }}
                                        onChange={handleName} value={name}
                            />
                        </Grid>}
                        { isJoinOrganizationTokenType && <Grid item xs={12} md={6}>
                            <TextField  label={t("invitationAndResetScreen.last_name")} variant="outlined"
                                        required
                                        fullWidth  margin="normal"
                                        onChange={handleLastName}
                                        value={lastName}/>
                        </Grid>}

                        <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus = {!isJoinOrganizationTokenType}
                                error={showPasswordError}
                                label={showPasswordError ? t("error"): t("invitationAndResetScreen.password") }
                                helperText={showPasswordError ? `${t("invitationAndResetScreen.password_too_short")}
                                 ${MINIMUN_PASSWORD_LENGHT} ${t("invitationAndResetScreen.characters")}characters`:
                                    ` ${t("invitationAndResetScreen.password_must_contain")} ${MINIMUN_PASSWORD_LENGHT} ${t("invitationAndResetScreen.characters")}`  }
                                variant="outlined"
                                required
                                type={"password"}
                                fullWidth  margin="normal"
                                onChange={handlePassword}
                                value={password}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                error={showConfirmPasswordError}
                                label={showConfirmPasswordError ? t("error"): t("invitationAndResetScreen.confirm_password") }
                                helperText={showConfirmPasswordError ? t("invitationAndResetScreen.confirm_must_match")
                                    : t("invitationAndResetScreen.confirm_your_password")}
                                variant="outlined"
                                required
                                type={"password"}
                                inputProps={{ "data-testid": "invitation-password" }}
                                fullWidth  margin="normal"
                                onChange={handleConfirmPassword}
                                value={confirmPassword}/>
                        </Grid>
                        <Grid item md={8} lg={9}> </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button disabled={emptyFields || loading} className={classes.saveButton}
                                    color={"primary"} fullWidth
                                    onClick={handleSave} size={"large"}
                                    variant="contained">{isJoinOrganizationTokenType ? t("invitationAndResetScreen.join") : t("invitationAndResetScreen.reset")}</Button>

                        </Grid>
                    </Grid>
                </form>
            </Card>}
            {!validInvitation && <InvalidInvitationScreen errorMessage={errorMessage}/>}
            <LoadingFullScreen loading={loading} />
            <Snackbar open={error!==null} autoHideDuration={3000} onClose={handleOnErrorSnackClose} >
                <Alert severity="error">{error}</Alert>
            </Snackbar>
            {serverError && <ServerErrorScreen/>}
        </div>

    );
};

export default InvitationAndResetScreen;
