import {webClient} from "../../../config/axios";
import {handleError} from "../../handleRequestError";


export const downloadAlarmsRequest = (callback) => {
    webClient.get('/alarms')
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};