import React from 'react';
import DatePicker from '@material-ui/lab/DatePicker';
import {TextField} from "@material-ui/core";
import LocalizaitonProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import {useTranslation} from "react-i18next";
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';


export const getLocale = ()=>{
    if(window.navigator.language.includes("es-ES")){
        return esLocale
    }
    else {
        return enLocale
    }
}

const CalendarMonthSelector = ({className,date,handleDateChange,includeCurrentMonth = true}) => {

    const {t} = useTranslation()
    const currentDate = new Date()

    return (
        <LocalizaitonProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
        <DatePicker
            className={className}
            views={['month','year']}
            label={t("analyticScreen.calendar.select_date")}
            minDate={new Date("2019-12-01")}
            maxDate={new Date(currentDate.getFullYear(),
                includeCurrentMonth ? currentDate.getMonth()+1:currentDate.getMonth(), 0)}
            value={date}
            onChange={handleDateChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    margin="normal"
                    helperText={null}
                    variant="standard"
                />
            )}
        />
        </LocalizaitonProvider>
    );
};

export default CalendarMonthSelector;
