import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Button, Card, CardContent, Grid, Link, Typography} from "@material-ui/core";
import CardLoading from "../analytics/common/CardLoading";
import DeleteIcon from "@material-ui/icons/Delete";
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import {DataGrid} from "@material-ui/data-grid";
import AlarmConfigPopUp from "./configure_alarms/AlarmConfigPopUp";
import {dateToReadableDateTimeString} from "../../../../utils/dateToReadableDateTimeString";
import {pollutantNames} from "../pollutantNames";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {unitsMap} from "../unitsNames";
import AlarmStationListPopUp from "./AlarmStationListPopUp";
import {getTriggerName} from "../triggerNames";
import {expiredSession} from "../../../../reducers/authReducer";
import {ALARM_ROUTE} from "../../../../constants";
import {useMountComponent} from "../../../../hooks/useMountComponent";
import {useSnackbar} from "notistack";
import {deleteAlarmRequest} from "../../../../requests/alarms/schedule/deleteAlarmRequest";
import {createAlarmRequest} from "../../../../requests/alarms/schedule/createAlarmRequest";
import {downloadAlarmsRequest} from "../../../../requests/alarms/schedule/downloadAlarmRequest";
import {setAlarmsAction} from "../../../../reducers/alarmReducer";

const ScheduleAlarmCardView = ({className}) => {


    const {t} = useTranslation()
    const isMounted = useMountComponent();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const { alarms } = useSelector( state => state.alarm );
    const [{enableDelete,selectionModel,loading,openConfirmDialog,openAddAlarm,viewStationList},updateState] =
        useState({selectionModel:[],
            openConfirmDialog:false,
            enableDelete:false,
            data:[],loading:false,
            selectedDate:new Date(),
            error:false,
            openAddAlarm:false,
            viewStationList:[]
        });

    const useStyles = makeStyles(({
        cardTittle:{
            marginLeft:20,
            color:"#000000",
            fontWeight:"bold"
        },
        deleteButton:{
            color:"#ffffff",
            background:"#f6343d",
            '&:hover': {
                background:"#81191c"
            },
        },
        rowTable:{
            display:"flex",
            width:"100%vw",
            justifyContent:"center",
            marginTop:32
        },
        table:{
            maxWidth:1600,
            height: 390,
            '& .super-app.current_user': {
                fontWeight: '500',
                color: '#bcbcbc',
                backgroundColor: 'rgba(105,105,105,0.05)',
            },
            '& .super-app.other_user': {
            },
        }

    }));

    useEffect(()=>{
        updateTable()
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[])

    const handleViewStationList = (stationList)=>{
        updateState(state => ({...state,viewStationList:stationList }))
    }

    const closeViewStationListCallback = useCallback(()=>{
        updateState(state => ({...state,viewStationList:[] }))
    },[])

    const columns = [
        { field: 'id', headerName: 'ID',  hide:true },
        { field: 'name', headerName: t("alarmScreen.popUp.name"), width: 220 },
        { field: 'pollutant', headerName: t("alarmScreen.sensor") , width: 140,
            renderCell: (params) => (
                <Typography>{ pollutantNames.get(params.value)}</Typography>
            )
        },
        { field: 'stationId', headerName: t("alarmScreen.stations") , width: 140,
            renderCell: (params) => (
                <Link component="button" underline="always" onClick={ event =>{
                    handleViewStationList(params.value)
                }}>{t("alarmScreen.view")}</Link>
            )
        },
        { field: 'trigger', headerName:t("alarmScreen.trigger") ,width: 160,
            renderCell: (params) => (
                <Typography>{ getTriggerName(params.value,t)}</Typography>
            )
        },
        {field: 'value', headerName: t("alarmScreen.threshold"),width: 140,
            renderCell: (params) => (
                <Typography>{`${params.value} ${ unitsMap.get(params.row.units)}`}</Typography>
            )
        },
        { field: 'created', headerName: t("alarmScreen.creating_date"), width: 220,
            renderCell: (params) => (
                <Typography>{ dateToReadableDateTimeString(new Date(params.value))}</Typography>
            )}
    ];

    const classes = useStyles();

    const hideDialog = useCallback(()=>{
        updateState(state => ({...state,openConfirmDialog: false}))
    },[])

    const onSelectionModelChange = (params)=>{
        updateState(state =>{
            return {...state,enableDelete:(params.selectionModel.length !== 0),
                selectionModel:params.selectionModel}
        })
    }

    const handleDeleteButtonPressed = ()=>{
        updateState(state =>({...state,openConfirmDialog: true}))
    }

    const handleAddAlarm = ()=>{
        updateState(state =>({...state,openAddAlarm: true}))
    }

    const updateTable = useCallback(()=>{
        updateState(state => ({...state,loading: true}))
        downloadAlarmsRequest((data, err)=>{
            if (!isMounted.current) {return}
            updateState(state => ({...state,loading: false}))
            if(!err && data){
                dispatch(setAlarmsAction(data));
            }
            if(err){
                if(data.status === 401){
                    expiredSession(ALARM_ROUTE)(dispatch)
                }
                else {
                    enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("alarmScreen.error_fetching_alarms")}`,{ variant:"error" });
                }
            }
        })
    },[t,isMounted,dispatch,enqueueSnackbar])

    const confirmDelete = useCallback(()=>{
        updateState(state => ({...state,loading: true, openConfirmDialog: false }))
        Promise.all(deleteAlarmRequest(selectionModel)).then(() => {
            if (!isMounted.current) {return}
            updateState(state => ({...state,loading: false,selectionModel:[],enableDelete:false }))
            enqueueSnackbar(`${t("alarmScreen.alarm_deleted")}`,{ variant:"success" });
        }).catch(err => {
            if (!isMounted.current) {return}
            updateState(state => ({...state,loading: false,selectionModel:[],enableDelete:false}))
            if(err.response){
                switch (err.response.status){
                    case 401:
                        expiredSession(ALARM_ROUTE)(dispatch)
                        break;
                    default:
                        enqueueSnackbar(`${t("error")} ${err.response.status},
                         ${t("alarmScreen.deleting_alarm")}`,{ variant:"error" });
                        break;
                }
            }
        });
    },[t,dispatch,selectionModel,enqueueSnackbar,isMounted])

    const finishAlarmSetup = useCallback((body)=>{
        updateState(state =>({...state,loading: true,openAddAlarm: false}))
        createAlarmRequest(body,(data,err)=>{
            if (!isMounted.current) {return}
            if(!err){
                enqueueSnackbar(`${t("alarmScreen.alarm_created")}`,{ variant:"success" });
                updateState(state =>({...state,loading: false}))
            }else {
                updateState(state =>({...state,loading: false}))
                if(data.status === 401){
                    expiredSession(ALARM_ROUTE)(dispatch)
                }
                else {
                    enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("alarmScreen.creating_alarm")}`,{ variant:"error" });
                }
            }
        })
    },[t,dispatch,enqueueSnackbar,isMounted,updateState])

    const cancelAlarmSetup = useCallback(()=>{
        updateState(state =>({...state,openAddAlarm: false}))
    },[updateState])

    return (
        <>
            {openConfirmDialog && <ConfirmDialog  hideDialog={hideDialog} tittle={t("alarmScreen.delete_alarm")}
                                 question={t("alarmScreen.are_you_sure_to_delete")}
                                 confirm={confirmDelete}/>}
            {openAddAlarm && <AlarmConfigPopUp open={openAddAlarm} cancelAlarmSetup={cancelAlarmSetup}
                                               finishAlarmSetup={finishAlarmSetup}/>}
        <Card className={className}>
            {!loading &&
            <CardContent>
                <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid container  item xs={12} sm={12} md={4} lg={6} alignItems={"flex-start"} alignContent={"flex-start"}>
                        <Typography className={classes.cardTittle} variant={"h5"}>{t("alarmScreen.alarm")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                        <Button
                            fullWidth
                            onClick={handleAddAlarm}
                            disabled={loading}
                            color={"primary"}
                            variant="contained"
                            startIcon={<AddAlarmIcon />}
                        >
                            {t("alarmScreen.add_alarm")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Button
                            fullWidth
                            onClick={handleDeleteButtonPressed}
                            disabled={loading || !enableDelete }
                            variant="contained"
                            className={classes.deleteButton}
                            startIcon={<DeleteIcon />}
                        >
                            {t("alarmScreen.delete")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.rowTable} >
                        <DataGrid
                            className={classes.table}
                            rows={alarms}
                            columns={columns.map((column) => {
                                    return {
                                        ...column,disableClickEventBubbling: column.field === "stationId"
                                    }
                                }
                            )}
                            columnBuffer = {0}
                            pageSize={7}
                            Name="dataGrid1"
                            selectionModel={selectionModel}
                            onSelectionModelChange={onSelectionModelChange}
                            checkboxSelection  density={"standard"}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            }
            {loading &&
            <CardLoading/>
            }
            { viewStationList.length> 0 && <AlarmStationListPopUp
                handleCloseDetailCallback={closeViewStationListCallback}
                stationIdList={viewStationList}/>}
        </Card>
        </>
    );
}

export default ScheduleAlarmCardView;
