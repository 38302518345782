import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup
} from "@material-ui/core";
import CardTittle from "../common/CardTittle";
import Plot from "react-plotly.js";
import PeriodSelector, {CUSTOM_RANGE} from "../common/PeriodSelector";
import HelpPopup from "../common/HelpPopup";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import {makeStyles} from "@material-ui/styles";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE, DEFAULT_PERIOD} from "../../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useSnackbar} from "notistack";
import {unitsMap} from "../../unitsNames";
import {sensorStatisticRequest} from "../../../../../requests/analytics/sensorStatisticRequest";
import {pollutantNames} from "../../pollutantNames";
import useDateRange from "../../../../../hooks/useDateRange";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import {roundAccurately} from "../../../../../utils/roundNumbers";
import './chart_style.css';
import DataNotFound from "../../../../common/DataNotFound";
import ChartLoading from "../common/ChartLoading";
import ErrorFetchingDataMessage from "../common/ErrorFetchingDataMessage";
import {useTranslation} from "react-i18next";

const baseSensorData ={
    type: 'bar',
    marker: {color: '#741AD0'}
}

const initialPlotLayout = {
    autosize: true,
    legend:{x: -0.22, y: 0.9},
    yaxis: {
        position: 0.07,
        title: 'µg/m³',
    },
    yaxis2: {
        range: [0, 50],
        anchor: 'free',
        overlaying: 'y',
        side: 'right',
        position: 1,
        visible:true,
        title: "Temperature ºC",
    },
    yaxis3: {
        range: [0, 100],
        anchor: 'free',
        overlaying: 'y',
        side: 'right',
        position: 0.9,
        title: "RH %",
        visible:true
    },
    yaxis4: {
        position: 0.17,
        side: 'left',
        overlaying: 'y',
        title: 'mg/m³'
    },
};

const xAxeWithTwoPollutantUnits = {
    xaxis:{showgrid:false,domain: [0.2, 0.9]}
}
const xAxeWithOnePollutantUnit = {
    xaxis:{showgrid:false,domain: [0.1, 0.9]}
}

const legendColors = ['#741AD0','#3333FF',
    '#55b42c','#000','#7B7B7B'];

const legendName = new Map();
legendName.set("ppb","Pollutants - ppb");
legendName.set("ug-m3","Pollutants - µg/m³");
legendName.set("ppm","Pollutants - ppm");
legendName.set("mg-m3","Pollutants - mg/m³");

legendName.set("celsius","Temp - ºC");
legendName.set("fahrenheit","Temp - ºF");
legendName.set("percentage","RH - %");

const SensorStatisticCardView = ({className}) => {


    const {t} = useTranslation()
    const dispatch = useDispatch();
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const initialState = {rawData:[],maxYAxeValue:0,maxY4AxeValue:0,data:[],error:"",period:DEFAULT_PERIOD,loading:true,
        plotLayout:initialPlotLayout, xPlot:xAxeWithOnePollutantUnit,type:"mean"}
    const[{rawData,data,period,loading,plotLayout,xPlot,maxYAxeValue,
        maxY4AxeValue,type,error},updateState] = useState(initialState)
    const { enqueueSnackbar } = useSnackbar();
    const isMounted = useMountComponent();
    const { selectedStation } = useSelector( state => state.dashboardUI );
    const { units } = useSelector( state => state.auth );


    useEffect(()=>{
        updateState(state =>({...state,plotLayout: {...initialPlotLayout,
                yaxis: units.pollutants === "eu" ? {...initialPlotLayout.yaxis} : {
                title: 'ppb - µg/m³',
        },
                yaxis4: units.pollutants === "eu" ? {...initialPlotLayout.yaxis4} : {
                    title: 'ppm',
                    side: 'left',
                    overlaying: 'y',
                    position: 0.1,
                },
            yaxis2: units.temperature === "celsius" ? {...initialPlotLayout.yaxis2} :{
                range: [0, 140],
                anchor: 'free',
                overlaying: 'y',
                side: 'right',
                position: 1,
                visible:true,
                title: "Temperature ºF",
            }
            }}))
    },[units.pollutants, units.temperature])


    const [{openDateRangePicker,dateRange},updateOpenDatePickerCallback,
        updateDatePickedCallback,clearDataRange] = useDateRange();

    useEffect(
        ()=>{
            if(dateRange != null){
                updateData(CUSTOM_RANGE,dateRange);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[dateRange]);

    const plotConfig = {
        modeBarButtonsToRemove:[ "select2d", "lasso2d","autoScale2d",
            "zoomIn2d", "zoomOut2d","resetScale2d","zoom2d",
            "toggleHover", "resetViews", "sendDataToCloud",
            "toggleSpikelines", "resetViewMapbox","pan2d","hoverClosestCartesian",
            "hoverCompareCartesian"
        ],
        displaylogo : false
    };

    const useStyles = makeStyles(( {
        radioGroup:{
            marginTop:30
        },
        loading:{
            left: "50%",
            position: "relative",
            top: "50%",
            zIndex:999
        },
        rightControls:{
            display:"flex",
            flexDirection:"column",
            alignItems:"flex-start"
        }
    }));
    const classes = useStyles();

    const getChartData = useCallback((fetchedData, selectedType) =>{
        let currentType = selectedType || type
        let currentData = fetchedData || rawData
        if(currentData.length === 0){
            return []
        }
        let data = currentType === "mean" ? currentData.mean : currentData.max

        let unitGroups = [];
        unitsMap.forEach((values,keys)=>{
            let sharedUnitGroup = Object.entries(data).filter( item =>{
                return item[1].units === keys;
            });
           if(sharedUnitGroup.length > 0 ){
               unitGroups.push(sharedUnitGroup);
           }
        });

        return unitGroups.map((group,index) =>{
            let units = unitsMap.get(group[0][1].units)
            let pollutants = group.map(value =>{return value[0]})

            let yaxis = group[0][1].units === "celsius" || group[0][1].units === "fahrenheit"? 'y2' :
                group[0][1].units === "percentage" ? 'y3':
                    group[0][1].units === "ppb" || group[0][1].units === "ug-m3" ? 'y1' :
                        group[0][1].units === "ppm" || group[0][1].units === "mg-m3" ? 'y4': "error";

            return  {
                name:legendName.get(group[0][1].units),
                x:pollutants.map(value =>{
                    return pollutantNames.get(value);
                }),
                y:group.map(value =>{return roundAccurately(value[1].value,2)}),
                ...baseSensorData,
                marker: {color:legendColors[index] ,size:2},
                yaxis,
                hovertemplate: `<b>%{x}</b>: %{y} ${units}`,
                hoverlabel:{namelength:0}
            }
        });
    },[rawData,type]);

    const updateData = useCallback((selectedPeriod,dateRange)=>{
        let currentPeriod = selectedPeriod || period
        updateState(state =>{
            return {...state,loading: true, data: [],error: "",rawData:[], period: currentPeriod}
        });

        let selectedRange = getDateRange(currentPeriod,dateRange)
        sensorStatisticRequest(units.temperature,units.pollutants,selectedStation,
            selectedRange[0],
            selectedRange[1], (data,err)=>{
                if (!isMounted.current) {return}
                if(!err){
                    updateState(state =>{

                        return {...state,loading: false,
                            rawData:data,
                            maxYAxeValue: getMaxYAxeValue("y1",data),
                            maxY4AxeValue:getMaxYAxeValue("y4",data),
                            xPlot: isDataComposedByTwoPollutantUnits(data) ?
                                xAxeWithTwoPollutantUnits : xAxeWithOnePollutantUnit,
                            data:getChartData(data,null)
                        }
                    })
                }
                else {
                    if(data.status === 404){
                        updateState(state =>{
                            return {...state,loading: false}
                        });
                        enqueueSnackbar(t("analyticScreen.statistic.statistic_data_not_found"),{ variant:"info" });
                    }
                    else {
                        updateState(state =>{
                            return {...state,loading: false,error:data.status}
                        });
                        if(data.status === 401){
                            expiredSession(ANALYTICS_ROUTE)(dispatch)
                        }
                        else {
                            enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.statistic.could_not_update_data")}`,{ variant:"error" });
                        }
                    }
                }
            } );
    },[t,dispatch,units.temperature,units.pollutants,enqueueSnackbar,getChartData,isMounted,period,selectedStation]);

    const getMaxYAxeValue = (axe,data)=>{
        let aux = Object.entries(data.max)
        let max = 0
        aux.forEach(pollutant => {
            if(pollutant[1].units === (axe === "y1" ? "ppb" : "ppm") ||
                pollutant[1].units === (axe === "y1" ? "ug-m3" : "mg-m3")){
                if(pollutant[1].value>max){
                    max = pollutant[1].value
                }
            }
        })
       return max
    }

    const isDataComposedByTwoPollutantUnits = (data)=>{
        let aux = Object.entries(data.max)
        let containsMicroGramsOrPpb = false
        let containsMilligramsOrPpm = false
        aux.forEach((value)=>{
            if(value[1].units === "ppb" || value[1].units ==="ug-m3"){
                containsMicroGramsOrPpb = true
            }
            if(value[1].units === "ppm" || value[1].units ==="mg-m3"){
                containsMilligramsOrPpm = true
            }
        });
        return containsMicroGramsOrPpb && containsMilligramsOrPpm
    }

    useEffect(()=>{
        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedStation]);

    const handleSelectorChange = useCallback((event)=>{
        if( Number(event.target.value) !== CUSTOM_RANGE){
            clearDataRange();
            updateData(event.target.value,null)
        }
    },[updateData,clearDataRange]);

    const onCustomPressedCallback = useCallback(()=>{
            updateOpenDatePickerCallback(true);
        }
        ,[updateOpenDatePickerCallback]);

    const handleRadioChange = (event) =>{
        let type = event.target.value
        updateState(state =>{
            return{...state,data:getChartData(null,type),type}
        });
    }



    return (
        <Card className={className}>
            <DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                onDateRangePicked={updateDatePickedCallback} />
            <Grid container>
                <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                    <CardTittle tittle={t("analyticScreen.statistic.statistic")} setAnchorEl={setAnchorEl}/>
                </Grid>
                {data.length>0 && <Grid  container item xs={10} className={"statistic"}>
                    <Plot
                        useResizeHandler = {true}
                        layout={{...plotLayout,
                            yaxis:{...plotLayout.yaxis, range: [0,maxYAxeValue]},
                            yaxis4:{...plotLayout.yaxis4, range: [0,maxY4AxeValue]},
                            ...xPlot}}
                        data={data}
                        onInitialized={(figure) => this.setState(figure)}
                        onUpdate={
                            (figure) =>
                                this.setState(figure)
                        }
                        config = {plotConfig}>
                    </Plot>
                </Grid>}
                {(data.length === 0 && !loading) &&  <Grid container item xs={10} className={"notFoundStatisticData"} >
                    <DataNotFound/>
                </Grid>}
                {error !== "" &&  <Grid container item xs={10} className={"notFoundStatisticData"} >
                    <ErrorFetchingDataMessage/>
                </Grid>}
                {loading &&  <Grid container item xs={10} className={"notFoundStatisticData"} >
                    <ChartLoading/>
                </Grid>}
                <Grid item className={classes.rightControls} xs={2}>
                    <PeriodSelector loading={loading}
                                    period={period}
                                    handleSelectorChange={handleSelectorChange}
                                    dateRange={dateRange}
                                    onCustomPressedCallback={onCustomPressedCallback}
                    />

                    <FormControl className={classes.radioGroup} disabled={loading}>
                        <FormLabel component="legend">Value</FormLabel>
                        <RadioGroup  value={type} onChange={handleRadioChange}>
                            <FormControlLabel value="mean" control={<Radio />} label={t("analyticScreen.statistic.mean")} />
                            <FormControlLabel value="max" control={<Radio />} label={t("analyticScreen.statistic.max")} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("analyticScreen.statistic.en_analytics_stationStatistic")}/>
        </Card>
    );
};

export default SensorStatisticCardView;
