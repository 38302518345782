import React, {useCallback, useEffect, useState} from 'react';
import {Grid, ImageList, ImageListItem, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {calendarAQIColors, calendarCAQIColors} from "./calendarAQIColors";
import {pollutantNames} from "../../pollutantNames";

export const DAYS_IN_MS = 86400000;

const CalendarComponent = ({date,data,indexType}) => {


    const [{state,overElement},updateState] = useState({state:[],overElement:-1})

    useEffect(
        ()=>{
            populateMonthFromPreviousMonth(data,date)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[date,data]);

    const getFirstDateOfSelectedMonth = useCallback((numberOfDaysBefore,lastDateOfPreviousMonth,date) =>{
        return numberOfDaysBefore === 0 ? getFirstDayStartingOnMonday(date) :
            getFirstDayStartingNotMonday(lastDateOfPreviousMonth,numberOfDaysBefore);
    },[])

    const populateMonthFromPreviousMonth = useCallback((data,currentSelectedDate)=>{

        let currentMonthStartDayOfWeek = getDayOfWeek(currentSelectedDate)

        let numberOfDaysBefore =  currentMonthStartDayOfWeek === 1 ? 0 :
            currentMonthStartDayOfWeek !== 0 ?  currentMonthStartDayOfWeek - 1 : 6


        let lastDateOfPreviousMonth = new Date(new Date(currentSelectedDate.getFullYear(), currentSelectedDate.getMonth(), 1).getTime() - (DAYS_IN_MS));

        let firstDatOfCalendar = getFirstDateOfSelectedMonth(numberOfDaysBefore,lastDateOfPreviousMonth,currentSelectedDate)

        let arrayData = Object.entries(data).map(value =>{
            return {...value,date : new Date(currentSelectedDate.getFullYear(), currentSelectedDate.getMonth(), Number(value[0]))}
        });


        let newState = Array.apply(null, Array(42)).map(function (x, i) {
            let currentDay = new Date(firstDatOfCalendar.getTime() + (i*DAYS_IN_MS));
            let dayData = arrayData.find(value => {
                    return value.date.getFullYear() === currentDay.getFullYear() &&
                    value.date.getMonth() === currentDay.getMonth() &&
                    value.date.getDate() === currentDay.getDate()
            }
            );
            return {id:i,
                day:currentDay.getDate(),
                value: dayData !== undefined ? dayData[1]?.value : null,
                index: dayData !== undefined ? dayData[1].index : null,
                pollutant: dayData !== undefined ? dayData[1].pollutant : null,
                belongToCurrentMonth: belongToCurrentMonth(currentDay,currentSelectedDate)
            };
        });
        updateState(oldState => ({...oldState,state:newState}));
    },[getFirstDateOfSelectedMonth]);



    function getFirstDayStartingOnMonday(date){
        return new Date(date.getFullYear(), date.getMonth(), 1)
    }

    function getFirstDayStartingNotMonday(lastDateOfPreviousMonth,numberOfDaysBefore){
       return new Date(lastDateOfPreviousMonth.getTime() - ((numberOfDaysBefore - 1)*DAYS_IN_MS))
    }

    function belongToCurrentMonth (date, currentSelectedDate){
        let firstDay = new Date(currentSelectedDate.getFullYear(), currentSelectedDate.getMonth(), 1);
        let lastDay = new Date(currentSelectedDate.getFullYear(), currentSelectedDate.getMonth() + 1, 0);
        return date>= firstDay && date <= lastDay
    }

    function getDayOfWeek(date){
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return firstDay.getDay(); //start from 0 sunday
    }

    const useStyles = makeStyles(({
        whiteCell:{
            background:"#fff"
        },
        grayCell:{
            background:"#EDEDED"
        },
        ...calendarAQIColors,

        ...calendarCAQIColors,

        dayWithingSelectedMonth:{
            fontSize:"x-small",
            marginRight:2,
            color:"#3e3e3e"
        },
        dayOutOfSelectedMonth:{
            color:"#cdcdcd"
        },
        pollutant:{
            margin:"auto"
        },
        calendar:{
            marginTop:0,
            marginBottom:0
        }
    }));

    const classes = useStyles();


    const handleMouseOver= (id,index) =>{
        if(index !== null){
            updateState(state =>({...state,overElement:id}))
        }else {
            updateState(state =>({...state,overElement:-1}))
        }
    }

    const handleMouseLeave= (index) =>{
        if(index === null){
            updateState(state =>({...state,overElement:  -1 }))
        }
    }

    function getCellColorByValue(value){


        let  color
        if(indexType === "aqi"){
            switch (value){
                case 1 :
                    color = classes.green
                    break;
                case 2 :
                    color = classes.yellow
                    break;
                case 3 :
                    color = classes.orange
                    break;
                case 4 :
                    color = classes.red
                    break;
                case 5 :
                    color = classes.purple
                    break;
                case 6 :
                    color = classes.darkPurple
                    break;
                default:
                    color = classes.grayCell
                    break;
            }
            return color;
        } else if (indexType === "caqi"){
            switch (value){
                case 1 :
                    color = classes.greenCAQI
                    break;
                case 2 :
                    color = classes.greenYellowCAQI
                    break;
                case 3 :
                    color = classes.yellowCAQI
                    break;
                case 4 :
                    color = classes.orangeCAQI
                    break;
                case 5 :
                    color = classes.redCAQI
                    break;
                default:
                    color = classes.grayCell
                    break;
            }
            return color;
        }

    }

    return (
        <ImageList rowHeight={50} cols={7} gap={2} className={classes.calendar} onMouseLeave={ event => handleMouseLeave(null)}>
            {state.map(value =>{
                return <ImageListItem key={value.id}
                                      className={ overElement !== value.id ? getCellColorByValue(value.index) :
                                          classes.whiteCell }>
                    <Grid container onMouseOver={ event => handleMouseOver(value.id, value.index)
                    }
                          onMouseLeave={event => handleMouseLeave(value.index)}

                    >
                        <Grid item xs={12}>
                            <Typography variant={"subtitle2"} align={"right"}
                                className={value.belongToCurrentMonth === true ?
                                    classes.dayWithingSelectedMonth : classes.dayOutOfSelectedMonth }
                            >{value.day}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align={"center"} className={classes.pollutant}
                                        variant={"subtitle2"} >
                                { overElement !== value.id ?  pollutantNames.get(value.pollutant) :
                                    (indexType === "caqi" && value.value === "101") ? ">100" : value.value}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}/>
                    </Grid>
                </ImageListItem>
            })}
        </ImageList>
    );
};

export default CalendarComponent;
