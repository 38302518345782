import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const stationInfoRequest = (stationId,callback) => {
    webClient.get(`/stations/${stationId}`)
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};

export const updateAliasRequest = (body,callback) => {
    webClient.put('/stations/alias', body, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};