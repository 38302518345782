import React, {useCallback} from 'react';
import {Backdrop, Card, Divider, Grid, Typography} from "@material-ui/core";
import DownloadSteps from "./DownloadSteps";
import {makeStyles} from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import {useSelector} from "react-redux";
import validator from "validator";
import { getDateRangeIncludingEndDate} from "../../../../utils/requestPeriodGenerator";
import {useTranslation} from "react-i18next";

const DownloadConfigPopUp = ({open, finishDownloadSetup, cancelDownload}) => {

    const {t} = useTranslation()
    const useStyles = makeStyles((
        {
            card:{
                paddingTop:16,
                paddingBottom:10,
            },
            tittle:{
                marginLeft:30,
                color:"#434343",
                marginBottom:16
            },
            buttons:{
                marginRight:30,
                display:"flex",
                marginTop:10,
                justifyContent:"flex-end"
            },
            divider:{
                marginBottom:32
            },
            divider2:{
                marginTop:8
            },
            steps:{
                marginRight:20,
                marginLeft:20
            },
            stepContainer:{
                margin:40
            },
            popUpContainer:{
                maxWidth:980
            },
            step1Container:{
                paddingTop:60,
                paddingLeft:30,
                paddingRight:30,
                maxWidth:1000,
                justifyContent:"center"
            },
            step2Container:{
                padding:30,
                maxWidth:700,
                justifyContent:"center"
            }
        }
    ));
    const classes = useStyles();


    const { units } = useSelector( state => state.auth );
    const [{activeStep,
        name, pollutantUnits,tempUnits, period,dateRange,step1Valid,winSpeedUnit,
        selectedStations,step2Valid,
        selectedVariables,step3Valid,
    }, updateState] = React.useState(
        {activeStep:0,
            name:"",pollutantUnits:units.pollutants,tempUnits:units.temperature,period:30,dateRange:null,step1Valid:false,
            winSpeedUnit:units.windSpeed
            ,selectedStations:[],step2Valid:false,
            selectedVariables:[],step3Valid:false
        });


    //Step 1 callbacks
    const nameCallback = useCallback((event)=>{
        let value = event.target.value
        let step1Valid = !validator.isEmpty(value)
        updateState(state=>({...state,name:value,step1Valid}))
    },[])

    const windSpeedCallback = useCallback((event)=>{
        updateState(state => ({...state,winSpeedUnit: event.target.value }))
    },[])

    const pollutantCallback = useCallback((event)=>{
        updateState(state => ({...state,pollutantUnits: event.target.value }))
    },[])

    const tempCallback = useCallback((event)=>{
        updateState(state => ({...state,tempUnits: event.target.value }))
    },[])

    const updateDateCallback = useCallback((period,dateRange)=>{
        updateState(state => ({...state,period,dateRange}))
    },[])

    //Step 2 callbacks
    const selectedStationsCallback = useCallback((stations)=>{
        updateState(state=>({...state,selectedStations: stations, step2Valid: stations.length>0}))
    },[])

    //Step 3 callbacks
    const selectedVariablesCallback = useCallback((variables)=>{
        updateState(state=>({...state,selectedVariables: variables, step3Valid:variables.length>0 }))
    },[])


    const handleFirstButton = ()=>{
        if (activeStep===0){
            cancelDownload();
        }else {
            updateState(state => ({...state,activeStep: activeStep-1}))
        }
    }

    const handleSecondButton = ()=>{
        if (activeStep<=1){
            updateState(state => ({...state,activeStep: activeStep+1}))
        }else {
            let selectedRange = getDateRangeIncludingEndDate(period,dateRange);
            finishDownloadSetup({name,
                pollutants:pollutantUnits,
                temperature:tempUnits,
                windSpeed:winSpeedUnit,
                stations:selectedStations.map(item => item.id),
                variables:selectedVariables.map(item=>item.id),
                time:selectedRange[0],
                endtime:selectedRange[1],
            })
        }
    }


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Card className={classes.card} data-testid={"create-download-popup"} >
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.tittle}>{t("downloadScreen.popUp.configure_download")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid item xs={12} className={classes.steps}>
                         <DownloadSteps activeStep={activeStep}/>
                    </Grid>
                    {activeStep === 0 && <Grid container item xs={12} className={classes.step1Container} >
                        <Step1
                            windSpeedUnit={winSpeedUnit} windSpeedCallback={windSpeedCallback}
                            pollutantUnit={pollutantUnits} pollutantCallback={pollutantCallback}
                               tempUnit={tempUnits} tempCallback={tempCallback}
                            period={period} name={name} nameCallback={nameCallback}
                        initialDate={dateRange} updateDate={updateDateCallback}/>
                    </Grid>}
                    {activeStep === 1 && <Grid container item xs={12} className={classes.step2Container} >
                        <Step2 initialSelection={selectedStations} callback={selectedStationsCallback} />
                    </Grid>}
                    {activeStep === 2 && <Grid container item xs={12} className={classes.step2Container} >
                        <Step3 selectedStations={selectedStations}
                               initialSelection={selectedVariables} callback={selectedVariablesCallback} />
                    </Grid>}
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            {activeStep !== 0 && <Button onClick={cancelDownload}>{t("cancel")}</Button>}
                            <Button onClick={handleFirstButton}>{activeStep === 0 ? t("cancel") : t("back")}</Button>
                            <Button
                                data-testid={"download-config-next"}
                                disabled={activeStep === 0 ? !step1Valid : activeStep === 1 ? !step2Valid : !step3Valid  }
                                onClick={handleSecondButton}>{activeStep <=1 ? t("next") : t("finish")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default DownloadConfigPopUp;
