import React from 'react';
import {ImageList, ImageListItem, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";

const CalendarDaysTittle = () => {

    const {t} = useTranslation()
    let daysTittle = [t("analyticScreen.calendar.monday"),t("analyticScreen.calendar.tuesday"),
        t("analyticScreen.calendar.wednesday"),t("analyticScreen.calendar.thursday"),
        t("analyticScreen.calendar.friday"),t("analyticScreen.calendar.saturday"),
        t("analyticScreen.calendar.sunday")]

    const useStyles = makeStyles(({
        tittle:{
            marginBottom:8,
            marginTop:8
        },
        label:{
            fontWeight:"normal"
        }
    }));

    const classes = useStyles();

    return (
        <ImageList rowHeight={25} cols={7} gap={0} className={classes.tittle}>
            {daysTittle.map(value =>{
                return <ImageListItem key={value} >
                    <Typography className={classes.label} variant={"subtitle2"} align={"center"}>{value}</Typography>
                </ImageListItem>
            })}
        </ImageList>
    );
};

export default CalendarDaysTittle;
