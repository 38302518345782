import React from 'react';
import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const ChartLoading = () => {

    const useStyles = makeStyles((theme) => {
        return {
            notFound:{
                display:"flex",
                height:"100%",
                width:"100%",
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            },
        }
    });
    const classes = useStyles();
    return (
        <div className={classes.notFound}>
            <CircularProgress  />
        </div>
    );
};

export default ChartLoading;
