import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#3333FF',
        },
        secondary: {
            main: '#0D0E41',
        },
    },
});

export default theme;