import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader, Divider, Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {makeStyles} from "@material-ui/styles";
import sensor from '../../../../../assets/ic_sensor.png'
import DeviceInfoList from "./DeviceInfoList";
import {useDispatch, useSelector} from "react-redux";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {stationInfoRequest} from "../../../../../requests/analytics/stationInfoRequest";
import CardLoading from "../common/CardLoading";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE} from "../../../../../constants";
import {useSnackbar} from "notistack";
import UpdateAliasPopUp from "./UpdateAliasPopUp";
import {updateAliasAction} from "../../../../../reducers/dashboardUIReducer";
import {dateToReadableDateTimeString} from "../../../../../utils/dateToReadableDateTimeString";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Warning from "../../../../common/Warning";

const DeviceDescriptionCardView = ({className}) => {

    const { t } = useTranslation();
    let history = useHistory();
    const { selectedStation } = useSelector( state => state.dashboardUI );
    const isMounted = useMountComponent();
    const initialValue = useRef({deviceInfo:[],state:[],lastConnection:"",deviceId:null,
        alias:"",loading:true,openPopUp:false,error:""})
    const [{deviceInfo,state,lastConnection,alias,loading,openPopUp,deviceId,error},updateState] = useState(initialValue.current);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        loadDeviceInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedStation]);

    const getDeviceInfoFromData = useCallback((data)=>{
        return [{
            name:t("analyticScreen.deviceDescription.serial_number"),
            value:data.serial,
            id:1
        },{
            name:t("analyticScreen.deviceDescription.model"),
            value:data.model,
            id:2
        },{
            name:t("analyticScreen.deviceDescription.id"),
            value:data.id,
            id:3
        },{
            name:t("analyticScreen.deviceDescription.fw"),
            value:data.fw!== undefined ? data.fw:t("not_available"),
            id:4
        }]
    },[t])

    const getStateFromData = useCallback((data)=>{

        return [{
            name:t("analyticScreen.deviceDescription.state"),
            value: data.state,
            color: data.state === "active" ? "#26D13D" : "#c61218",
            id:1
        },{
            clickable: data.position !== undefined,
            name:t("analyticScreen.deviceDescription.location"),
            value: data.position !== undefined ? `${data.position.lat}, ${data.position.long}` :t("not_available") ,
            id:2
        },{
            name:t("analyticScreen.deviceDescription.battery_level"),
            value:data.battery !== undefined ? `${data.battery}%`:t("not_available"),
            id:3
        },{
            name:t("analyticScreen.deviceDescription.last_data"),
            small:true,
            value: data.lastData !== undefined ? `${dateToReadableDateTimeString(new Date(data.lastData))}`:t("not_available"),
            id:4
        }]
    },[t])

    const loadDeviceInfo = useCallback(()=>{
        updateState(initialValue.current);
        stationInfoRequest(selectedStation,(data,err)=>{
            if(isMounted.current){
                 if(!err){
                         updateState({
                             deviceId: data.id,
                             openPopUp: false,
                             deviceInfo: getDeviceInfoFromData(data),
                             state: getStateFromData(data),
                             loading: false,
                             lastConnection: getLastConnection(data.lastConnection),
                             alias: data.alias,
                             error: ""
                         })
                 }else {
                     updateState({...initialValue.current,loading: false})
                     if(data.status === 401){
                         expiredSession(ANALYTICS_ROUTE)(dispatch)
                     }else {
                         updateState(state =>({...state,error:data.status}))
                         enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.deviceDescription.could_not_update_info")}`,{ variant:"error" });
                     }
                 }
            }
        })
    },[getStateFromData,getDeviceInfoFromData,t,dispatch,enqueueSnackbar,initialValue,isMounted,selectedStation]);

    const getLastConnection = (stringDate)=>{
        return stringDate !== undefined ? (dateToReadableDateTimeString(new Date(stringDate))) :"" ;
    }

    const useStyles = makeStyles((theme) => ({
        cardTittle:{
            display:"flex",
            alignItems:"center"
        },
        root: {
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: "white",
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
    }));

   const onCLosePopUpCallback = useCallback((successChange, data)=>{
       updateState(state =>({...state,openPopUp: false,alias: successChange ? data[1] : state.alias}))
       if(successChange){
           dispatch(updateAliasAction(data[0],data[1]))
       }
   },[dispatch])

    const handleOpenPopUp = ()=>{
        updateState(state =>({...state,openPopUp:true}))
    }

    const locationCallback = useCallback((item)=>{
        let data = item.split(",")
        history.push(`/home?lat=${data[0]}&long=${data[1].trim()}&zoom=16`);

    },[history])

    const classes = useStyles();

    return (
        <Card className={className}>

            {error === "" && <>
            { !loading && <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}
                            src={sensor}>
                    </Avatar>
                }
               title={<div className={classes.cardTittle}>
                    <Typography data-testid={"device-alias"} variant={"h5"} >{alias}</Typography>
                    <IconButton
                        data-testid={"device-update-alias"}
                        aria-label="edit" onClick={handleOpenPopUp} >
                        <EditRoundedIcon/>
                    </IconButton>
                </div>}
                subheader={<Typography variant={"subtitle1"}
                                       data-testid={"device-last-connection"} >
                    {t("analyticScreen.deviceDescription.last_connection")}
                    {lastConnection} {lastConnection !== "" ? "(UTC)":t("not_available")}</Typography>}
                disableTypography = {true}
                 />}
            { !loading &&<CardContent>
                <Grid container direction={"row"} justifyContent={"space-between"}>
                    <Grid item xs={5}>
                       <DeviceInfoList list={deviceInfo}/>
                    </Grid>
                    <Grid item xs={1}>
                        <Divider orientation="vertical" variant="middle"/>
                    </Grid>
                    <Grid item xs={5}>
                        <DeviceInfoList list={state} locationCallback={locationCallback}/>
                    </Grid>
                </Grid>
            </CardContent>}
            </>}
            {error !== "" && <Warning message={t("error") +" "+ error}/>}

            {loading && <CardLoading/>}
            <UpdateAliasPopUp
                stationId={deviceId}
                open={openPopUp}
                              handleClose={onCLosePopUpCallback}
                              currentAlias={alias}/>
        </Card>
    );
};

export default DeviceDescriptionCardView;
