import React from 'react';
import { Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import warning from '../../assets/ic_warning_big.png'
import {useTranslation} from "react-i18next";

const Warning = ({message} ) => {

    const { t } = useTranslation();
    const notFoundMessage = message || t("error")

    const useStyles = makeStyles((theme) => {
        return {
            notFound:{
                display:"flex",
                height:"100%",
                width:"100%",
                flexDirection:"column",
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            },
            text:{
                color:"#dddddd"
            },
            img:{
                width :80,
                paddingBottom : 10,
                opacity: 0.9
            },

        }
    });
    const classes = useStyles();
    return (
        <div className={classes.notFound}>
            <img className={classes.img} src={warning} alt="warning"/>
            <Typography
                data-testid={"warning-message"}
                variant={"h5"} align={"center"} className={classes.text} >
                {notFoundMessage}
            </Typography>
        </div>
    );
};

export default Warning;
