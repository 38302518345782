import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const sensorLogRequest = (stationId,callback) => {
    let url = `stations/log/${stationId}?limit=50`
    webClient.get(url)
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};