import React, {useEffect, useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import CompareIcon from '@material-ui/icons/Compare';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HomeIcon from '@material-ui/icons/Home';
import { useSelector} from "react-redux";
import {Badge} from "@material-ui/core";
import {useLocation} from "react-router-dom";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import {ALARM_ROUTE, DOWNLOAD_ROUTE, HOME_ROUTE, REPORT_ROUTE} from "../../../../constants";
import {useTranslation} from "react-i18next";

export const DrawerListItems = ({selectedPageCallback})=>{

    const { t } = useTranslation();
    const location = useLocation();
    const [{selectedTab}, updateState] = useState({selectedTab:-1});

    const handleOnClick = (position)=>{
        selectedPageCallback(position);
    }
    const { notifications } = useSelector( state => state.notification );
    const updateTabPosition = (position)=>{
        updateState({selectedTab:position})
    }

    useEffect(()=>{
        if (location.pathname.includes("/analytics")){
            updateTabPosition(1);
            return;
        }
        if (location.pathname.includes("/compare")){
            updateTabPosition(2);
            return;
        }
        switch (location.pathname){
            case HOME_ROUTE:
                updateTabPosition(0);
                break;
            case ALARM_ROUTE:
                updateTabPosition(3);
                break;
            case DOWNLOAD_ROUTE:
                updateTabPosition(4);
                break;
            case REPORT_ROUTE:
                updateTabPosition(5);
                break;
            default:
                updateTabPosition(-1);
                break;
        }
    },[location])


   return  <>
        <ListItem button onClick={ (_)=>{ handleOnClick(0)}} selected={selectedTab===0}>
            <ListItemIcon>
                <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={t("drawer.home")} />
        </ListItem>
        <ListItem
            data-testid={"dashboard-analytic"}
            button  onClick={ (_)=>{ handleOnClick(1)}} selected={selectedTab===1} >
            <ListItemIcon>
                <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary={t("drawer.analytics")} />
        </ListItem>
        <ListItem
            data-testid={"dashboard-compare"}
            button  onClick={ (_)=>{ handleOnClick(2)}} selected={selectedTab===2}>
            <ListItemIcon>
                <CompareIcon />
            </ListItemIcon>
            <ListItemText primary={t("drawer.compare")} />
        </ListItem>
       <ListItem
           data-testid={"dashboard-alarm"}
           button  onClick={ (_)=>{ handleOnClick(3)}} selected={selectedTab===3}>
           <ListItemIcon>
               <Badge badgeContent={notifications.length} color="secondary">
                   <NotificationsIcon />
               </Badge>
           </ListItemIcon>
           <ListItemText primary={t("drawer.alarms")} />
       </ListItem>
        <ListItem
            data-testid={"dashboard-download"}
            button  onClick={ (_)=>{ handleOnClick(4)}} selected={selectedTab===4}>
            <ListItemIcon>
                <CloudDownloadIcon />
            </ListItemIcon>
            <ListItemText primary={t("drawer.download")} />
        </ListItem>

       <ListItem
           data-testid={"dashboard-report"}
           button  onClick={ (_)=>{ handleOnClick(5)}} selected={selectedTab===5}>
           <ListItemIcon>
               <DescriptionIcon />
           </ListItemIcon>
           <ListItemText primary={t("drawer.reports")} />
       </ListItem>

       <ListItem button  onClick={ (_)=>{ handleOnClick(6)}} selected={selectedTab===6}>
           <ListItemIcon>
               <AnnouncementIcon />
           </ListItemIcon>
           <ListItemText primary={t("drawer.send_suggestions")} />
       </ListItem>
    </>
}


