import React from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {
    dateToReadableUTCString
} from "../../../../../utils/dateToReadableDateTimeString";
import {useTranslation} from "react-i18next";

export const CUSTOM_RANGE = -1;

const PeriodSelector = ({loading, period,handleSelectorChange, onCustomPressedCallback, dateRange}) => {

    const { t } = useTranslation();
    const useStyles = makeStyles( ({
            formControl: {
                minWidth: 120,
            }
        }
    ));

    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} disabled={loading}>
            <InputLabel>{t("periodSelector.period")}</InputLabel>
            <Select
                value={period}
                onChange={ event => handleSelectorChange(event)}
            >
                <MenuItem value={1}>{t("periodSelector.last_day")}</MenuItem>
                <MenuItem value={7}>{t("periodSelector.last_7_day")}</MenuItem>
                <MenuItem value={30}>{t("periodSelector.last_30_day")}</MenuItem>
                <MenuItem value={60}>{t("periodSelector.last_60_day")}</MenuItem>
                <MenuItem onClick={onCustomPressedCallback}    value={CUSTOM_RANGE}>{t("periodSelector.custom_period")}</MenuItem>

            </Select>
            { !(dateRange === null || dateRange === undefined) &&
            <FormHelperText>{`${dateToReadableUTCString(dateRange.startDate)} - 
            ${dateToReadableUTCString(dateRange.endDate)}`}</FormHelperText>}
        </FormControl>
    );
};

export default PeriodSelector;
