import React, {useCallback, useEffect, useState} from 'react';
import {
    Button,
    Card,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ORGANIZATION_ROUTE} from "../../../../../constants";
import {useDispatch} from "react-redux";
import HelpPopup from "../../analytics/common/HelpPopup";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {
    organizationRequest,
    updateLocale
} from "../../../../../requests/organization/organizationRequests";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useSnackbar} from "notistack";
import CardViewLoading from "../../analytics/common/ChartLoading";
import DataNotFound from "../../../../common/DataNotFound";
import CommonDialog from "../../../../CommonDialog";
import {useTranslation} from "react-i18next";

const Preferences = ({className}) => {

    const { t } = useTranslation();
    const [{organizationLocale,loading,enableSave,error,openWarningDialog}, updateState] = useState({
        organizationLocale: null,loading:true,error:"",enableSave:true,openWarningDialog:false
    });
    const isMounted = useMountComponent();
    const { enqueueSnackbar } = useSnackbar();
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const dispatch = useDispatch()
    const useStyles = makeStyles({
        saveButton:{
            color:'#ffffff',
        },
        form:{
            width:"100%",
        },
        tittle:{
            fontWeight:"bold"
        },
        grip:{
            marginTop:12,
        },
        helpButton:{
            color:"black"
        }
    });

    const classes = useStyles();

    useEffect(()=>{
        updateData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLanguageChange = (event) =>{
        updateState(state =>({...state,organizationLocale:event.target.value}))
    }


    const updateData = useCallback(()=>{
        organizationRequest((data,error)=> {
            if (!isMounted.current) {return}
            if (!error) {
                updateState(state => {
                    return {...state, organizationLocale: data.locale,loading:false,error:  ""}
                })
            } else {
                switch (data.status){
                    case 401:
                        expiredSession(ORGANIZATION_ROUTE)(dispatch)
                        break;
                    default:
                        updateState(state => {
                            return {...state, organizationLocale: null ,loading:false,error: t("organizationPreferences.could_not_fetch_organization_preference")}
                        })
                        enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("organizationPreferences.could_not_fetch_organization_preference")}`,{ variant:"error" });
                        break;
                }
            }
        });
    },[t,enqueueSnackbar,dispatch,isMounted]);

    const handleWarningDialogClose = useCallback(()=>{
        updateState(state=>({...state,openWarningDialog: false}))
    },[])

    const handleSave = ()=>{
        updateLocale({locale:organizationLocale },(data,error) =>{
            updateState(state =>({...state,enableSave: false}))
            if (!isMounted.current) {return}
            if(!error){
                updateState(state =>({...state,enableSave: true,openWarningDialog:true}))
                enqueueSnackbar(t("organizationPreferences.locale_update"),{ variant:"success" });
            }else {
                switch (data.status){
                    case 401:
                        expiredSession(ORGANIZATION_ROUTE)(dispatch)
                        break;
                    default:
                        updateState(state => {
                            return {...state ,loading:false,enableSave: true}
                        })
                        enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("organizationPreferences.updating_organization_locale")}`,{ variant:"error" });
                        break;
                }
            }
        })
    }

    return (
        <>
            <Card className={className} >
                {loading && <CardViewLoading/>}
                {!loading && error === "" &&  <div style={{position:"relative",width:"100%"}}><form  noValidate className={classes.form} >
                    <Grid className={classes.grip} container direction={"row"} spacing={2}>
                        <Grid item container xs={12}  >
                            <Typography className={classes.tittle} variant={"h4"}>{t("organizationPreferences.title")}</Typography>
                            <IconButton className={classes.helpButton} aria-label="help"
                                        onClick={e => setAnchorEl(e.currentTarget)}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Grid>

                        <Grid item xs={12} md={6} >
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                                <InputLabel >{t("organizationPreferences.language")}</InputLabel>
                                <Select
                                    id={"preference-selector-language"}
                                    data-testid = "preference-language"
                                    disabled={!enableSave}
                                    onChange={handleLanguageChange}
                                    label={t("organizationPreferences.language")}
                                    value={organizationLocale}
                                >
                                    <MenuItem value={"es"}>{t("organizationPreferences.spanish")}</MenuItem>
                                    <MenuItem value={"en"}>{t("organizationPreferences.english")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}/>

                        <Grid item md={8} lg={9}/>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button
                                data-testid={"preference-save"}
                                className={classes.saveButton}
                                     disabled={!enableSave}
                                     color={"primary"} fullWidth
                                    onClick={handleSave} size={"large"}
                                     variant="contained">{t("save")}</Button>

                        </Grid>
                    </Grid>
                </form>
                    {!enableSave && <div style={{position:"absolute",top:0,
                        left: 0,width:"100%",height:"100%",
                        display:"flex", flexDirection:"row",alignSelf:"center",
                        justifyContent:"center"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>}
                </div>
                    }
                {!loading && error !== "" && <DataNotFound message={error}/>}
            </Card>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("organizationPreferences.help")}/>
            {openWarningDialog && <CommonDialog
                title={t("warning")}
                handleClose={handleWarningDialogClose}
                description={t("organizationPreferences.previous_report_keep_language")}/>}
        </>
    );
};

export default Preferences;
