import React from 'react';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import UserDataCardView from "./user_data/UserDataCardView";
import AppPasswordCardView from "./app_password/AppPasswordCardView";

const ProfileScreen = () => {

    const useStyles = makeStyles({
        root: {
            maxWidth:1800,
            paddingBottom:20,
            paddingTop:20,
            paddingLeft:40,
            paddingRight:40,
        },
        userDataCard: {
            display:"flex",
            padding:20,
            borderRadius: 5,
            minHeight:500
        },
        appPasswordCard: {
            display:"flex",
            padding:20,
            borderRadius: 5,
            minHeight:600
        }
    });

    const classes = useStyles();

    return (
        <Grid container direction={"row"} className={classes.root}
              alignItems={"center"} justifyContent="space-around" spacing={2}>
            <Grid item xs={12}>
                <UserDataCardView className={classes.userDataCard}/>
            </Grid>
            <Grid item xs={12}>
                <AppPasswordCardView className={classes.appPasswordCard}/>
            </Grid>
        </Grid>
    );
};

export default ProfileScreen;
