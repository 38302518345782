import React from 'react';
import LoadingFullScreen from "./LoadingFullScreen";
import {Snackbar, Alert} from "@material-ui/core";
import {removeRequestErrorAction, removeSuccessRequest} from "../../reducers/requestUIReducer";
import {useDispatch, useSelector} from "react-redux";

const RequestInfoComponent = () => {

    const dispatch = useDispatch();

    const { loading, error, success } = useSelector( state => state.requestUI );

    const handleOnErrorSnackClose =()=>{
        dispatch(removeRequestErrorAction())
    }

    const handleOnSuccessSnackClose =()=>{
        dispatch(removeSuccessRequest())
    }

    return (
        <div>
            <LoadingFullScreen loading={loading}/>
            <Snackbar open={error!==null} autoHideDuration={3000} onClose={handleOnErrorSnackClose} >
                <Alert severity="error">{error}</Alert>
            </Snackbar>
            <Snackbar open={success!==null} autoHideDuration={3000} onClose={handleOnSuccessSnackClose} >
                <Alert severity="success">{success}</Alert>
            </Snackbar>
        </div>
    );
};

export default RequestInfoComponent;
