import "./map_style.css"
import React, {useCallback, useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/styles";
import {drawerWidth} from "../dashboard/Dashboard";
import clsx from "clsx";
import {useSelector} from "react-redux";
import ReactDOM from "react-dom";
import NodeDetail from "./NodeDetail";
import {useHistory, useLocation} from "react-router-dom";
import MapLegend from "./legend/MapLegend";
import mapBoxGL from 'mapbox-gl';
const queryString = require('query-string');

const HomeScreen = () => {

    const location = useLocation();
    const history = useHistory();
    const { drawerOpen } = useSelector( state => state.dashboardUI);
    const { stations } = useSelector( state => state.dashboardUI );
    const { units,organization } = useSelector( state => state.auth );

    const navigateToAnalyticsCallback = useCallback((deviveId)=>{
        history.push(`/analytics/${deviveId}`);
    },[history]);

    const useStyles = makeStyles((theme) => {
        return {
            root:{
                border:"none",
                position:"relative",
                left:0,
                top:0
            },
            rootHeight:{
                height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
                '@media (min-width:0px) and (orientation: landscape)':{
                    height: `calc(100vh - 48px)`
                },
                '@media (min-width:600px)':{
                    height: `calc(100vh - 64px)`
                }
            },
            rootWidth: {
                width: `calc(100vw - ${drawerOpen ? `${drawerWidth}px` : theme.spacing(7)})`,
                [theme.breakpoints.up('sm')]:{
                    width:`calc(100vw - ${drawerOpen ? `${drawerWidth}px` : theme.spacing(9)})`
                }
            }
        }
    });

    const usePopUp = useRef(makeStyles(({
            popUp: {
                zIndex:1999,
            }
    })));

    const classes = useStyles();
    const popUpClass = useRef(usePopUp.current());
    const mapRef = useRef(null);
    const markers = useRef(null);
    const detailRef = useRef(null);

    const updateDetailScreen = useCallback((deviceId)=>{
        const device = stations.find(element => element.id === deviceId);
        ReactDOM.render(
            <NodeDetail index={units.index} device={device} deviceSelectedCallback ={navigateToAnalyticsCallback}  />
            , document.getElementById('detailScreen')
        );
    },[units.index,stations,navigateToAnalyticsCallback])

    const showDetail = useCallback((deviceId, position) =>{
        let markerHeight = 20, markerRadius = 12, linearOffset = 25;
        let popupOffsets = {
            'top': [0, markerHeight],
            'top-left': [0,0],
            'top-right': [0,0],
            'bottom': [0, -markerHeight],
            'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
            'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
            'left': [markerRadius, (markerHeight - markerRadius) * -1],
            'right': [-markerRadius, (markerHeight - markerRadius) * -1]
        };

        detailRef.current?.remove();
        detailRef.current = new mapBoxGL.Popup({className:popUpClass.current.popUp,maxWidth:600,
            closeOnClick: false ,offset:popupOffsets,focusAfterOpen:false})
            .setLngLat([position.long, position.lat])
            .setHTML(`<div id="detailScreen"></div>`)
            .addTo(mapRef.current);
        updateDetailScreen(deviceId);
    },[updateDetailScreen,popUpClass]);

    useEffect(()=>{

        const parsedValues = queryString.parse(location.search);

        mapBoxGL.accessToken = 'pk.eyJ1Ijoiam1zYWJpbjEzMjciLCJhIjoiY2ttZWx0dHZhMndmbDJ4a25sazFiOWs5ZiJ9.bm1M1koxEnJI_jwx5OCbyQ';
        mapRef.current = new mapBoxGL.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/light-v10',
            boxZoom:true,
            zoom:parsedValues.zoom !== undefined ? parsedValues.zoom : organization.map.zoom,
            center:(parsedValues.long !== undefined &&  parsedValues.lat !== undefined) ?
                [parsedValues.long,parsedValues.lat ]:[organization.map.center.lon,organization.map.center.lat]
        });
        mapRef.current?.addControl(new mapBoxGL.NavigationControl());
        mapRef.current?.dragRotate.disable();

        return ()=>{
            mapRef.current = null
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getAQUIImage = useCallback((value,state)=>{

        if(state === "offline"){
            return "ic_warning.png"
        }

        if(value === undefined){
            return "ic_gray_sensor.png"
        }

        if(units.index === "aqi"){
            if(value <= 50){
                return "ic_green_sensor.png"
            }else if(value <= 100) {
                return "ic_yellow_sensor.png"
            }
            else if(value <= 150) {
                return "ic_orange_sensor.png"
            }
            else if(value <= 200) {
                return "ic_red_sensor.png"
            }
            else if(value <= 300) {
                return "ic_purple_sensor.png"
            }
            else {
                return "ic_dark_purple_sensor.png"
            }
        }else if(units.index === "caqi"){
            if(value <= 25){
                return "ic_caqi_green.png"
            }else if(value <= 50) {
                return "ic_caqi_yellow_green.png"
            }
            else if(value <= 75) {
                return "ic_caqi_yellow.png"
            }
            else if(value <= 100) {
                return "ic_caqi_orange.png"
            }
            else if(value > 100) {
                return "ic_caqi_red.png"
            }
        }
    },[units.index])

    useEffect(()=>{


        const stationsWithLocation  = stations.filter(station => station.position !== undefined)

        markers.current?.forEach(value =>{
            if(value !== undefined){
                value.remove();
            }
        })

        markers.current = stationsWithLocation.map(value =>{
              let el = document.createElement('div');
              el.id = value.id
              el.alias = value.alias
              el.position = value.position
              el.style.backgroundImage = `url(${getAQUIImage(value.index,value.state)})`;
              el.style.width = 20 + 'px';
              el.style.height = 20 + 'px';
              el.style.backgroundSize = '100%';

              el.addEventListener('click', function () {
                  showDetail(el.id, el.position);
              });
              return new mapBoxGL.Marker(el)
                  .setLngLat({lng:value.position.long,lat:value.position.lat}).addTo(mapRef.current);
        });
    },[getAQUIImage,showDetail,stations])


    return (
        <div>
            <div className={"no-border-map"} >
                 <div className={clsx(classes.rootHeight, classes.rootWidth,classes.root)} id="map"/>
            </div>
            <MapLegend indexType={units.index}/>
        </div>
    );
};

export default HomeScreen;
