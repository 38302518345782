import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    Grid,
    IconButton,
} from "@material-ui/core";
import Plot from 'react-plotly.js';
import {makeStyles} from "@material-ui/styles";
import './chart_style.css';
import {sensorDataRequest} from "../../../../../requests/analytics/sensorDataRequest";
import { useSnackbar } from 'notistack';
import RefreshIcon from '@material-ui/icons/Refresh';
import {useDispatch, useSelector} from "react-redux";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE, DEFAULT_PERIOD, pollutants} from "../../../../../constants";
import HelpPopup from "../common/HelpPopup";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import PeriodSelector, {CUSTOM_RANGE} from "../common/PeriodSelector";
import CardTittle from "../common/CardTittle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import {unitsMap} from "../../unitsNames";
import {pollutantNames} from "../../pollutantNames";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import useDateRange from "../../../../../hooks/useDateRange";
import {roundAccurately} from "../../../../../utils/roundNumbers";
import DataNotFound from "../../../../common/DataNotFound";
import ChartLoading from "../common/ChartLoading";
import ErrorFetchingDataMessage from "../common/ErrorFetchingDataMessage";
import {getY4Range} from "../../../../../utils/chartAxeRangeUtil";
import {useTranslation} from "react-i18next";
import {reorderElementInArray} from "../../../../../utils/arrayUtil";

const baseSensorData ={
    type: 'scatter'
}

const legendColors = ['#D01A55','#BF1AD0','#741AD0', '#1A23D0',
    '#1AD0AA','#40D01A','#D0B11A','#000','#7B7B7B'];

const initialPlotLayout = {

    legend:{x: -0.25, y: 0.9},
    yaxis3: {
        rangemode:"nonnegative",
        title: 'µg/m³',
        domain: [0.33, 1],
    },
    yaxis2: {
        domain: [0.17, 0.3],
        title: "ºC",
    },
    yaxis: {
        title: "RH %",
        range:[0,100],
        domain: [0, 0.13],
    },
    yaxis4: {
        rangemode:"nonnegative",
        overlaying: 'y3',
        side:"right",
        title: 'mg/m³',
        domain: [0.33, 1],
    },
    xaxis:{showgrid:false},
};

const SensorDataCardView = ({className}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const initialState = {data:[],
        visibleVariables:[0],
        error:"",
        period:DEFAULT_PERIOD,
        loading:true,
        plotLayout:initialPlotLayout,
        y3Side : "left",
        y3Visible : true,
        y4Side : "right",
        y4Visible : true,
        y4Range:[]
    }
    const[{data,period,loading,plotLayout,error,visibleVariables,
        y3Side,y3Visible,y4Side,y4Visible,y4Range},updateState] = useState(initialState)
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const { enqueueSnackbar } = useSnackbar();
    const isMounted = useMountComponent();
    const { selectedStation } = useSelector( state => state.dashboardUI );
    const { units } = useSelector( state => state.auth );

    const [{openDateRangePicker,dateRange},updateOpenDatePickerCallback,
        updateDatePickedCallback,clearDataRange] = useDateRange();


    useEffect(()=>{
        updateState(state =>({...state,plotLayout: {...initialPlotLayout,
                yaxis3: units.pollutants === "eu" ? {...initialPlotLayout.yaxis3} : {
                    title: 'ppb - µg/m³',
                    domain: [0.33, 1],
                },
                yaxis4: units.pollutants === "eu" ? {...initialPlotLayout.yaxis4} : {
                    side:"right",
                    overlaying: 'y3',
                    title: 'ppm',
                    domain: [0.33, 1],
                },
                yaxis2: units.temperature === "celsius" ? {...initialPlotLayout.yaxis2} :{
                    domain: [0.17, 0.3],
                    title: "ºF",
                }
            }}))
    },[units.pollutants, units.temperature])

    useEffect(
        ()=>{
            if(dateRange != null){
                updateData(CUSTOM_RANGE);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[dateRange]);

    useEffect(()=>{
        updateData(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedStation])

    const useStyles = makeStyles(({
        loading:{
                left: "50%",
                position: "relative",
                top: "50%",
                zIndex:999
            },
            rightControls:{
                display:"flex",
                flexDirection:"column",
                alignItems:"flex-start"
            },
            refreshButton:{
               marginLeft:"auto",
                color:"gray"
            }
    }));

    const classes = useStyles();


    const getSelectedPollutants = useCallback((visibleVariables, newData = null)=>{
        let output = []
        visibleVariables.forEach(position => {
            output.push(newData !== null ? newData[position]:data[position])
        })
        return output
    },[data])


    const updateData = useCallback((selectedPeriod)=>{

        let currentPeriod = selectedPeriod || period

        updateState(state =>{
            return {...state,loading: true,data: [],error: "", period: currentPeriod,y4Range:[]}
        });

        let selectedRange = getDateRange(currentPeriod,dateRange);
        sensorDataRequest(units.temperature,units.pollutants,selectedStation,
            selectedRange[0],
            selectedRange[1], (data,err)=>{
                if (!isMounted.current) {return}
                if(!err){
                    let dataArray = Object.entries(data)
                    let lastPosition = dataArray.length-1
                    let tempPosition = dataArray.findIndex(element => {
                        return element[0] === pollutants.temperature})

                    let auxArray1 = reorderElementInArray(dataArray,tempPosition,lastPosition)
                    let rhPosition = auxArray1.findIndex(element => {
                        return element[0] === pollutants.rh})
                    let auxArray2 = reorderElementInArray(auxArray1,rhPosition,lastPosition)

                    let newData = auxArray2.map((value,index) =>{
                        let units = unitsMap.get(value[1].units)
                        let y = value[1].y.map(item =>{
                            return roundAccurately(item,2);
                        });
                        let adaptedData = {...value[1],y};
                        return {
                            visible:  visibleVariables.includes(index)  || value[1].units === "celsius" || value[1].units === "fahrenheit" ||
                                    value[1].units === "percentage" ?
                                    true :
                                    "legendonly"  ,
                            name:` ${pollutantNames.get(value[0])} - ${units}` ,
                            ...adaptedData,
                            ...baseSensorData,
                            hoverlabel:{namelength:0},
                            hovertemplate: `<b>${pollutantNames.get(value[0])}</b>: %{y} ${units}`,
                            marker: {color:legendColors[index]},
                            yaxis: value[1].units === "celsius" || value[1].units === "fahrenheit" ? 'y2' :
                                value[1].units === "percentage" ? 'y1' : value[1].units === "ppb" ||  value[1].units === "ug-m3" ? 'y3' :
                                    value[1].units === "mg-m3" || value[1].units === "ppm" ? 'y4': "error"
                        };
                    });
                   if (newData.length > 0 ){
                       updateState(state =>{
                           return {...state,data:newData,
                               y4Range: getY4Range(newData),
                               loading: false,
                               ...getAxeVisibilityConfig(getSelectedPollutants(visibleVariables,newData)) }
                       });
                   }else {
                       updateState(state =>{
                           return {...state,data:[],loading: false, }
                       });
                   }
                }
                else {
                    if(data.status === 404){
                        updateState(state =>{
                            return {...state,loading: false}
                        });
                        enqueueSnackbar(t("analyticScreen.sensorData.sensor_data_not_found"),{ variant:"info" });
                    }
                    else {
                        updateState(state =>{
                            return {...state,loading: false,error:data.status}
                        });
                        if(data.status === 401){
                            expiredSession(ANALYTICS_ROUTE)(dispatch)
                        }
                        else {
                            enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.sensorData.could_not_update_station_data")}`,{ variant:"error" });
                        }
                    }
                }
            } );
    },[t,getSelectedPollutants,visibleVariables,dispatch,
        units.temperature,units.pollutants,dateRange,
        isMounted,period,selectedStation,enqueueSnackbar]);


    const handleSelectorChange = useCallback((event)=>{
        if( Number(event.target.value) !== CUSTOM_RANGE){
            clearDataRange();
            updateData(event.target.value)
        }
    },[clearDataRange,updateData]);

    const onCustomPressedCallback = useCallback(()=>{
            updateOpenDatePickerCallback(true);
        }
        ,[updateOpenDatePickerCallback]);

    const plotConfig = {
        modeBarButtonsToRemove:[ "select2d", "lasso2d",
            "toggleHover", "sendDataToCloud", "toggleSpikelines",
        ],
        displaylogo : false
    };


    const handleRefresh = ()=>{
        updateData(null);
    }

    const getAxeVisibilityConfig = (visiblePollutants,) => {
        const units = [...new Set(visiblePollutants.map(item => {return item.units}))]
        let y3Visible =  units.includes("ug-m3") || units.includes("ppb")
        let y4Visible =  units.includes("mg-m3") || units.includes("ppm")
        let y3Side = "left"
        let y4Side = y3Visible ? "right" : "left"
        return {y3Visible,y4Visible,y3Side,y4Side }
    }

    const onLegendClick = (event)=>{
        let rhPosition = data.length-1
        let tempPosition = data.length-2
        if(rhPosition === event.curveNumber || tempPosition === event.curveNumber){return false}
        let position = event.curveNumber
        let newVisibleList = !visibleVariables.includes(position) ? [...visibleVariables,position] :
            visibleVariables.filter(item => item !== position)
        let visiblePollutants = getSelectedPollutants(newVisibleList,null)


        updateState(state => ({...state,visibleVariables: newVisibleList,...getAxeVisibilityConfig(visiblePollutants)}))
    }

    const onLegendDoubleClick = (event)=>{
        return false
    }

    return (
        <Card className={className}>
            <DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                onDateRangePicked={updateDatePickedCallback} />
            <Grid container>
                <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                    <CardTittle tittle={t("analyticScreen.sensorData.station_data")} setAnchorEl={setAnchorEl}/>
                    <IconButton className={classes.refreshButton} aria-label="refresh"  disabled={loading}
                    onClick={handleRefresh}>
                        <RefreshIcon fontSize={"large"}/>
                    </IconButton>
                </Grid>
                { data.length>0 && <Grid  container item xs={10} className={"sensorData"} >
                        <Plot
                            useResizeHandler = {true}
                            layout={{...plotLayout,
                                yaxis3:{...plotLayout.yaxis3,visible:y3Visible,side:y3Side},
                                yaxis4:y4Range.length === 0 ? {...plotLayout.yaxis4,visible:y4Visible,side:y4Side}:
                                    {...plotLayout.yaxis4,visible:y4Visible,side:y4Side,range:y4Range}
                            }}
                            data={data}
                            onInitialized={(figure) => this.setState(figure)}
                            onUpdate={
                                (figure) =>
                                    this.setState(figure)

                            }
                            onLegendDoubleClick = {onLegendDoubleClick}
                            onLegendClick = {onLegendClick}
                            config = {plotConfig}>
                         </Plot>

                </Grid>}
                {(data.length === 0 && !loading) &&  <Grid container item xs={10} className={"notFoundSensorData"} >
                <DataNotFound />
                </Grid>}
                {error !== "" &&  <Grid container item xs={10} className={"notFoundSensorData"} >
                    <ErrorFetchingDataMessage/>
                </Grid>}
                {loading &&  <Grid container item xs={10} className={"notFoundSensorData"} >
                    <ChartLoading/>
                </Grid>}
                <Grid item className={classes.rightControls} xs={2}>
                   <PeriodSelector loading={loading}
                                   period={period}
                                   handleSelectorChange={handleSelectorChange}
                                   dateRange={dateRange}
                                   onCustomPressedCallback={onCustomPressedCallback}
                   />
                </Grid>
            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("analyticScreen.sensorData.en_analytics_stationData")}/>
        </Card>
    );
};

export default SensorDataCardView;
