import React from 'react';
import {Popover, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const HelpPopup = ({anchorEl,handleHelpClose,message, halfCard = false}) => {

    const useStyles = makeStyles((theme) => {
        return {
            help:{
                background:"#424242",
                color:"#ffffff",
                padding:20
            }
        }
    });

    const classes = useStyles();

    return (
            <Popover
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={handleHelpClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: !halfCard ? 'right' : "center",
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                PaperProps={{
                    style: { width: '25%' },
                }}
            >
                <Typography variant={"subtitle1"} className={classes.help}>{message}</Typography>
            </Popover>
    );
};

export default HelpPopup;
