import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const sensorDataRequest = (temp,pollutantUnit,stationId,init,end,callback) => {
    let params = end ? `?temperature=${temp}&pollutants=${pollutantUnit}&time=${init}&endtime=${end}` : `?temperature=${temp}&pollutants=${pollutantUnit}&time=${init}`
    let url = `/station-data/${stationId}/sensor-data/${params}`

    webClient.get(url)
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};