import React from 'react';
import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const CardLoading = () => {

    const useStyles = makeStyles(({
        loadingContainer:{
            display:"flex",
            height:"100%",
            alignSelf:"center",
            alignItems:"center",
            justifyContent:"center",
        }
    }));
    const classes = useStyles();
    return (
        <div className={classes.loadingContainer}><CircularProgress /></div>
    );
};

export default CardLoading;
