import React from 'react';
import { Grid, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const AppPasswordParamsConfig = ({name,nameCallback}) => {

    const { t } = useTranslation();

    return (
        <>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={1}/>
            <Grid item xs={10}>
                <TextField
                    inputProps={{ "data-testid": "password-name" }}
                    onChange={nameCallback}
                    value={name}
                    label={t("appPassword.password_name")} fullWidth/>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
        </>
    );
};

export default AppPasswordParamsConfig;
