import React from 'react';
import {createStyles, makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

const DownloadProgress = ({ progress }) => {

    const {t} = useTranslation()

    const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                border: `1px solid`,
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                height: 26,
                color: "#d0d0d0",
                borderRadius: 4,
            },
            value: {
                color:"#757575",
                position: 'absolute',
                lineHeight: '24px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            },
            bar: {
                height: '100%',
                '&.low': {
                    backgroundColor: '#d0d0d0',
                },
                '&.medium': {
                    backgroundColor: '#d0d0d0',
                },
                '&.high': {
                    backgroundColor: '#d0d0d0',
                },
            },
        }),
    );

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.value}>{`${t("processing")} ${Math.round(progress)} %`}</div>
            <div
                className={clsx(classes.bar, {
                    low: progress < 30,
                    medium: progress >= 30 && progress <= 70,
                    high: progress > 70,
                })}
                style={{ maxWidth: `${progress}%` }}
            />
        </div>
    );
};

export default DownloadProgress;
