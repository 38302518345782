import {Button, Card, CardContent, Grid, Link, Typography} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import {DataGrid} from "@material-ui/data-grid";
import {makeStyles} from "@material-ui/styles";
import AddIcon from '@material-ui/icons/Add';
import clsx from "clsx";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {useDispatch, useSelector} from "react-redux";
import {useMountComponent} from "../../../../hooks/useMountComponent";
import {useSnackbar} from "notistack";
import LoadingFullScreen from "../../../common/LoadingFullScreen";
import ServerErrorScreen from "../../../common/ServerErrorScreen";
import DownloadProgress from "../download/DownloadProgress";
import ReportConfigPopUp from "./report_config_menu/ReportConfigPopUp";
import {expiredSession} from "../../../../reducers/authReducer";
import { REPORT_ROUTE} from "../../../../constants";
import {downloadReportListRequest} from "../../../../requests/report/downloadReportListRequest";
import {createReportRequest} from "../../../../requests/report/createReportRequest";
import {setReportListAction} from "../../../../reducers/reportReducer";
import {deleteReportRequest} from "../../../../requests/report/deleteReportRequest";
import {reportBase} from "../../../../config/axios";
import CommonDialog from "../../../CommonDialog";
import {useTranslation} from "react-i18next";


const LIST_PAGE_SIZE = 7

const ReportScreen = () => {

    const {t} = useTranslation()
    const { reportList,reportProcessingProgressList } = useSelector( state => state.report );
    const isMounted = useMountComponent();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [{enableDelete,selectionModel,serverError,loading,openConfirmDialog,open,page,repeatedReportId},updateState] =
        useState({selectionModel:[],
            repeatedReportId:"",
            page:0,
            openConfirmDialog:false,
            serverError:false,
            enableDelete:false,
            data:[],
            loading:false,
            selectedDate:new Date(),
            open:false});

    const useStyles = makeStyles(({
        root: {
            maxWidth:1800,
            paddingBottom:20,
            paddingTop:20,
            paddingLeft:40,
            paddingRight:40,
        },
        error:{
           color:"#d22929",
            fontWeight:"bold"
        },
        card: {
            background:"white",
            padding:20,
            borderRadius: 5,
            minHeight:245
        },
        firstRowCard: {
            minHeight:600
        },
        cardTittle:{
            marginLeft:20,
            color:"#000000",
            fontWeight:"bold"
        },
        deleteButton:{
            color:"#ffffff",
            background:"#f6343d",
            '&:hover': {
                background:"#81191c"
            },
        },
        rowTable:{
            display:"flex",
            width:"100%vw",
            justifyContent:"center",
            marginTop:32
        },
        table:{
            maxWidth:1600,
            height: 480,
            '& .super-app.current_user': {
                fontWeight: '500',
                color: '#bcbcbc',
                backgroundColor: 'rgba(105,105,105,0.05)',
            },
            '& .super-app.other_user': {
            },
        },
        not_deletable: {
                fontWeight: '500',
                color: '#bcbcbc',
                backgroundColor: 'rgba(105,105,105,0.05)',
            },
        deletable: {
        },

    }));

    const hideDialog = useCallback(()=>{
        updateState(state => ({...state,openConfirmDialog: false}))
    },[])

    const handleDeleteButtonPressed = ()=>{
        updateState(state =>({...state,openConfirmDialog: true}))
    }

    const handleReportPreview  = (reportId)=>{
        const newWindow = window.open(`${reportBase}/report/${reportId}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const updateLoadingState = (isLoading) =>{
        updateState(state => ({...state,loading: isLoading}))
    }

    const columns = [
        { field: 'name', headerName: t("reportScreen.name") , width: 380, cellClassName: (params) => (
            params.row.autoReport === true ? classes.not_deletable :
                params.row.status.state === "completed" || params.row.status.state === "error" ?
                    classes.deletable : classes.not_deletable)
        },
        { field: 'status', headerName: t("reportScreen.status"),width: 280,
                renderCell: (params) => {
                    switch (params.value.state){
                        case "queue":
                            return <Typography>{ t("reportScreen.queued")}</Typography>
                        case "progress":
                            if(reportProcessingProgressList.find(item => item.id === params.row.id) === undefined){
                                return <Typography>{ t("reportScreen.processing")}</Typography>
                            }else {
                                return <DownloadProgress progress={reportProcessingProgressList.find(item => item.id === params.row.id).progress}/>
                            }
                        case "completed":
                            return <Typography>{ t("reportScreen.completed")}</Typography>
                        case "error":
                            return <Typography className={classes.error}>{ t("error")}</Typography>
                        default:
                            return
                    }
                },
            cellClassName: (params) => (
                params.row.autoReport === true ? classes.not_deletable :
                    params.row.status.state === "completed" || params.row.status.state === "error" ?
                        classes.deletable : classes.not_deletable)
            },
            { field: 'id', headerName: t("reportScreen.link"),  hide:false,width: 220,
                renderCell:(params)=>{
                    return params.row.status.state === "completed" ?
                         <Link component="button" underline="always" onClick={ event =>{
                             handleReportPreview(params.value)
                        }}>{t("reportScreen.preview")}</Link>:<div/>
                },
                cellClassName: (params) => (
                    params.row.autoReport === true ? classes.not_deletable :
                        params.row.status.state === "completed" || params.row.status.state === "error" ?
                            classes.deletable : classes.not_deletable)
            },
    ];

    const classes = useStyles();

    const onSelectionModelChange = (params)=>{
        updateState(state =>{
            return {...state,enableDelete:(params.selectionModel.length !== 0),
                selectionModel:params.selectionModel.filter(reportId =>
                {
                    let report = reportList.find(report => report.id === reportId)
                    return  report.autoReport === false && (report.status.state === "completed" || report.status.state === "error")
                })
        }})
    }

    const updateTable = useCallback(()=>{
        updateState(state => ({...state,loading: true, enableDelete: false}))
        downloadReportListRequest((data, err)=>{
            if (!isMounted.current) {return}
            updateLoadingState(false)
            if(!err && data){
                if(data.length>0){
                    dispatch(setReportListAction(data));
                }
            }
            if(err){
                if(data.status === 401){
                    expiredSession(REPORT_ROUTE)(dispatch)
                }
                else if (data.status === 500){
                    updateState(state => ({...state,loading: false,serverError: true}))
                }
                else {
                    updateLoadingState(false)
                    enqueueSnackbar(`${t("error")} ${data.status},
                        ${t("reportScreen.error_fetching")}`,{ variant:"error" });
                }
            }
        })
    },[t,isMounted,dispatch,enqueueSnackbar])

    const finishReportSetup = useCallback((body)=>{
        updateState(state =>({...state,open: false,loading: true}))
        createReportRequest(body,(data,err)=>{
            if (!isMounted.current) {return}
            if(!err){
                updateLoadingState(false)
                dispatch(setReportListAction(data));
            }else {
                if(data.status === 401){
                    expiredSession(REPORT_ROUTE)(dispatch)
                }
                else if (data.status === 403){
                    updateState(state => ({...state,loading: false,repeatedReportId: data.error}))
                }
                else if (data.status === 500){
                    updateState(state => ({...state,loading: false,serverError: true}))
                }
                else {
                    updateLoadingState(false)
                    enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("reportScreen.could_not_create_report")}`,{ variant:"error" });
                }
            }
        })

    },[t,dispatch,enqueueSnackbar,isMounted,updateState])

    const cancelReportSetup = useCallback(()=>{
       updateState(state =>({...state,open: false}))
    },[updateState])

    const handleConfigureNewReport = ()=>{
        updateState((state =>({...state,open: true})))
    }

    const confirmDelete = useCallback(()=>{
        hideDialog()
        updateLoadingState(true)
        Promise.all(deleteReportRequest(selectionModel)).then(() => {
            if (!isMounted.current) {return}
            updateTable();
        }).catch(err => {
            if (!isMounted.current) {return}
            updateLoadingState(false)
            if(err.response){
                switch (err.response.status){
                    case 401:
                        expiredSession(REPORT_ROUTE)(dispatch)
                        break;
                    case 404:
                        updateTable();
                        enqueueSnackbar(`${t("error")} ${err.response.status},
                         ${t("reportScreen.error_deleting")}`,{ variant:"error" });
                        break;
                    case 403:
                        updateTable();
                        enqueueSnackbar(`${t("error")} ${err.response.status},
                          ${t("reportScreen.error_deleting")}`,{ variant:"error" });
                        break;
                    case 500:
                        updateState(state => ({...state,loading: false,serverError: true}))
                        break;
                    default:
                        updateLoadingState(false)
                        enqueueSnackbar(`${t("error")} ${err.response.status},
                         ${t("reportScreen.error_deleting")}`,{ variant:"error" });
                        break;
                }
            }else {
                enqueueSnackbar(t("reportScreen.error_deleting_unknown"),{ variant:"error" });
            }
        });
    },[hideDialog,t,dispatch,updateTable,selectionModel,enqueueSnackbar,isMounted])

    const handlePageUpdate = (event)=>{
        updateState(state =>({...state,page: event.page}))
    }

    const handleClose = ()=>{
        updateState(state =>({...state,repeatedReportId: ""}))
    }

    return (
        <>
        <Grid className={classes.root} container direction={"row"}
              alignItems={"center"} justifyContent="space-around" spacing={2} >
            <Grid item  xs={12} >
                <Card  className={clsx(classes.card,classes.firstRowCard)}>
                    {
                    <CardContent>
                        <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                            <Grid container  item xs={12} sm={12} md={4} lg={6} alignItems={"flex-start"} alignContent={"flex-start"}>
                                <Typography className={classes.cardTittle} variant={"h5"}>{t("reportScreen.reports")}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3} >
                                <Button
                                    data-testid={"report-screen-add"}
                                    fullWidth
                                    disabled={loading}
                                    color={"primary"}
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    onClick={handleConfigureNewReport}
                                >
                                    {t("reportScreen.configure_report")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <Button
                                    data-testid={"report-screen-delete"}
                                    fullWidth
                                    disabled={loading || !enableDelete }
                                    variant="contained"
                                    className={classes.deleteButton}
                                    startIcon={<DeleteIcon />}
                                    onClick={handleDeleteButtonPressed}
                                >
                                    {t("delete")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={classes.rowTable} >
                                <DataGrid
                                    page={page}
                                    onPageChange={handlePageUpdate}
                                    className={classes.table}
                                    rows={reportList}
                                    columns={columns.map((column) => {
                                            return {
                                                ...column,disableClickEventBubbling: column.field === "id"
                                            }
                                        }
                                    )}
                                    columnBuffer = {8}
                                    pageSize={LIST_PAGE_SIZE}
                                    Name="dataGrid1"
                                    selectionModel={selectionModel}
                                    onSelectionModelChange={onSelectionModelChange}
                                    checkboxSelection  density={"standard"}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    }
                </Card>
            </Grid>
        </Grid>
            {open && < ReportConfigPopUp open={open}
                             finishReportSetup={finishReportSetup}
                             cancelReportSetup={cancelReportSetup}/>}
            {openConfirmDialog && <ConfirmDialog hideDialog={hideDialog} tittle={t("reportScreen.delete_report")}
                           question={t("reportScreen.are_you_sure_to_delete")}
                           confirm={confirmDelete}/>
            }
            {loading && <LoadingFullScreen loading={loading}/>}
            {serverError && <ServerErrorScreen />}
            {repeatedReportId !== "" && <CommonDialog title={t("warning")} description={t("reportScreen.there_is_already")+
            reportList.find(item => item.id === repeatedReportId).name +t("reportScreen.with_this_config")}
                                                      handleClose={handleClose}/>}
        </>
    );
}

export default ReportScreen;
