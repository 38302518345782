import React, {useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Grid, IconButton, Typography} from "@material-ui/core";
import RemoveIcon from '@material-ui/icons/Remove';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AQILegend from "./AQILegend";
import CAQILegend from "./CAQILegend";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const MapLegend = ({indexType}) => {

    const { t } = useTranslation();
    const { downloadProcessList, minimized } = useSelector( state => state.download );

    const useStyles = makeStyles({
        infoButton: {
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            background:"white",
            borderRadius:"50%",
            width:18,
            height:18,
            padding:16,
            position:"absolute",
            bottom:downloadProcessList.length === 0 ? 32 : minimized ? 92 : downloadProcessList.length === 1 ?
                (110+32+10) : downloadProcessList.length === 2 ? (164+32+10) : (216+32+10),
            right:8,
            zIndex:1999,
            boxShadow: "0 3px 3px rgba(0,0,0,0.2)"
        },

        box: {
            background:"white",
            borderRadius:6,
            width:390,
            padding:16,
            position:"absolute",
            bottom:downloadProcessList.length === 0 ? 32 : minimized ? 92 : downloadProcessList.length === 1 ?
                (110+32+10) : downloadProcessList.length === 2 ? (164+32+10) : (216+32+10),
            right:8,
            zIndex:1999,
            boxShadow: "0 3px 3px rgba(0,0,0,0.2)"
        },
        mainTittle:{
            color:"#696969",
            fontWeight:"bold",
            marginBottom:8
        }
    });

    const [{showLegend},updateState] = useState({showLegend:true})

    const handleMinimize = ()=>{
        updateState({showLegend:false});
    }

    const handleMaximize = ()=>{
        updateState({showLegend:true});
    }

    const classes = useStyles();

    return (<>
                {showLegend &&
                <Grid  container className={classes.box} >
                    <Grid item container xs={12} justifyContent={"space-between"} alignItems={"center"} >
                        <Grid item>
                            <Typography variant={"h6"}
                                        className={classes.mainTittle}>{ indexType === "aqi" ? t("legend.aqi") :
                                indexType === "caqi" ? t("legend.caqi"):"ERROR" }</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="minimize" onClick={handleMinimize}>
                                <RemoveIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {indexType === "aqi" ? <AQILegend/> : <CAQILegend/>}
                </Grid>
                }
                {!showLegend &&
                <div className={classes.infoButton}>
                    <IconButton onClick={handleMaximize}>
                        <InfoOutlinedIcon  />
                    </IconButton>
                </div>
                }
        </>)
}

export default MapLegend;
