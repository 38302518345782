import React from 'react';
import CircularProgressWithPercentage from "./CircularProgressWithPercentage";
import {
    IconButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {removeDownloadProcessAction} from "../../../../../reducers/downloadReducer";
import {makeStyles} from "@material-ui/styles";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const DownloadWidget = ({id,name,progress,source}) => {

    const dispatch = useDispatch();

    const handleCancel = ()=>{
        source.cancel()
        dispatch(removeDownloadProcessAction(id))
    }

    const useStyles = makeStyles((theme) => ({
        widgetContainer: {
            margin:0,
            display:"flex",
            paddingLeft:8,
            paddingRight:8,
            justifyContent:"center",
            alignItems:"center",
            width:320,
            height:45,
            background:"#ffffff",
        },
        center:{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
        },
        left:{
            textAlign:"left",
        }
    }));

    const classes = useStyles();

    return (
        <>
        <ListItemIcon>
            <IconButton aria-label="minimize" onClick={handleCancel} className={classes.minimize}>
                <DeleteOutlineIcon fontSize="small"  />
            </IconButton>
        </ListItemIcon>
            <ListItemText id={`text${id}`} primary={name} />
            <ListItemSecondaryAction>
                <CircularProgressWithPercentage value={progress} />
            </ListItemSecondaryAction>
        </>
    );
};



export default DownloadWidget;
