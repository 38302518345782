import {getVariableName} from "../components/routes/private/pollutantNames";

const alarmSensors = ["PM1","PM10","PM4","PM2P5","O3","NO","NO2","SO2","H2S","CO","CO2","relativeHumidity","temperature"]

export const getAvailableSensors = (stationList) =>{
    let output = []
    stationList.forEach(station => {
        station.sensors.forEach(sensorId =>{
            let sensorEntry = output.find(item => item.id=== sensorId)
            if(sensorEntry === undefined && alarmSensors.includes(sensorId)){
                output.push({id:sensorId,alias:getVariableName(sensorId)})
            }
        })
    })

    return output;
}