import {webClient} from "../../../config/axios";
import {handleError} from "../../handleRequestError";

export const createAlarmRequest = (body,callback) => {
    webClient.post('/alarms', body, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};