import React from 'react';
import { IconButton, Typography} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {makeStyles} from "@material-ui/styles";

const CardTittle = ({tittle,setAnchorEl }) => {

    const useStyles = makeStyles((theme) => {
        return {
            cardTittle:{
                marginLeft:20,
                color:"gray"
            },
            helpButton:{
                color:"black"
            }
        }
    });
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.cardTittle} variant={"h6"}>{tittle}</Typography>
            <IconButton className={classes.helpButton} aria-label="help"
                        onClick={e => setAnchorEl(e.currentTarget)}>
                <HelpOutlineIcon />
            </IconButton>
        </>
    );
};

export default CardTittle;
