import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Grid, IconButton, List, ListItem, Paper, Typography} from "@material-ui/core";
import DownloadWidget from "./DownloadWidget";
import {makeStyles} from "@material-ui/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import MinimizedProgress from "./MinimizedProgress";
import {setDownloadProgressMinimizedStateAction} from "../../../../../reducers/downloadReducer";


const DownloadWidgetGroup = () => {

    const {downloadProcessList,minimized } = useSelector( state => state.download );
    const dispatch = useDispatch()

    const useStyles = makeStyles((theme) => ({
        listContainer: {
            zIndex:2999,
            backgroundColor: "#ffffff",
            position:"absolute",
            bottom:32,
            right:10,
            left:0,
            marginLeft:"auto",
        },
        listItem:{
          background:"#fff"
        },
        downloadTittle:{
            color:"#696969",
            fontWeight:"bold",
            paddingLeft:16,
            paddingTop:16
        },
        list:{
            display: 'flex',
            flexDirection: 'column',
            marginLeft:8,
            marginRight:8,
            maxHeight:30
        },
        minimize:{
            paddingTop:16,
            marginTop:5,
        }
    }));

    const classes = useStyles();


    const handleMinimize = ()=>{
        dispatch(setDownloadProgressMinimizedStateAction(true))
    }


    return (
        <>
            {downloadProcessList.length > 0 && minimized ===false &&
            <Paper elevation={3} className={classes.listContainer} style={{width:350,height: downloadProcessList?.length === 1 ? 110 :
                    downloadProcessList?.length === 2 ? 164 : downloadProcessList?.length === 3 ? 216 : 216, overflow : "auto",
            }}>

                <Grid container>
                    <Grid item xs={10}>
                        <Typography className={classes.downloadTittle} variant={"h6"}>Current Downloads</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={handleMinimize}  className={classes.minimize}>
                            <RemoveIcon fontSize="small"  />
                        </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                        <List disablePadding dense className={classes.list} >
                            {downloadProcessList.map(item => {
                           return  <ListItem key={item.id} dense className={classes.listItem}>
                                <DownloadWidget progress={item.progress}
                                id={item.id}
                                name={item.name}
                                source={item.source}
                                />
                            </ListItem>
                        })}
                        </List>
                    </Grid>
                </Grid>
            </Paper>
            }
            {
                downloadProcessList.length > 0 && minimized === true &&
                <MinimizedProgress/>
            }
        </>
    );
}

export default DownloadWidgetGroup;
