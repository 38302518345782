import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {updateAliasRequest} from "../../../../../requests/analytics/stationInfoRequest";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

const UpdateAliasPopUp = ({open,handleClose,currentAlias,stationId}) => {

    const { t } = useTranslation();
    const isMounted = useMountComponent();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(
        ()=>{
            updateState({alias:currentAlias})
        },[currentAlias]);

    let [{alias},updateState] = useState({alias:""})

    const handleTextFieldChange = (event)=>{
        updateState({alias: event.target.value})
    }

    const handleUpdate = ()=>{

        updateAliasRequest({stationId,alias},(data,err)=>{
            if(isMounted.current){
               if(!err){
                   enqueueSnackbar(t("analyticScreen.deviceDescription.alias_success_updated"),{ variant:"success" });
                   handleClose(true,[stationId,alias]);
               }else {
                   enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.deviceDescription.updating_alias")}`,{ variant:"error" });
                   handleClose(false,null);
               }
            }
        })
    }

    return (
        <Dialog fullWidth open={open} onClose={event => handleClose(false,null)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t("analyticScreen.deviceDescription.change_station_alias")}</DialogTitle>
            <DialogContent>
                <TextField
                    inputProps={{ "data-testid": "update-alias-text-field" }}
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("analyticScreen.deviceDescription.device_alias")}
                    type="text"
                    value={alias}
                    onChange={handleTextFieldChange}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={event => handleClose(false,null)} color="primary">
                    {t("cancel")}
                </Button>
                <Button
                    data-testid={"update-alias-update-button"}
                    disabled={alias === ""} onClick={handleUpdate} color="primary">
                    {t("update")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateAliasPopUp;
