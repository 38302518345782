import React, {useCallback, useEffect, useState} from 'react';
import {Card, Grid} from "@material-ui/core";
import DaysOverThresholdTable from "./DaysOverThresholdTable";
import {daysOverWhoRequest} from "../../../../../requests/analytics/daysOverWhoRequest";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE} from "../../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import HelpPopup from "../common/HelpPopup";
import CardTittle from "../common/CardTittle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import CardLoading from "../common/CardLoading";
import {useTranslation} from "react-i18next";

const DaysOverWhoThresholdCardView = ({className}) => {

    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();

    const {t} = useTranslation()
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [{data,loading},updateState] = useState({data:[],loading:true})
    const { selectedStation } = useSelector( state => state.dashboardUI );

    const isMounted = useMountComponent();

    useEffect(() => {
        fetchDaysOverWhoData();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [selectedStation]);

    const fetchDaysOverWhoData = useCallback(()=>{
        updateState({data:[],loading: true})
        daysOverWhoRequest(selectedStation,(data,error)=>{
            if(!isMounted.current){return}
            if(!error){
                updateState(state =>{
                    return {...state,data,loading: false}
                })
            }
            else {
                updateState(state =>{
                    return {...state,loading: false}
                });
                if(data.status === 401){
                    expiredSession(ANALYTICS_ROUTE)(dispatch)
                }else {
                    enqueueSnackbar(`${t("error")}Error ${data.status},
                         ${t("analyticScreen.daysOverThreshold.could_not_update_who_table")}`,{ variant:"error" });
                }
            }
        });
    },[t,dispatch,enqueueSnackbar,isMounted,selectedStation]);

    return (
        <Card className={className}>
            { !loading &&
                <Grid container direction={"row"}>
                    <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                        <CardTittle tittle={t("analyticScreen.daysOverThreshold.tittle")} setAnchorEl={setAnchorEl}/>
                    </Grid>
                    <Grid item xs={12}>
                        {data.length > 0 && <DaysOverThresholdTable daysOverWho={data}/>}
                    </Grid>
                </Grid>
            }
            {loading && <CardLoading/>}
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose}
                       message={t("analyticScreen.daysOverThreshold.en_analytics_days_over_who")} halfCard={true}/>
        </Card>
    );
};

export default DaysOverWhoThresholdCardView;
