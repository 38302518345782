import React, {useCallback, useEffect, useState} from 'react';
import {
    Card, Checkbox,
    CircularProgress,
    FormControl, FormControlLabel, FormLabel,
    Grid,
    InputLabel,
     Radio, RadioGroup, Select,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Plot from "react-plotly.js";
import {pollutantTrendRequest} from "../../../../../requests/analytics/pollutantTrendRequest";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE} from "../../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import HelpPopup from "../common/HelpPopup";
import CardTittle from "../common/CardTittle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import {pollutantNames} from "../../pollutantNames";
import {roundAccurately} from "../../../../../utils/roundNumbers";
import {unitsMap} from "../../unitsNames";
import './chart_style.css';
import DataNotFound from "../../../../common/DataNotFound";
import {useTranslation} from "react-i18next";


const PollutantTrendCardView = ({className}) => {

    const {t} = useTranslation()

    const useStyles = makeStyles( (
         {
            loading:{
                left: "50%",
                position: "relative",
                top: "50%",
                zIndex:999
            },
            rightControls:{
                display:"flex",
                flexDirection:"column",
                alignItems:"flex-start"
            },
            radioGroup:{
                marginTop:30
            },
            formControl: {
                minWidth: 120,
            },
            checkBox: {
                marginTop:20
            }
    }));

    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const isMounted = useMountComponent();
    const { selectedStation } = useSelector( state => state.dashboardUI );
    const { units } = useSelector( state => state.auth );

    const getPlotWithoutThreshold = (unit) =>{
        return {
            autosize: true,
            showlegend: false,
            legend:{x: -0.22, y: 0.9},
            yaxis: {
                title: unitsMap.get(unit)
            },
            xaxis:{showgrid:false,domain: [0, 1],zeroline:false}
        }
    }

    const initialState = {rawData:[],data:[],type:"days",enableWho:false,
        pollutant:"",loading:true,
        plotLayout:getPlotWithoutThreshold(),
        pollutantList:["Pollutant"],
        whoThreshold:false
    }
    const[{rawData,data,type,pollutant,enableWho,loading,plotLayout
        ,whoThreshold,pollutantList},updateState] = useState(initialState)


    useEffect(
        ()=>{
            updatePollutantTrend();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[selectedStation])

    const getChartData = useCallback((fetchedData, selectedType, selectedPollutant) =>{

        let currentType = selectedType || type
        let currentData = fetchedData || rawData
        let currentPollutant = selectedPollutant  || pollutant

        let data = currentType === "days" ? currentData.days : currentData.hours

        const output = Object.entries(data).filter(value =>{
            return value[0] === currentPollutant
        }).map((value) =>{
            const mean =  getValue("Mean",value)
            const min =  getValue("Min",value)
            const max =  getValue("Max",value)
            return [min,mean,max]
        });
        return output[0];

    },[pollutant,rawData,type])


    const getValue = (type,value) =>{
        let units = unitsMap.get(value[1].units)

        const chartData = type === "Mean" ? value[1].mean : type === "Min" ? value[1].min : value[1].max
        let y = chartData.y.map(item =>{
            return roundAccurately(item,2) ;
        });
        let adaptedData = {...chartData,y};

        const colorValue = type === "Mean" ? "#1a23d0" : "#8d93f8"
        return {
            name:`${pollutantNames.get(value[0])} ${type}`,
            marker: {color: colorValue},
            ...adaptedData,
            mode: "lines",
            fill: type === "Min" ? "none" : "tonexty",
            hoverlabel:{namelength:0},
            fillcolor: "rgba(26, 35, 208, 0.3)",
            type: 'scatter',
            hovertemplate: `<b>${pollutantNames.get(value[0])} ${type}</b>: %{y} ${units}`,
        };
    }

    const getPollutantThreshold = useCallback((selectedType, selectedPollutant,rawData)=>{
        let currentType = selectedType || type;
        let currentPollutant = selectedPollutant || pollutant;

        let data = currentType === "days" ? rawData.days : rawData.hours
        let aux = Object.entries(data);
        let pollutantObject = aux.filter(item =>{
            return item[0] === currentPollutant
        })
        return pollutantObject[0][1].threshold
    },[pollutant,type])

    const getPollutantUnit = useCallback((selectedPollutant,data)=>{
        return Object.entries(data.days).find(item => item[0] === selectedPollutant)[1].units
    },[])

    const updatePollutantTrend = useCallback(()=>{
        updateState(state =>{
            return {...state,loading: true}
        })
        pollutantTrendRequest(selectedStation,units.pollutants,(data,error)=>{
            if( !isMounted.current){return}
            if(!error){
                updateState(state =>{

                   let selectedPollutant = Object.entries(data.days)[0][0]

                    let threshold = getPollutantThreshold(null,selectedPollutant,data)
                    return {...state,enableWho: threshold !== null ,
                        loading: false,
                        rawData:data,
                        pollutant: selectedPollutant,
                        plotLayout: getPlotWithoutThreshold(getPollutantUnit(selectedPollutant,data)),
                        data:getChartData(data,null,selectedPollutant),
                        pollutantList: getPollutantFromRawData(data)
                    }
                })
            }
            else {
                if(data.status === 404){
                    updateState(state =>{
                        return {...state,loading: false}
                    });
                    enqueueSnackbar(t("analyticScreen.pollutantTrend.data_not_available_for_period"),{ variant:"info" });
                }else {
                    updateState(state =>{
                        return {...state,loading: false}
                    });
                    if(data.status === 401){
                        expiredSession(ANALYTICS_ROUTE)(dispatch)
                    }else {
                        enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.pollutantTrend.could_not_update_trend")}`,{ variant:"error" });
                    }
                }
            }
        })
    },[t,getPollutantUnit,getPollutantThreshold,dispatch,units.pollutants,enqueueSnackbar,getChartData,isMounted,selectedStation]);

    const getPollutantFromRawData = (fetchedData)=>{
        return Object.entries(fetchedData.days).map(value=>{
            return value[0]
        })
    }

    const plotConfig = {
        modeBarButtonsToRemove:[ "select2d", "lasso2d","autoScale2d",
            "zoomIn2d", "zoomOut2d","resetScale2d","zoom2d",
            "toggleHover", "resetViews", "sendDataToCloud",
            "toggleSpikelines", "resetViewMapbox","pan2d","hoverClosestCartesian",
            "hoverCompareCartesian"
        ],
        displaylogo : false
    };

    const handleSelectorChange = (event) =>{
        let pollutant = event.target.value
        let pollutantThreshold = getPollutantThreshold(null,pollutant,rawData)

        updateState(state =>{
            return{...state,enableWho:pollutantThreshold!==null ,data:getChartData(null,null,pollutant),pollutant,
                plotLayout: whoThreshold ? getPlotWithThreshold(null,pollutant) : getPlotWithoutThreshold(getPollutantUnit(pollutant,rawData))}
        });
    }

    const handleRadioChange = (event) =>{
        let type = event.target.value
        updateState(state =>{
            return{...state,data:getChartData(null,type,null),type,
                plotLayout: whoThreshold ? getPlotWithThreshold(type,null) : getPlotWithoutThreshold(getPollutantUnit(pollutant,rawData)) }
        });
    }

    const handleCheckBox = (event)=>{
        let checked = event.target.checked
        updateState(state =>{
            return{...state,whoThreshold: checked,plotLayout: checked ? getPlotWithThreshold() : getPlotWithoutThreshold(getPollutantUnit(pollutant,rawData)) }
        });
    }

    const getPlotWithThreshold = (selectedType, selectedPollutant)=>{
        let currentType = selectedType || type;
        let currentPollutant = selectedPollutant || pollutant;

        let data = currentType === "days" ? rawData.days : rawData.hours
        let aux = Object.entries(data);
        let pollutantObject = aux.filter(item =>{
            return item[0] === currentPollutant
        })
        let threshold = pollutantObject[0][1].threshold

        let unit = getPollutantUnit(currentPollutant,rawData)
        if(threshold === null ) return getPlotWithoutThreshold(unit)

        return {
            ...getPlotWithoutThreshold(unit),shapes: [
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: threshold,
                    x1: 1,
                    y1: threshold,
                    line:{
                        color: 'rgb(255, 0, 0)',
                        width: 2.5,
                    }
                }
            ],
            annotations: [
                {
                    x: currentType === "days" ? 6.1 : 23.5,
                    y: threshold,
                    xref: 'x',
                    yref: pollutantObject[0][1].units === "ppb" ||  pollutantObject[0][1].units === "ug-m3" ? 'y1' :
                        pollutantObject[0][1].units === "mg-m3" || pollutantObject[0][1].units === "ppm" ? 'y2' :"error",
                    text: `${threshold}`,
                    showarrow: true,
                    arrowhead: 20,
                    ax: 0,
                    ay: 20
                }
            ]
        }
    }

    return (
        <Card className={className}>
            <Grid container direction={"row"}>
                <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                    <CardTittle tittle={t("analyticScreen.pollutantTrend.pollutant_trend")} setAnchorEl={setAnchorEl}/>
                </Grid>
                {data.length>0 && <Grid  container item xs={10} className={"trendChart"}>
                    { loading && <CircularProgress className={classes.loading} color={"inherit"}/>}
                    <Plot
                        useResizeHandler = {true}
                        layout={plotLayout}
                        data={data}
                        onInitialized={(figure) => this.setState(figure)}
                        onUpdate={
                            (figure) =>
                                this.setState(figure)
                        }
                        config = {plotConfig}>
                    </Plot>
                </Grid>}
                {(data.length === 0 && !loading) &&  <Grid container item xs={12} className={"notFoundTrend"} >
                    <DataNotFound message={t("analyticScreen.pollutantTrend.data_not_available_for_the_last_month")}/>
                </Grid>}
                    {data.length>0 && <Grid item className={classes.rightControls} xs={2}>
                    <FormControl className={classes.formControl} disabled={loading}>
                        <InputLabel >{t("analyticScreen.pollutantTrend.pollutant")}</InputLabel>
                        <Select
                            data-testid = "trend-pollutant-selector"
                            native
                            value={pollutant}
                            onChange={handleSelectorChange}
                        >
                            {pollutantList.map(value=>{
                                return <option key={value} value={value}>{pollutantNames.get(value)}</option>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.radioGroup} disabled={loading}>
                        <FormLabel component="legend">{t("analyticScreen.pollutantTrend.mean_by")}</FormLabel>
                        <RadioGroup  value={type} onChange={handleRadioChange}>
                            <FormControlLabel value="days" control={<Radio />} label={t("analyticScreen.pollutantTrend.week_day")} />
                            <FormControlLabel value="hours" control={<Radio />} label={t("analyticScreen.pollutantTrend.hour_in_day")} />
                        </RadioGroup>
                    </FormControl>
                    <FormControlLabel className={classes.checkBox}
                        control={
                            <Checkbox
                                data-testid={"threshold-checkbox"}
                                disabled={!enableWho}
                                onChange={handleCheckBox}
                                checked={whoThreshold}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label= {t("analyticScreen.pollutantTrend.who_threshold")}
                    />
                </Grid>}
            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("analyticScreen.pollutantTrend.en_analytics_pollutant_trend")}/>
        </Card>
    );
};

export default PollutantTrendCardView;
