import React, {useCallback, useEffect, useState} from 'react';
import { Button, Card, Grid, Typography} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import DeleteIcon from "@material-ui/icons/Delete";
import {DataGrid} from "@material-ui/data-grid";
import {
    deleteMemberRequest,
    organizationRequest,
    resendInvitationRequest
} from "../../../../../requests/organization/organizationRequests";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ORGANIZATION_ROUTE} from "../../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import clsx from "clsx";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import {makeStyles} from "@material-ui/styles";
import InviteDialog from "./InviteDialog";
import ConfirmDialog from "../../../../common/ConfirmDialog";
import CardViewLoading from "../../analytics/common/ChartLoading";
import DataNotFound from "../../../../common/DataNotFound";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

const initialState = {
    error:"",
    loading:true,
    deleting:false,
    resending:false,
    enableDelete:false,selectionModel:[],
    members: [],organization:'',
    showInviteDialog:false,
    openConfirmDialog:false}

const OrganizationMembers = ({className}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useMountComponent();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useSelector( state => state.auth );

    const [{error,loading,deleting,resending,enableDelete,selectionModel, members, organization,showInviteDialog,
        openConfirmDialog},updateState] = useState(initialState)

    const useStyles = makeStyles({
        currentUser: {
            '& .super-app.current_user': {
                fontWeight: '500',
                color: '#bcbcbc',
                backgroundColor: 'rgba(105,105,105,0.05)',
            },
            '& .super-app.other_user': {
            },
        },
        deleteButton:{
            color:"#ffffff",
            background:"#f6343d",
            '&:hover': {
                background:"#81191c"
            },
        },
        subtitle:{
            color:"#8a8a8a"
        },
        organizationName:{
            fontWeight:"bold"
        },
        rowTable:{
            display:"flex",
            width:"100%vw",
            justifyContent:"center",
            margin:0,
        },
        table:{
            maxWidth:1600,
            height: 480,
            '& .super-app.current_user': {
                fontWeight: '500',
                color: '#bcbcbc',
                backgroundColor: 'rgba(105,105,105,0.05)',
            },
            '& .super-app.other_user': {
            },
        }
    });
    const classes = useStyles();

    const updateData = useCallback(()=>{
        updateState(initialState)
        organizationRequest((data,error)=> {
            if (!isMounted.current) {return}
            if (!error) {
                updateState(state => {
                    return {...state, loading:false,organization: data.name,
                        members: data.users,selectionModel:[],enableDelete: false }
                })
            } else {
                switch (data.status){
                    case 401:
                        expiredSession(ORGANIZATION_ROUTE)(dispatch)
                        break;
                    default:
                        updateState(state => {
                            return {...state,loading:false,error: t("organizationMembers.could_not_fetch_members")}
                        })
                        enqueueSnackbar(`${t("error")} ${data.status},
                        ${t("organizationMembers.could_not_fetch_members")} `,{ variant:"error" });
                        break;
                }
            }
        });
    },[t,enqueueSnackbar,dispatch,isMounted]);

    const setInviteDialogState = useCallback((showDialog)=>{
        updateState(state =>{
            return{...state,showInviteDialog:showDialog}
        })
    },[updateState]);

    const updateServerError = ()=>{
        updateState(state =>({...state,serverError: true}))
    }

    const serverErrorCallback = useCallback(()=>{
        updateServerError();
    },[])

    const hideDialog = useCallback(()=>{
        updateState(state =>{
            return {...state,openConfirmDialog: false};
        })
    },[])

    const onSelectionModelChange = (params)=>{
        updateState(state =>{
            return {...state,enableDelete:(params.selectionModel.length !== 0),
                selectionModel:(params.selectionModel.filter((userId)=> userId !== id))}
        })
    }

    const handleInvitation = ()=>{
        setInviteDialogState(true);
    }


    const sucessfullySentCallback = useCallback(()=>{
        updateData();
    },[updateData]);


    useEffect(()=> {
        updateData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const confirmDelete = useCallback(()=>{
        updateState(state => ({...state,deleting: true,openConfirmDialog: false}))
        Promise.all(deleteMemberRequest(selectionModel)).then(() => {
            if (!isMounted.current) {return}
            updateData();
            enqueueSnackbar(t("organizationMembers.sucessfully_deleted"),{ variant:"success" });
        }).catch(err => {
            if (!isMounted.current) {return}
            if(err.response){
                updateState(state => ({...state,deleting: false}))
                switch (err.response.status){
                    case 401:
                        expiredSession(ORGANIZATION_ROUTE)(dispatch)
                        break;
                    default:
                        enqueueSnackbar(t("organizationMembers.error_deleting"),{ variant:"error" });
                        break;
                }
            }else {
                enqueueSnackbar(t("organizationMembers.error_deleting"),{ variant:"error" });
            }
        });
    },[isMounted,dispatch,t,enqueueSnackbar,selectionModel,updateData])

    const handleDelete = ()=>{
        updateState(state =>{
            return {...state,openConfirmDialog: true}
        })
    }

    const handleResend = (id)=>{
        updateState(state => ({...state,resending: true}))
        resendInvitationRequest({userId:id},(data,error)=>{
            if (!isMounted.current) {return}
            updateState(state => ({...state,resending: false}))
            if(!error){
                enqueueSnackbar(t("organizationMembers.invitation_sent"),{ variant:"success" });
            }else {
                switch (data.status){
                    case 401:
                        expiredSession(ORGANIZATION_ROUTE)(dispatch)
                        break;
                    case 403:
                        enqueueSnackbar(t("organizationMembers.error_user_not_exist"),{ variant:"error" });
                        break;
                    default:
                        enqueueSnackbar(t("organizationMembers.error_sending_invitation"),{ variant:"error" });
                        break;
                }
            }
        })
    }

    const columns = [
        { field: 'id', headerName: 'ID',  hide:true },
        { field: t("name"), headerName: t("organizationMembers.name") , width: 240,
            cellClassName: (params) =>
                clsx('super-app', {
                    current_user: params.row.id === id,
                    other_user: params.row.id !== id,
                }),
            renderCell: (params) => (
                <Typography>{ params.value ? params.value: "--"}</Typography>
            )
        },
        { field: 'lastName', headerName: t("organizationMembers.last_name"),width: 240,
            cellClassName: (params) =>
                clsx('super-app', {
                    current_user: params.row.id === id,
                    other_user: params.row.id !== id,
                }),
            renderCell: (params) => (
                <Typography>{ params.value ? params.value: "--"}</Typography>
            )
        },
        {
            field: 'mail',
            headerName: t("organizationMembers.email"),
            width: 340,
            cellClassName: (params) =>
                clsx('super-app', {
                    current_user: params.row.id === id,
                    other_user: params.row.id !== id,
                })
        },
        { field: 'registered', headerName: ' ', width: 250,
            sortable: false
            ,
            renderCell: (params) => {
                return params.value === true ?
                    <Typography/>
                    :
                    <Button
                        fullWidth
                        variant="text"
                        color="primary"
                        onClick={()=>{
                            handleResend( params.row.id)
                        }}
                        className={classes.button}
                        endIcon={<SendRoundedIcon/>}
                    >
                        { t("organizationMembers.resend_invitation")}
                    </Button>
            }
        },
    ];

    return (
        <>
        <Card className={className}  >
            {loading && <CardViewLoading/>}
            {!loading && error === "" && <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={4} lg={6} >
                    <Typography className={classes.organizationName} variant={"h4"}>{organization}</Typography>
                    <Typography className={classes.subtitle} variant={"h6"}>{t("organizationMembers.memberList")}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} >
                    <Button
                        data-testid={"organization-member-add"}
                        fullWidth
                        disabled={deleting || resending}
                        onClick={handleInvitation}
                        color={"primary"}
                        variant="contained"
                        startIcon={<SendIcon />}
                    >
                        { t("organizationMembers.invite_new_member")}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Button
                        data-testid={ "organization-member-delete"}
                        fullWidth
                        disabled={  !enableDelete || deleting || resending}
                        onClick={handleDelete}
                        variant="contained"
                        className={classes.deleteButton}
                        startIcon={<DeleteIcon />}
                    >
                        { t("delete")}
                    </Button>
                </Grid>

                <Grid item xs={12} className={classes.rowTable} >
                    <DataGrid
                        loading={deleting || resending}
                        className={classes.table}
                        rows={members}
                        columns={columns.map((column) => {
                                return {
                                    ...column,disableClickEventBubbling:column.field === "registered"
                                }
                            }
                        )}
                        columnBuffer = {8}
                        pageSize={7}
                        Name="dataGrid1"
                        selectionModel={selectionModel}
                        onSelectionModelChange={onSelectionModelChange}
                        checkboxSelection  density={"standard"}
                    />
                </Grid>
            </Grid>}
            {!loading && error !== "" && <DataNotFound message={error}/>}
        </Card>
            {showInviteDialog && <InviteDialog serverErrorCallback={serverErrorCallback}
                          sucessfullySent={sucessfullySentCallback} showInviteDialog = {showInviteDialog}
                          setInviteDialogState = {setInviteDialogState}/>}
            { openConfirmDialog && <ConfirmDialog hideDialog={hideDialog} tittle={t("organizationMembers.delete_member")}
                                                  question={t("organizationMembers.are_you_sure_to_delete")}
                                                  confirm={confirmDelete} />}
        </>
    );
};

export default OrganizationMembers;
