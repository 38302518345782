import React from 'react';
import { Route, Switch} from "react-router-dom";
import {PublicRoute} from "./public/PublicRoute";
import {LOGIN_ROUTE, REGISTER_ROUTE, RESET_ROUTE} from "../../constants";
import LoginScreen from "./public/login/LoginScreen";
import InvitationAndResetScreen from "./public/invitations/InvitationAndResetScreen";
import Dashboard from "./private/dashboard/Dashboard";

const TopLevelSwitch = ({signedIn}) => {

    return (
        <Switch>
            <PublicRoute isAuthenticated={signedIn} exact path= {LOGIN_ROUTE} component={LoginScreen}/>
            <Route exact path={REGISTER_ROUTE} component={InvitationAndResetScreen}/>
            <Route exact path={RESET_ROUTE} component={InvitationAndResetScreen}/>
            <Route  path="/"
                    render={(props) => (
                        <Dashboard {...props} isAuthenticated={signedIn} />
                    )}
            />
        </Switch>
    );
};

export default TopLevelSwitch;
