import {webClient} from "../config/axios";
import {handleError} from "./handleRequestError";


export const validateInvitationRequest = (token,callback) => {
    webClient.get(`/auth/validateMail/${token}`)
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};

export const joinOrganizationRequest = (body,callback) => {
    webClient.post('/auth/signup', body, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 0
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback);
        })
};




