import axios from 'axios';

const DEBUG = process.env.REACT_APP_ENVIRONMENT === 'development';

export const urlBase = DEBUG ? 'organization.bettair.es' : `${window.location.host}/api`;
export const reportBase = DEBUG ? 'http://localhost.bettair.es:3001' : `https://${window.location.host}`;

export const webClient = axios.create({
    baseURL: `https://${urlBase}`,
    timeout: 10000,
    withCredentials: true
});