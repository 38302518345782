import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const CompareStationSelectorButton = ({onButtonPressCallback}) => {

    const {t} = useTranslation()
    const useStyles = makeStyles((
        {
           button:{
               color:"white",
               marginRight:24
           }
        }
        ));
    const classes = useStyles();

    return (
        <Button
            onClick={onButtonPressCallback}
            className={classes.button}>{t("dashboard.select_stations")}</Button>
    );
};

export default CompareStationSelectorButton;
