import React from 'react';
import {
    Table, TableBody,
    TableContainer,
    TableRow
} from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
import {makeStyles, withStyles} from "@material-ui/styles";

const DeviceInfoList = ({list, locationCallback}) => {
    const useStyles = makeStyles({
        table: {
            minWidth: 0,
        },
        clickable:{
            color: "#1A23D0",
            fontWeight: "bold",
            '&:hover': {
                textDecoration:"underline",
                cursor: "pointer"
            }
        }

    });

    const classes = useStyles();
    const TableCellStyled = withStyles({
        root: {
            borderBottom: "none"
        }
    })(MuiTableCell);

    return (
    <TableContainer >
        <Table className={classes.table} aria-label="caption table">
            <TableBody>
                {list.map((item) => (
                    <TableRow key={item.id}>
                        <TableCellStyled padding={"none"} variant={"head"} align="left">{item.name}</TableCellStyled>
                        { item.clickable &&
                        <TableCellStyled
                            onClick = { event => locationCallback(item.value)}
                           className = {classes.clickable}
                            padding={"none"}
                            align="right">{item.value}</TableCellStyled>
                        }
                        {
                            !item.clickable &&  <TableCellStyled
                                style={{ color: item.color,fontWeight: item.color && "bold"}}
                                padding={"none"}
                                align="right">{item.value}</TableCellStyled>
                        }
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    );
};

export default DeviceInfoList;
