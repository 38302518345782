import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

const CommonDialog = ({title,description,handleClose}) => {
    return (
        <Dialog
            fullWidth
            open={true}
            onClose={handleClose}
        >

            <DialogTitle id="common-dialog-tittle">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="common-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CommonDialog;
