import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {
    AppBar,
    Divider,
    Drawer, Grid,
    IconButton,
    List,
    Toolbar,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {DrawerListItems} from "./DrawerListItems";
import {PrivateRoutesTree} from "../PrivateRoutesTree";
import {useDispatch, useSelector} from "react-redux";
import {
    updateDashboardDrawerAction,
} from "../../../../reducers/dashboardUIReducer";
import { logoutAction} from "../../../../reducers/authReducer";
import SettingMenu from "./SettingMenu";
import * as Sentry from "@sentry/react";
import {
    ALARM_ROUTE,
    DOWNLOAD_ROUTE,
    HOME_ROUTE,
    ORGANIZATION_ROUTE,
    PROFILE_ROUTE, REPORT_ROUTE
} from "../../../../constants";
import {
    removeRequestErrorAction,
    setRequestError,
    startLoadingAction,
    stopLoadingAction
} from "../../../../reducers/requestUIReducer";
import {logoutRequest} from "../../../../requests/auth/authRequests";
import ServerErrorScreen from "../../../common/ServerErrorScreen";
import AnalyticsStationSelector from "./AnalyticsStationSelector";
import CompareStationSelectorButton from "./compareSelector/CompareStationSelectorButton";
import CompareStationSelectorPopUp from "./compareSelector/CompareStationSelectorPopUp";
import DownloadWidgetGroup from "./download_progress/DownloadWidgetGroup";
import {removeAllDownloadProcessAction} from "../../../../reducers/downloadReducer";
import {useTranslation} from "react-i18next";

export const drawerWidth = 220;

export const Dashboard = (props) => {

    const { t } = useTranslation();
    let history = useHistory();
    let dispatch = useDispatch();
    const { downloadProcessList } = useSelector( state => state.download );
    const location = useLocation();
    const { stations,selectCompareStations } = useSelector( state => state.dashboardUI );
    const { signedIn,email,name,lastName } = useSelector( state => state.auth );
    const { drawerOpen } = useSelector( state => state.dashboardUI);
    const [anchorEl, setAnchorEl] = useState(null);
    const [{notFoundRouteValue,serverError,openCompareSelector}, updateState] =
        useState({notFoundRouteValue:false, serverError:false,openCompareSelector:false});


    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
        },
        toolbarIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        menuButtonHidden: {
            display: 'none',
        },
        title: {
            flexGrow: 1,
        },
        drawerPaper: {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        },
        appBarSpacer: theme.mixins.toolbar,
        main: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            background:"#eaeaea"

        },
        container: {
            display:"flex",
            justifyContent:"center",
        },
    }));
    const classes = useStyles();


    useEffect(() => {
        if (downloadProcessList.length>0) {
            window.onbeforeunload = function() {
                return true;
            };
        }
        return () => {
            window.onbeforeunload = null;
        };
    }, [downloadProcessList]);

    const handleOpenCompareSelectorCallback = useCallback(()=>{
        updateState(state => ({...state,openCompareSelector: true}))
    },[updateState])

    const handleCloseCompareSelectorCallback = useCallback(()=>{
        updateState(state => ({...state,openCompareSelector: false}))
    },[updateState])

    const closeMenu = useCallback(()=>{
        setAnchorEl(null);
    },[setAnchorEl])

    useEffect(()=>{
        if(!signedIn){
            downloadProcessList.forEach(item =>{
                item.source.cancel()
            })
            dispatch(removeAllDownloadProcessAction())
        }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[downloadProcessList,signedIn])


    const logout = useCallback(()=>{
        localStorage.removeItem('lastPath');
        dispatch(startLoadingAction())
        dispatch(removeRequestErrorAction())
        logoutRequest( (resp, err) => {
            dispatch(stopLoadingAction())
            if (err) {
                switch (resp.status) {
                    case 401:
                        dispatch(logoutAction());
                        break;
                    case 500:
                        updateServerErrorCallback();
                        break;
                    default:
                        dispatch(setRequestError(t('dashboard.error_logout')));
                        break;
                }
            } else {
                dispatch(logoutAction());
            }
        });
    },[t,dispatch]);


    const logoutSession = useCallback(()=>{
        logout();
    },[logout]);

    const openProfile = useCallback(()=>{
        history.push(PROFILE_ROUTE);
    },[history]);

    const openOrganization = useCallback(()=>{
        history.push(ORGANIZATION_ROUTE);
    },[history])



    const selectedPageCallback = useCallback((position)=>{
        switch (position){
            case 0:
                history.push(HOME_ROUTE);
                break;
            case 1:
                let lastStation = localStorage.getItem('lastStation');
                let defaultSensorId = stations[0]?.id
                history.push(`/analytics/${lastStation ? lastStation : defaultSensorId}`);
                break;
            case 2:
                let query = ""
                selectCompareStations.forEach((value,index) =>{
                    if(index === 0){
                        query = query +`?id=${value.id}`
                    }else{
                        query = query+`&id=${value.id}`
                    }
                });
                history.push(`/compare/${query}`);
                break;
            case 3:
                history.push(ALARM_ROUTE);
                break;
            case 4:
                history.push(DOWNLOAD_ROUTE);
                break;
            case 5:
                history.push(REPORT_ROUTE);
                break;
            case 6:
                Sentry.showReportDialog({
                    eventId:Sentry.captureMessage(t('dashboard.sentry_front_feedback')),
                    title:t('dashboard.sentry_send_suggestion'),
                    subtitle:t('dashboard.sentry_opinion'),
                    subtitle2:"",
                    user:{email:email,name:`${name} ${lastName}`},
                    labelSubmit:t('dashboard.sentry_send'),
                    labelComments:t('dashboard.sentry_something_to_say')
                });
                break;
            default:
                break;
        }
    },[t,name,email,lastName,history,stations,selectCompareStations]);

    //Remove the server throw by the logout if user use the next and back navigation buttons
    useEffect(()=>{
            updateState(previousState => ({...previousState,serverError:false}))
        }
    ,[location]);


    const handleDrawerOpen = () => {
        dispatch(updateDashboardDrawerAction(true));
    };
    const handleDrawerClose = () => {
        dispatch(updateDashboardDrawerAction(false))
    };

    const updateNotFoundRouteCallback= useCallback((notFound) =>{
        updateState(previousState => ({...previousState,notFoundRouteValue:notFound}))
    },[]);

    const updateServerErrorCallback = ()=>{
        updateState(previousState => ({...previousState,serverError:true}))
    };

    return (
        <div className={classes.root}>
            <DownloadWidgetGroup/>
            { !notFoundRouteValue && <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {t('dashboard.bettair_platform')}
                    </Typography>
                    {(location.pathname.includes("/analytics")) && <AnalyticsStationSelector/>}
                    {(location.pathname.includes("/compare")) && <CompareStationSelectorButton onButtonPressCallback={handleOpenCompareSelectorCallback}/>}
                    <IconButton
                        data-testid={"dashboard-setting"}
                        color="inherit" onClick={e => setAnchorEl(e.currentTarget)}>
                        <SettingsIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>}

            { !notFoundRouteValue && <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
            }}
            open={drawerOpen}>
            <div className={classes.toolbarIcon}>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List><DrawerListItems selectedPageCallback={selectedPageCallback}/></List>
            </Drawer>}

            <main className={classes.main}>
                {!notFoundRouteValue &&  <div className={classes.appBarSpacer} />}
                    <Grid container direction={"row"}  alignItems={"flex-start"} justifyContent={"center"}>
                       <Grid item xs={12} className={classes.container} >
                           <PrivateRoutesTree  isAuthenticated={props.isAuthenticated}
                                              updateNotFound = {updateNotFoundRouteCallback}
                           />
                       </Grid>
                    </Grid>
            </main>
            <SettingMenu anchorEl = {anchorEl}
                         closeMenu = {closeMenu}
                         logoutSession = {logoutSession}
                         openProfile = {openProfile}
                         openOrganization = {openOrganization}/>
            {serverError && <ServerErrorScreen/>}
            {openCompareSelector && <CompareStationSelectorPopUp open={openCompareSelector}
                                                                 handleClose={handleCloseCompareSelectorCallback}/>}
        </div>
    );
}

export default Dashboard;
