import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";

const AQILegend = () => {
    const { t } = useTranslation();

    const useStyles = makeStyles({
        valueDark:{
            textAlign:"center",
            minWidth:60,
            color:"#696969",
            fontWeight:"bold",
            marginRight:42,
            marginLeft:8
        },
        descriptionDark:{
            textAlign:"center",
            color:"#696969",
            fontWeight:"bold",
        },
        value:{
            textAlign:"center",
            minWidth:60,
            color:"#ffffff",
            fontWeight:"bold",
            marginRight:42,
            marginLeft:8
        },
        description:{
            textAlign:"center",
            color:"#ffffff",
            fontWeight:"bold",
        },

        green:{
            background:"#52B946"
        },
        yellow:{
            background:"#F3EC18",
            marginBottom:4
        },
        orange:{
            background:"#F67D1E",
            marginBottom:4
        },
        red:{
            background:"#ED1C25",
            marginBottom:4
        },
        purple:{
            background:"#7D287C",
            marginBottom:4
        },
        darkPurple:{
            background:"#480D27",
            marginBottom:4
        }

    });

    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} container className={classes.darkPurple}>
                <Typography variant={"subtitle2"} className={classes.value}>301-500 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("aqiLegend.hazardous")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.purple}>
                <Typography variant={"subtitle2"} className={classes.value}>201-300 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("aqiLegend.very_unhealthy")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.red}>
                <Typography variant={"subtitle2"} className={classes.value}>151-200 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("aqiLegend.unhealthy")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.orange}>
                <Typography variant={"subtitle2"} className={classes.value}>101-150 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("aqiLegend.unhealthy_for_sensitive")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.yellow}>
                <Typography variant={"subtitle2"} className={classes.valueDark}>51-100 </Typography>
                <Typography variant={"subtitle2"} className={classes.descriptionDark}>{t("aqiLegend.moderate")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.green}>
                <Typography variant={"subtitle2"} className={classes.valueDark}>0-50 </Typography>
                <Typography variant={"subtitle2"} className={classes.descriptionDark}>{t("aqiLegend.good")}</Typography>
            </Grid>
        </>
    );
};

export default AQILegend;
